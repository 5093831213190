/* lato-regular - latin */
@font-face {
    font-display: optional;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../font/lato-v17-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../font/lato-v17-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-700 - latin */
@font-face {
  font-display: optional;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../font/lato-v17-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../font/lato-v17-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-900 - latin */
@font-face {
  font-display: optional;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local(''), url('../font/lato-v17-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../font/lato-v17-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* pt-serif-700 - latin */
@font-face {
  font-display: optional;
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../font/pt-serif-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../font/pt-serif-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* pt-serif-regular - latin */
@font-face {
  font-display: optional;
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../font/pt-serif-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../font/pt-serif-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
