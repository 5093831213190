/*===============
    Imports
===============*/
@import "normalize";
@import "swiper";
@import "nouislider";
@import "common";
@import "utils";

/*====================
    Custom Rules
====================*/

body {
  &.body--withskin {
    @media (min-width: $medium + 1) {
      margin-top: 120px;
    }
  }
}

//Le immagini che non hanno l'attributo src (per via del loading) le nascondo
img {
  &.error,
  &:not([src]) {
    visibility: hidden;
  }

  &.lazy {
    opacity: 0;

    @include transition(opacity);

    &.loaded {
      opacity: 1;
    }

    .no-js & {
      display: none !important;
    }
  }
}

//Loading generico e loading sullo slider
.loading,
.swiper-lazy-preloader {
  @include loading;
}

.swiper-lazy-preloader {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.swiper-lazy {
  opacity: 0;
  @include transition(opacity);

  &.swiper-lazy-loaded {
    opacity: 1;
  }

  .no-js & {
    display: none !important;
  }
}

.app {
  background: #fff;
  /*overflow: hidden;*/
  position: relative;

  .body--error & {
    background-color: #000;
  }

  .body--withskin & {
    @media (min-width: $l-tablet) {
      margin-left: auto;
      margin-right: auto;
      max-width: $container-skin;
    }
  }

  .body--mktg & {
    background-color: #000;
    color: #fff;
  }
}

//noUiSlider generico
.noUi-horizontal {
  background-color: #f8f8f8;
  height: 10px;

  .noUi-handle {
    background-color: $primary;
    border: none;
    border-radius: 50%;
    box-shadow: none;
    height: 20px;
    right: -10px;
    width: 20px;

    &:before,
    &:after {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }

  .noUi-connect {
    background-color: #fff;
  }
}

//Leaderboard
.app__leaderboard {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  min-height: 140px;
  padding: 20px 0;
  position: relative;

  @media (min-width: $s-tablet) {
    min-height: 310px;
  }

  &:before {
    color: #bcbfc5;
    content: "Pubblicità";
    display: block;
    @include latobold;
    font-size: 8px;
    letter-spacing: 0.24px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 6px;
    width: 100%;
  }
}

.adv__top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.adv__top,
.adv__contentx {
  display: none;
  text-align: center;

  @media (min-width: $l-tablet) {
    padding: 30px 0;
  }

  .body--withleaderboard &,
  .body--withskin & {
    @media (min-width: $l-tablet) {
      display: block;
    }
  }
}

.adv__contentx {
  @media (min-width: $l-tablet) {
    padding: 100px 0 0;
  }
}

//Adv
.adv__wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  min-height: 310px;
  padding: 20px 0 5px;
  position: relative;
  width: 100%;

  @media (min-width: $s-tablet) {
    padding: 20px 0;
  }

  &:before {
    color: #bcbfc5;
    content: "Pubblicità";
    display: block;
    @include latobold;
    font-size: 8px;
    letter-spacing: 0.24px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 6px;
    width: 100%;
  }

  .adv__aside & {
    margin: 0;
  }
}

//Header del sito
.app__header {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 85px;
  padding: 25px 0;
  width: 100%;

  @media (min-width: 1366px) {
    min-height: 174px;
    padding: 20px 0 30px;
  }

  .body--home &,
  .body--video &,
  .body--mktg & {
    background-color: transparent;
    border-bottom: none;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 100;
  }

  .body--video & {
    background-color: #000;
  }
}

.header__main {
  .app__header--sticky & {
    @media (max-width: 1365px) {
      background-color: #fff;
      left: 0;
      margin-top: 0;
      padding: 10px 0;
      position: fixed;
      top: -2px;
      transform: translateY(-100%);
      width: 100%;
      z-index: 50;

      .body--video & {
        background-color: #000;
      }

      a {
        color: #000;
      }
    }
  }

  .app__header--sticky.is-active & {
    @media (max-width: 1365px) {
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
      transform: translateY(0);
    }
  }

  > div {
    align-items: center;
    display: flex;
  }
}

.header__left {
  align-items: center;
  display: flex;
  flex: 1;
}

.header__open-menu {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  height: 34px;
  @include transition;

  @media (min-width: 1366px) {
    display: none;
  }

  .no-touchevents &:hover,
  &:active,
  &:focus {
    color: $primary;
    outline: none;
  }

  .body--home &,
  .body--video &,
  .body--mktg & {
    color: #fff;

    .no-touchevents &:hover,
    &:active,
    &:focus {
      color: $primary;
      outline: none;
    }
  }

  .app__header--sticky & {
    .body--home & {
      @media (max-width: 1365px) {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.header__open-search {
  color: rgba(0, 0, 0, 0.7);
  display: block;
  text-align: right;
  transition: color 0.3s ease;

  .body--home &,
  .body--video &,
  .body--mktg & {
    color: #fff;
  }

  .no-touchevents &:hover {
    color: $primary;
  }

  .app__header--sticky & {
    .body--home & {
      @media (max-width: 1365px) {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.header__logo {
  align-items: center;
  display: flex;

  @media (min-width: $l-tablet) {
    height: 75px;
  }

  img {
    display: block;
  }
}

.header__logo-img {
  height: auto;
  width: 149px;

  @media (min-width: $l-tablet) {
    height: 45px;
    width: 197px;
  }

  &.header__logo-img--notsticky {
    .app__header--sticky & {
      @media (max-width: 1365px) {
        body:not(.body--video) & {
          display: none;
        }
      }
    }
  }

  &.header__logo-img--sticky {
    display: none;

    .app__header--sticky & {
      body:not(.body--video) & {
        @media (max-width: 1365px) {
          display: block;
        }
      }
    }
  }
}

.header__logo-icon {
  height: auto;
  margin-left: 10px;
  width: 32px;

  @media (min-width: $l-tablet) {
    margin-left: 20px;
    width: 52px;
  }
}

.header__right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.header__nav {
  margin-top: 15px;

  @media (max-width: 1365px) {
    display: none;
  }

  @media (min-width: 1366px) {
    margin-top: 30px;
  }

  .app__header--sticky & {
    @media (min-width: 1366px) {
      background-color: #fff;
      left: 0;
      margin-top: 0;
      opacity: 0;
      padding: 15px 0;
      position: fixed;
      top: 0;
      transform: translateY(-100%);
      width: 100%;
      z-index: 50;

      a {
        color: #000;
      }
    }

    .body--withskin & {
      overflow: hidden;

      @media (min-width: $l-tablet) {
        left: 50%;
        max-width: $container-skin;
        top: 0;
        transform: translateX(-50%) translateY(-100%);
      }
    }
  }

  .app__header--sticky.is-active & {
    @media (min-width: 1366px) {
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
      opacity: 1;
      transform: translateY(0);
    }

    .body--withskin & {
      @media (min-width: $l-tablet) {
        transform: translateX(-50%) translateY(0);
      }
    }
  }

  .app__header:not(.app__header--sticky) & {
    a {
      .body--home &,
      .body--video &,
      .body--mktg & {
        @media (min-width: 1366px) {
          color: #fff;

          .no-touchevents &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  a {
    @include latoblack;
    font-size: 14px;
    letter-spacing: 1px;
    @include transition(color);

    @media (min-width: $xs-mobile) {
      font-size: 15px;
    }

    @media (min-width: $l-tablet) {
      font-size: 16px;
    }

    @media (min-width: 1366px) {
      white-space: nowrap;
    }

    .no-touchevents &:hover {
      color: $primary;
    }

    .body--home &,
    .body--video &,
    .body--mktg & {
      @media (max-width: 1365px) {
        color: #fff;
      }
    }
  }
}

.header__navlist {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.header__navitem {
  margin: 0 7px;

  @media (min-width: $s-tablet) {
    margin: 0 10px;
  }

  @media (min-width: $l-tablet) {
    margin: 0 20px;
  }

  &--selected {
    position: relative;

    a {
      color: $primary;
    }

    .app__header:not(.app__header--sticky) & {
      .body--home &,
      .body--video &,
      .body--mktg & {
        a {
          @media (min-width: 1366px) {
            color: $primary;
          }
        }
      }
    }
  }

  &--onlydesktop {
    @media (max-width: 1365px) {
      display: none;
    }
  }

  &--onlysticky {
    display: none;

    .app__header--sticky & {
      @media (min-width: 1366px) {
        display: block;
      }
    }
  }

  .body--withskin & {
    @media (min-width: $l-tablet) {
      margin: 0 15px;
    }
  }

  a {
    @include transition(color);
    text-transform: uppercase;

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}
//Seguici sui social
.social {
  display: flex;
  font-size: 18px;
  @include transition;

  .app__header & {
    @media (max-width: 1365px) {
      display: none;
    }
  }

  .app__footer & {
    margin-top: 20px;
    justify-content: center;
  }
}

.social__item {
  &:not(:last-child) {
    margin-right: 7px;

    @media (min-width: $xs-mobile) {
      margin-right: 10px;
    }
  }

  a {
    align-items: center;
    color: hsla(0, 0%, 0%, 0.7);
    display: flex;
    font-size: 16px;
    height: 28px;
    justify-content: center;
    transition: color 0.3s ease;
    width: 28px;

    .no-touchevents &:hover {
      color: $primary;
    }

    .body--home &,
    .body--video &,
    .body--mktg & {
      color: #fff;
    }

    .menu & {
      color: #fff;
      font-size: 18px;
      height: 35px;
      width: 35px;
    }

    .app__footer & {
      color: #fff;
    }
  }
}

//Menu visibile solo a mobile
.menu {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  @include latoblack;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  padding: 15px 20px;
  position: fixed;
  transform: translateX(-100%);
  transition: transform 0.3s ease, opacity 0s 0.3s ease;
  top: 0;
  width: 100%;
  z-index: 1000000010; //sennò il banner pubblicitario va sopra

  @media (min-width: 1366px) {
    display: none;
  }

  &--open {
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.3s ease, opacity 0s 0s ease;
  }
}

.menu__header {
  padding-top: 10px;
  position: relative;
}

.menu__close-menu {
  height: 35px;
  width: 35px;

  &:before {
    color: #fff;
    font-size: 14px;
    left: -7px;
    position: relative;
  }
}

.menu__nav {
  margin-top: 11vh; /* Fallback for browsers that do not support Custom Properties */
  margin-top: calc(var(--vh, 1vh) * 11);
  margin-bottom: 40px;
}

.menu__navitem {
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &--selected {
    a {
      color: $primary;
    }
  }

  a {
    font-size: 24px;
    letter-spacing: 0.2px;
    text-transform: uppercase;

    @media (min-height: 640px) {
      font-size: 30px;
      letter-spacing: 0.3px;
    }
  }
}

.menu__follow {
  color: #fff;
  margin-top: auto;

  span {
    display: block;
    @include latobold;
    font-size: 14px;
    letter-spacing: 0.28px;
    margin-bottom: 20px;
  }
}
//Corpo del sito
.app__body {
  body:not(.body--ontv):not(.body--post) & {
    overflow: hidden;
  }

  .body--video & {
    padding-top: 85px;

    @media (min-width: $l-tablet) {
      padding-top: 125px;
    }

    @media (min-width: 1366px) {
      padding-top: 174px;
    }
  }
}

.main__header {
  margin-top: 30px;

  @media (min-width: $s-tablet) {
    margin-top: 50px;
  }

  &.main__header--with-bb {
    > div {
      &:after {
        background-color: #f8f8f8;
        content: "";
        display: block;
        height: 3px;
        margin-top: 25px;
        width: 100%;

        @media (min-width: $s-tablet) {
          margin-top: 35px;
        }
      }
    }
  }

  .body--video & {
    background-color: #000;
    margin-top: 0;
    padding-top: 30px;

    @media (min-width: $s-tablet) {
      padding-top: 50px;
    }
  }


  > div {
    position: relative;
  }
}

.main__header--catalogue {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0;
  padding: 30px 0 50px;
  position: relative;

  @media (min-width: $s-tablet) {
    padding: 50px 0;
  }

  &:before {
    background-color: rgba(0, 0, 0, 0.8);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  > div {
    z-index: 10;
  }
}

.main__header-inner {
  border-bottom: 2px solid $border;
  padding: 0 0 25px;

  @media (min-width: $s-tablet) {
    padding: 40px 0 25px;
  }
}

.main__header-back,
.movie__section-back > a {
  align-items: center;
  color: #000;
  display: flex;
  @include latoblack;
  font-size: 14px;
  left: 20px;
  position: absolute;
  top: 3px;
  @include transition(color);
  z-index: 10;

  .no-touchevents &:hover {
    color: $primary;
  }

  @media (max-width: ($s-tablet - 1)) {
    display: none;
  }

  @media (min-width: $l-tablet) {
    top: 10px;
  }

  @media (min-width: $medium) {
    top: 6px;
  }

  @media (min-width: $l-wide) {
    top: 12px;
  }

  &:before {
    align-items: center;
    border: solid 2px #e0e0e0;
    border-radius: 50%;
    display: flex;
    font-size: 11px;
    flex-shrink: 0;
    height: 40px;
    justify-content: center;
    margin-right: 10px;
    width: 40px;

    @media (min-width: $l-tablet) {
      font-size: 12px;
      height: 45px;
      width: 45px;
    }
  }

  .body--video & {
    color: #fff;
  }
}

.main__header-label {
  color: $primary;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 10px;
  text-transform: uppercase;

  @media (min-width: $s-tablet) {
    font-size: 16px;
    letter-spacing: 0.9px;
  }
}

.main__header-title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.main__header-text {
  font-size: 20px;
  line-height: 1.6;
  margin: 20px 0 0;

  @media (min-width: $medium) {
    padding-right: 95px;
  }
}

.main__header-number {
  display: block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.3px;
  margin-top: 15px;

  @include transition(color);

  @media (min-width: $s-tablet) {
    font-size: 16px;
    letter-spacing: 0.4px;
  }

  &:before {
    font-size: 16px;
    margin-right: 7px;

    @media (min-width: $s-tablet) {
      font-size: 18px;
      margin-right: 10px;
    }
  }
}

.main__header-nav {
  margin: 35px 0;

  @media (max-width: ($s-tablet - 1)) {
    display: none;
  }

  ul {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;

    > li {
      flex: 0 0 auto;
      padding: 0 10px;
      width: 50%;

      &:only-child {
        width: 100%;
      }

      > a {
        display: flex;
        font-size: 16px;

        @include transition(color);

        &:before {
          flex-shrink: 0;
          font-size: 19px;
        }

        .no-touchevents &:hover {
          color: $primary;
        }

        strong {
          @include latoblack;
        }
      }
    }
  }
}

.main__header-nav-prev {
  > a {
    &:before {
      margin-right: 20px;
    }
  }
}

.main__header-nav-next {
  > a {
    justify-content: flex-end;

    &:before {
      margin-left: 20px;
      order: 3;
    }
  }
}
//Footer del sito
.app__footer {
  background-color: #f9f9f9;
  font-size: 13px;
  margin-top: 100px;

  @media (min-width: $s-tablet) {
    margin-top: 200px;
  }
}

.app__footer-openindex {
  align-items: baseline;
  color: #fff;
  display: flex;
  justify-content: center;

  @media (min-width: $s-tablet) {
    display: none;
  }

  &:before {
    font-size: 11px;
    padding-left: 7px;
    order: 2;
  }

  &.is-open {
    margin-bottom: 20px;

    span {
      &:before {
        content: attr(data-close-text);
      }
    }
  }

  span {
    margin-right: 3px;

    &:before {
      content: attr(data-open-text);
    }
  }
}

.app__footer-index {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &:not(.is-open) {
    @media (max-width: $s-tablet - 1) {
      display: none;
    }
  }

  > * {
    @media (max-width: $l-tablet - 1) {
      width: 100%;
    }
  }
}

.company {
  background-color: #000;
  color: #fff;
  @include latoregular;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 0;
  padding: 30px 10px;

  @media (min-width: $s-tablet) {
    padding: 40px 10px;
  }

  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 10px;

    > * {
      @media (max-width: $l-tablet - 1) {
        width: 100%;
      }
    }
  }
}

.company__name {
  font-size: 12px;
  margin: 0;
  opacity: 0.5;
  padding-top: 20px;
  text-align: center;
  width: 100%;
}

.company__data {
  margin: 0;

  span {
    white-space: nowrap;
  }
}

.links {
  @media (max-width: ($l-tablet - 1)) {
    text-align: center;
  }

  @media (min-width: $l-tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}

.links__item {
  > a {
    display: block;
    padding: 10px;
    @include transition(color);

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}
//Testata della homepage
.hero {
  background-color: #000;
  height: 450px;
  margin-bottom: 50px;
  position: relative;

  @media (min-width: $s-tablet) {
    height: 85vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 85);
    margin-bottom: 95px;
  }

  @media (min-width: $medium) {
    max-height: 90vh; /* Fallback for browsers that do not support Custom Properties */
    max-height: calc(var(--vh, 1vh) * 90);
  }
}

.hero__image,
.hero__video {
  overflow: hidden;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;

  &:before {
    background-color: rgba(0, 0, 0, 0.3);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}

.hero__video {
  position: relative;

  video {
    height: 100%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: auto;
    z-index: 5;

    @media (min-width: $l-tablet) {
      min-height: 130%;
      min-width: 130%;
    }
  }
}

.hero__caption {
  bottom: 30px;
  left: 50%;
  max-width: 440px;
  padding: 0 20px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 100%;
  z-index: 10;

  @media (min-width: $s-tablet) {
    bottom: 12.5vh;
    max-width: 700px;
  }

  @media (min-width: $l-tablet) {
    @media (min-height: 900px) {
      max-width: 900px;
    }
  }
}

.hero__caption-link {
  display: block;
}

.hero__caption-label {
  color: $primary;
  display: block;
  @include latobold;
  font-size: 14px;
  letter-spacing: 0.7px;
  text-transform: uppercase;

  @media (min-width: $s-tablet) {
    font-size: 15px;
    letter-spacing: 0.8px;
  }

  @media (min-width: $l-tablet) {
    @media (min-height: 900px) {
      font-size: 16px;
    }
  }
}

.hero__caption-subtitle {
  color: #fff;
  display: block;
  font-size: 20px;
  margin-top: 10px;

  @media (min-width: $s-tablet) {
    font-size: 22px;
    margin-top: 15px;
  }

  @media (min-width: $l-tablet) {
    @media (min-height: 900px) {
      font-size: 25px;
      margin-top: 20px;
    }
  }
}

.hero__caption-title {
  color: #fff;
  display: block;
  @include latoblack;
  font-size: 25px;
  line-height: 1.33;
  margin-top: 10px;
  @include transition(color);

  @media (min-width: $s-tablet) {
    font-size: 50px;
    line-height: 1;
    margin-top: 15px;
  }

  @media (min-width: $l-tablet) {
    @media (min-height: 900px) {
      font-size: 55px;
      line-height: 1.16;
      margin-top: 10px;
    }
  }

  .no-touchevents .hero__caption-link:hover & {
    color: $primary;
  }
}
//News feed in home
.newsfeed__row {
  display: flex;
  margin: 30px 0;

  @media (max-width: ($l-tablet - 1)) {
    flex-direction: column-reverse;
  }

  @media (min-width: $s-tablet) {
    margin: 80px 0;
  }

  .hero + .newsfeed & {
    &:first-of-type {
      @media (min-width: $s-tablet) {
        margin-top: 40px;
      }
    }
  }

  .movie__news &,
  .star__news & {
    margin: 30px 0;

    @media (min-width: $s-tablet) {
      margin: 30px 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.newsfeed__list {
  display: flex;
  margin: 0 -10px;

  @media (max-width: ($s-tablet - 1)) {
    flex-wrap: wrap;
  }

  @media (min-width: $s-tablet) {
    margin: 0 -15px;
  }

  @media (min-width: $l-tablet) {
    margin: 0 -20px;
  }

  @media (min-width: $medium) {
    margin: 0 -30px;
  }

  .content__news &,
  .archive__news & {
    flex-wrap: wrap;

    @media (min-width: $l-mobile) {
      margin-bottom: -15px;
      margin-top: -15px;
    }
  }

  .archive__news & {
    margin: 0;
  }

  .body--video & {
    flex-wrap: wrap;
    margin: -7.5px;

    @media (min-width: $s-tablet) {
      margin: -15px;
    }
  }

  .post__related & {
    flex-wrap: wrap;
    margin: -10px;

    @media (min-width: $s-tablet) {
      margin: -15px;
    }

    @media (min-width: $l-tablet) {
      margin: -20px;
    }

    @media (min-width: $medium) {
      margin: -30px;
    }
  }

  > li {
    &:first-child {
      margin-top: 0;
    }
  }
}

.newsfeed__item {
  width: 100%;

  &:not(.newsfeed__item--small):not(:only-child) {
    @media (max-width: ($l-tablet - 1)) {
      margin-top: 80px;
    }

    @media (min-width: $l-tablet) {
      flex: 1;
      width: calc(100% - 400px);
    }
  }

  &:not(:only-child) {
    @media (max-width: ($s-tablet - 1)) {
      margin-top: 30px;
    }
  }

  &.newsfeed__item--small {
    flex: 0 0 auto;
    padding: 0 10px;
    width: 100%;

    @media (min-width: $s-tablet) {
      padding: 0 15px;
      width: calc(100% / 2);
    }

    @media (min-width: $l-tablet) {
      padding: 0 20px;
      width: calc(100% / 3);
    }

    @media (min-width: $medium) {
      padding: 0 30px;
    }

    .videos__others & {
      margin-top: 0;
      width: calc(100% / 3);

      .body--video & {
        padding: 10px;
        width: 100%;

        @media (min-width: $l-mobile) {
          width: calc(100% / 2);
        }

        @media (min-width: $s-tablet) {
          padding: 15px;
          width: calc(100% / 3);
        }

        @media (min-width: $medium) {
          width: calc(100% / 4);
        }

        @media (min-width: $wide) {
          width: calc(100% / 5);
        }
      }
    }

    .archive__news & {
      margin: 30px 0 0;
      padding: 0;
      width: 100%;

      @media (min-width: $s-tablet) {
        margin: 35px 0 0;
      }

      > a {
        display: inline-block;
      }
    }

    .post__related & {
      padding: 10px;

      @media (min-width: $s-tablet) {
        padding: 15px;
      }

      @media (min-width: $l-tablet) {
        padding: 20px;
      }

      @media (min-width: $medium) {
        padding: 30px;
      }
    }

    > a {
      .videos__others & {
        .body--video & {
          @media (max-width: $l-mobile - 1) {
            display: flex;
          }

          .no-touchevents &:hover {
            img {
              transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0);
            }
          }
        }
      }
    }
  }

  &.newsfeed__item--long {
    @media (min-width: $l-mobile) {
      padding-bottom: 15px;
      padding-top: 15px;
      width: 100%;
    }
  }

  &.newsfeed__item--video {
    position: relative;

    &.newsfeed__item--video--sticky {
      &.newsfeed__item--video--sticky--down {
        .newsfeed__video-sentinal {
          @media (max-width: $s-tablet - 1) {
            bottom: calc(100% + 54px);
          }
        }

        .newsfeed__video {
          @media (max-width: $s-tablet - 1) {
            transform: translate(-50%, 54px);
            @include transition(transform);
          }
        }
      }

      .newsfeed__video-placeholder {
        @media (max-width: $s-tablet - 1) {
          padding-bottom: calc(56.25% + 20px);
        }
      }

      .newsfeed__video {
        @media (max-width: $s-tablet - 1) {
          position: fixed;
          top: -1px;
          transition: none;
          z-index: 1000000000; //sennò il banner pubblicitario va sopra
        }
      }
    }
  }

  &.newsfeed__item--banner {
    width: 100% !important;
  }

  .movie__news &,
  .star__news & {
    width: 100%;
  }
}

.newsfeed__video-sentinal {
  @media (max-width: ($s-tablet - 1)) {
    bottom: 100%;
    height: 200vh;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -10;
  }
}

.newsfeed__link {
  display: block;

  .newsfeed__item:not(.newsfeed__item--small):not(.newsfeed__item--video):only-child & {
    @media (min-width: $s-tablet) {
      display: flex;
      flex-flow: row-reverse;
    }

    > div {
      @media (min-width: $s-tablet) {
        flex: 0 0 auto;
        width: 50%;
      }

      &.newsfeed__caption {
        @media (max-width: ($l-tablet - 1)) and (min-width: $s-tablet) {
          padding-top: 0;
        }
      }

      &.newsfeed__image {
        @media (min-width: $s-tablet) {
          padding-bottom: 33.074%;
        }
      }
    }
  }

  .newsfeed__item--long & {
    @media (min-width: $l-mobile) {
      display: flex;
    }
  }
}

.newsfeed__image,
.newsfeed__video,
.post__player {
  &.loading {
    background-color: $grey;

    &:after {
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      z-index: 10;

      @media (min-width: $s-tablet) {
        margin-left: -20px;
        margin-top: -20px;
      }
    }
  }
}

.newsfeed__image {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 66.09%;
  position: relative;

  .newsfeed__item--long & {
    @media (min-width: $l-mobile) {
      flex-shrink: 0;
      padding-bottom: 13.45%;
      width: 235px;
    }
  }

  img {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 1s ease, opacity 0.3s ease;
    width: 100%;

    .no-touchevents .newsfeed__link:hover & {
      transform: scale(1.1);
    }
  }
}

.newsfeed__video-placeholder {
  height: 0;
  left: 50%;
  padding-bottom: 0;
  position: relative;
  transform: translateX(-50%);
  width: 100vw;
}

.newsfeed__video-progress {
  background-color: rgba(255, 255, 255, 0.5);
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;

  div {
    background-color: $primary;
    height: 100%;
    width: 25%; //temp
  }
}

.newsfeed__video-label {
  color: #fff;
  display: block;
  @include latobold;
  font-size: 11px;
  left: 7px;
  position: absolute;
  top: 7px;
  text-transform: uppercase;
  z-index: 5;

  @media (min-width: $s-tablet) {
    font-size: 13px;
    left: 10px;
    top: 15px;
  }
}

.newsfeed__caption {
  padding: 15px 20px 0 10px;

  @media (min-width: $s-tablet) {
    padding: 40px 20px 0 40px;
  }

  .newsfeed__item--small & {
    @media (min-width: $s-tablet) {
      padding: 15px 10px 0 15px;
    }

    .body--video & {
      @media (max-width: $l-mobile - 1) {
        padding: 0 0 0 15px;
      }
    }
  }

  .movie__news &,
  .star__news & {
    padding: 15px 0 0;

    @media (min-width: $s-tablet) {
      padding: 10px 35px 0 0;
    }
  }

  .archive__news & {
    padding: 0;
  }
}

.newsfeed__caption-label {
  color: $primary;
  display: block;
  @include latobold;
  font-size: 14px;
  text-transform: uppercase;

  @media (min-width: $l-tablet) {
    font-size: 16px;
  }

  .movie__news &,
  .star__news & {
    font-size: 13px;

    @media (min-width: $s-tablet) {
      font-size: 14px;
    }
  }
}

.newsfeed__caption-title {
  color: #000;
  @include latoblack;
  font-size: 20px;
  line-height: 1.33;
  @include transition(color);

  @media (min-width: $s-tablet) {
    font-size: 25px;
    line-height: 1.29;
  }

  @media (min-width: $l-tablet) {
    font-size: 35px;
  }

  &:not(:first-child) {
    margin-top: 5px;
  }

  .no-touchevents .newsfeed__link:hover & {
    color: $primary;
  }

  .no-touchevents .newsfeed__video:hover + .newsfeed__caption & {
    //reset del rollover
    color: #000;
  }

  .no-touchevents .videos__featured .newsfeed__video:hover + .newsfeed__caption & {
    //reset del rollover
    color: #fff;
  }

  .no-touchevents .videos__others .newsfeed__item > a:hover & {
    //reset del rollover
    color: $primary;
  }

  .newsfeed__item--small & {
    @media (min-width: $s-tablet) {
      font-size: 22px;
      line-height: 1.45;
    }

    .body--video & {
      @media (max-width: $l-mobile - 1) {
        font-size: 15px;
      }
    }
  }

  .videos__featured & {
    color: #fff;
  }

  .movie__news &,
  .star__news & {
    font-size: 18px;

    @media (min-width: $s-tablet) {
      font-size: 24px;
    }
  }
}

.newsfeed__caption-author {
  @include latobold;
  font-size: 12px;
  margin: 7px 0 0;

  @media (min-width: $s-tablet) {
    margin: 15px 0 0;
  }

  body.body--video & {
    color: #fff;
    margin: 10px 0 0;

    span {
      color: #fff;
    }
  }

  span {
    color: $text-grey;
    text-transform: uppercase;
  }
}

.newsfeed__caption-text {
  color: #fff;
  @include latobold;
  font-size: 13px;
  margin: 10px 0;

  @media (min-width: $s-tablet) {
    font-size: 14px;
  }

  @media (min-width: $l-tablet) {
    font-size: 15px;
  }
}

.newsfeed__caption-html {
  color: #fff;
  @include latoregular;
  font-size: 13px;
  line-height: 1.5;

  &.is-expanded {
    > *:not(:first-child):not(.newsfeed__caption-readmore) {
      display: block;
    }
  }

  > *:not(:first-child):not(.newsfeed__caption-readmore) {
    display: none;
  }

  p {
    margin: 7px 0;
  }

  ul, ol {
    margin-bottom: 7px;
    margin-top: 7px;
    padding: 0;
  }

  ul {
    margin-left: 15px;
  }

  > ul {
    list-style-type: disc;

    ul {
      list-style-type: circle;
    }
  }

  ol {
    margin-left: 20px;
  }

  a, button {
    background-image: linear-gradient(#fff 50%, rgba(255, 255, 255, 0.6) 0);
    background-repeat: repeat-x;
    background-position: 0 15px;
    background-size: 1px 1px;
    color: #fff;
    line-height: 1.15;
    text-decoration: none;
    position: relative;
    text-shadow: 3px 0 #000, 2px 0 #000, 1px 0 #000, -1px 0 #000, -2px 0 #000, -3px 0 #000;
    @include transition;
    z-index: 2;

    .no-touchevents &:hover {
      background-image: linear-gradient(#fff 50%, rgba(232, 0, 0, 0.6) 0);
      color: $primary;
    }
  }

  button {
    line-height: 1.3;
  }
}

.newsfeed__banner {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  text-align: center;

  @media (min-width: $l-tablet) {
    flex: 0 0 400px;
    padding: 0 20px 0 80px;
    width: 400px;
  }
}
//Sezione
.sectionhome {
  margin-top: 50px;

  @media (min-width: $s-tablet) {
    margin-top: 95px;
  }

  .hero + & {
    margin-top: 30px;

    @media (min-width: $s-tablet) {
      margin-top: 80px;
    }
  }
}
//Titolo della sezione
.section__title {
  color: #000;
  @include latoblack;
  font-size: 30px;
  position: relative;
  z-index: 5;

  @media (min-width: $s-tablet) {
    font-size: 40px;
    text-align: center;
  }

  @media (min-width: $l-tablet) {
    font-size: 50px;
  }

  @media (min-width: $medium) {
    font-size: 60px;
  }

  &.section__title--small {
    text-align: left;

    @media (min-width: $l-tablet) {
      font-size: 55px;
    }
  }

  &.section__title--subsection {
    align-items: center;
    display: flex;
    font-size: 25px;
    margin-bottom: 30px;

    @media (min-width: $s-tablet) {
      font-size: 35px;
    }
  }

  .author__avatar + & {
    display: inline-block;
    vertical-align: middle;
  }

  .body--error & {
    color: #fff;
    text-align: center;
  }

  .body--video & {
    color: #fff;
  }

  .main__header-back + & {
    @media (min-width: $s-tablet) {
      padding: 0 70px;
    }
  }

  .main__header--catalogue & {
    color: #fff;
  }

  .movies__wrapper & {
    margin-bottom: 30px;
  }

  .main__header--search & {
    font-size: 27px;
    text-align: left;

    @media (min-width: $s-tablet) {
      font-size: 32px;
    }

    @media (min-width: $l-tablet) {
      font-size: 37px;
    }

    @media (min-width: $medium) {
      font-size: 42px;
    }

    small {
      display: block;
      font-size: 16px;

      @media (min-width: $s-tablet) {
        font-size: 19px;
      }

      @media (min-width: $l-tablet) {
        font-size: 22px;
      }

      @media (min-width: $medium) {
        font-size: 25px;
      }
    }
  }

  mark {
    background: none;
    color: $primary;
    margin: 0;
  }
}

.section__title-inner {
  position: relative;
}
//Sottotitolo della sezione
.section__subtitle {
  color: #000;
  @include latoblack;
  font-size: 25px;
  margin-top: 30px;

  @media (min-width: $l-tablet) {
    font-size: 30px;
    margin-top: 40px;
  }
}
//Testo della sezione
.section__text {
  font-size: 17px;
  letter-spacing: 0.1px;
  line-height: 1.6;
  margin-top: 20px;

  @media (min-width: $s-tablet) {
    font-size: 18px;
    letter-spacing: 0.15px;
    margin-top: 30px;
  }

  @media (min-width: $medium) {
    font-size: 20px;
    letter-spacing: 0.2px;
    margin-top: 40px;
  }

  &.section__text--small {
    font-size: 14px;

    @media (min-width: $s-tablet) {
      font-size: 16px;
    }

    @media (min-width: $medium) {
      font-size: 18px;
    }
  }

  &.section__text--static {
    > div {
      &:not(:first-child) {
        margin-top: 20px;

        @media (min-width: $s-tablet) {
          margin-top: 30px;
        }

        @media (min-width: $medium) {
          margin-top: 40px;
        }
      }
    }
  }

  &:not(.section__text--static) {
    a {
      background-image: linear-gradient(#fff 50%, rgba(232, 0, 0, 0.6) 0);
      background-repeat: repeat-x;
      background-position: 0 22px;
      background-size: 1px 1px;
      color: $primary;
      text-decoration: none;
      position: relative;
      text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
      z-index: 2;

      .body--video & {
        text-shadow: 3px 0 #000, 2px 0 #000, 1px 0 #000, -1px 0 #000, -2px 0 #000, -3px 0 #000;
        background-image: linear-gradient(#000 50%, rgba(232, 0, 0, 0.6) 0);
      }
    }
  }

  &:not(.is-expanded) {
    &.section__text--static {
      p {
        &:not(:first-child) {
          display: block;
        }
      }
    }

    p {
      &:not(:first-child) {
        display: none;
      }
    }
  }

  .author__avatar ~ & {
    margin-top: 30px;
  }

  .body--video & {
    color: #fff;
  }

  h2,
  h3 {
    color: #000;

    .body--video & {
      color: #fff;
    }
  }

  h2 {
    font-size: 16px;

    @media (min-width: $s-tablet) {
      font-size: 18px;
    }

    @media (min-width: $wide) {
      font-size: 25px;
    }
  }

  h3 {
    font-size: 14px;

    @media (min-width: $s-tablet) {
      font-size: 16px;
    }

    @media (min-width: $wide) {
      font-size: 22px;
    }
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .table__wrapper {
    margin: 1em 0;

    @media (max-width: $s-tablet - 1) {
      overflow-y: auto;
      position: relative;
    }
  }

  table {
    border: 1px solid #000;
    width: 100%;

    th,
    td {
      padding: 10px;

      @media (min-width: $s-tablet) {
        padding: 10px 15px;
      }
    }

    th {
      background-color: #000;
      border-right-color: #000;
      color: #fff;
      text-align: left;

      @media (max-width: $s-tablet - 1) {
        font-size: 12px;
        line-height: 1.2;
      }
    }

    td {
      vertical-align: top;

      @media (max-width: $s-tablet - 1) {
        font-size: 10px;
        line-height: 1;
      }

      &:first-child,
      &:last-child {
        width: 100px;

        @media (min-width: $s-tablet) {
          width: 250px;
        }
      }
    }
  }

  ul,
  ol {
    margin-left: 0;
    padding-left: 2em;

    @media (min-width: $s-tablet) {
      padding-left: 3em;
    }

    > li {
      padding-left: 5px;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  a {
    background-image: linear-gradient(#fff 50%, rgba(232, 0, 0, 0.6) 0);
    background-repeat: repeat-x;
    background-position: 0 22px;
    background-size: 1px 1px;
    color: $primary;
    text-decoration: none;
    position: relative;
    text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
    z-index: 2;

    .body--video & {
      background-image: linear-gradient(#000 50%, rgba(232, 0, 0, 0.6) 0);
      text-shadow: 3px 0 #000, 2px 0 #000, 1px 0 #000, -1px 0 #000, -2px 0 #000, -3px 0 #000;
    }
  }
}

.section__text-expand {
  color: $primary;
  display: block;
  font-family: "PT Serif", serif;
  font-size: 15px;
  letter-spacing: 0.05px;
  @include transition(color);

  @media (min-width: $s-tablet) {
    font-size: 17px;
    letter-spacing: 0.1px;
  }

  @media (min-width: $medium) {
    font-size: 18px;
    letter-spacing: 0.15px;
  }

  &:after {
    background-color: $primary;
    content: "";
    display: block;
    height: 1px;
    margin-top: 2px;
    transform: scaleX(0);
    transform-origin: 0 0;
    @include transition(transform);
    width: 100%;
  }

  .no-touchevents &:hover {
    &:after {
      transform: scaleX(1);
    }
  }
}
//Combo nel titolo
.section__title-combo {
  backface-visibility: hidden;
  opacity: 0;
  visibility: hidden;
  z-index: 10;

  @media (max-width: ($s-tablet - 1)) {
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    transition: transform 0.3s ease, opacity 0.3s 0.15s ease, visibility 0.3s 0.15s ease;
    width: 100%;
    z-index: 90;
  }

  @media (min-width: $s-tablet) {
    background-color: #fff;
    box-shadow: 1px 1.7px 50px 0 rgba(0, 0, 0, 0.19);
    left: -15px;
    position: absolute;
    right: -15px;
    top: calc(100% + 10px);
    transform: translateY(20px);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.2s linear;
  }

  &:before {
    @media (min-width: $s-tablet) {
      border-bottom: 6px solid #fff;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      content: "";
      display: block;
      height: 0;
      position: absolute;
      right: 15px;
      top: -6px;
      width: 0;
      z-index: 5;
    }
  }

  &--open {
    backface-visibility: visible;
    opacity: 1;
    visibility: visible;

    @media (max-width: ($s-tablet - 1)) {
      transition: transform 0.3s ease, opacity 0.3s 0s ease, visibility 0.3s 0s ease;
    }

    @media (min-width: $s-tablet) {
      transform: translateY(0);
    }
  }
}

.section__title-combolist {
  @media (max-width: ($s-tablet - 1)) {
    background-color: #fff;
    max-height: 50vh; /* Fallback for browsers that do not support Custom Properties */
    max-height: calc(var(--vh, 1vh) * 50);
    min-height: 185px;
    opacity: 0;
    overflow-y: auto;
    padding: 30px 20px;
    transform: translateY(100%);
    transition: transform 0.5s ease, opacity 0s 0.5s ease;
    width: 100%;

    .section__title-combo--open & {
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.5s ease, opacity 0s 0s ease;
    }
  }

  @media (min-width: $s-tablet) {
    padding: 0 30px;
  }
}

.section__title-comboitem {
  &:not(:last-child) {
    @media (max-width: ($s-tablet - 1)) {
      margin-bottom: 20px;
    }

    @media (min-width: $s-tablet) {
      border-bottom: 1px solid $border;
    }
  }

  &.section__title-comboitem--selected {
    a {
      @media (max-width: ($s-tablet - 1)) {
        border: 1px solid $primary;
        background-color: #fff;
      }

      @media (min-width: $s-tablet) {
        color: $primary;
      }
    }
  }

  a {
    display: block;
    font-size: 14px;

    @include transition(color);

    @media (max-width: ($s-tablet - 1)) {
      align-items: center;
      background-color: #f6f6f6;
      display: flex;
      justify-content: center;
      height: 45px;
      text-align: center;
    }

    @media (min-width: $s-tablet) {
      font-size: 30px;
      letter-spacing: 1.5px;
      padding: 20px 0;
    }

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

.section__button {
  flex-shrink: 0;
}
//Footer della sezione
.section__footer {
  border-bottom: 2px solid $border;
  padding: 30px 0;
  text-align: center;

  @media (min-width: $s-tablet) {
    padding: 40px 0;
  }

  @media (min-width: $l-tablet) {
    padding: 60px 0;
  }

  &:empty {
    padding-top: 15px;

    @media (min-width: $s-tablet) {
      padding-top: 20px;
    }

    @media (min-width: $l-tablet) {
      padding-top: 30px;
    }
  }

  .content__news & {
    border-bottom: none;
    padding-bottom: 0;

    @media (min-width: $s-tablet) {
      padding-bottom: 5px;
    }

    @media (min-width: $l-tablet) {
      padding-bottom: 25px;
    }
  }

  a,
  button {
    @include latoblack;
    font-size: 14px;
    text-transform: uppercase;
    @include transition(color);

    @media (min-width: $s-tablet) {
      font-size: 16px;
    }

    @media (min-width: $l-tablet) {
      font-size: 18px;
    }

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}
//Categoria in home
.categoryhome__container {
  margin-top: 30px;

  @media (min-width: $s-tablet) {
    margin-top: 40px;
  }
}
//Video in home
.videos__featured {
  background-color: #000;
  padding: 30px 0;

  @media (min-width: $s-tablet) {
    padding: 60px 0 160px;
  }

  .body--video & {
    @media (min-width: $s-tablet) {
      padding: 60px 0 40px;
    }
  }
}

.videos__others {
  .sectionhome & {
    @media (max-width: ($s-tablet - 1)) {
      display: none;
      margin-bottom: 40px;
    }
  }

  .body--video & {
    padding: 20px 20px 0;

    @media (min-width: $s-tablet) {
      padding: 30px 30px 0;
    }
  }

  > div {
    background-color: #fff;
    border-bottom: 2px solid $border;
    margin-top: -270px;
    padding-bottom: 35px;
    padding-top: 20px;
    position: relative;

    @media (max-width: ($medium - 1)) {
      padding-left: 10px;
      padding-right: 10px;
      width: calc(100% - 20px);
    }

    @media (min-width: $s-tablet) {
      margin-top: -115px;
    }

    &:before,
    &:after {
      background-color: #fff;
      bottom: -2px;
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      width: 20px;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }
}
//Contenuto del post
.post {
  &:first-child {
    margin-top: 30px;

    @media (min-width: $s-tablet) {
      margin-top: 50px;
    }
  }
}

.post__article {
  &:not(:first-of-type) {
    margin-top: 40px;

    @media (min-width: $s-tablet) {
      margin-top: 60px;
    }
  }
}

.post__header {
  @media (min-width: $s-tablet) {
    display: flex;
  }

  @media (min-width: $l-tablet) {
    padding: 0 25px;
  }

  .post--quiz & {
    padding: 0;
  }

  .post article:not(:first-child) & {
    @media (min-width: $l-tablet) {
      padding-right: 0;
      width: calc(100% - 340px);
    }
  }
}

.post__sidebar {
  @media (min-width: $s-tablet) {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 25px;
    text-align: center;
  }
}

.post__label {
  color: $primary;
  display: block;
  flex-shrink: 0;
  @include latobold;
  font-size: 13px;
  letter-spacing: 0.1px;
  margin-right: 20px;
  text-transform: uppercase;

  @media (min-width: $s-tablet) {
    font-size: 14px;
    margin-top: 12px;
  }

  .post__sidebar & {
    margin-right: 0;
    width: 70px;
  }
}

.post__title {
  color: #000;
  @include latoblack;
  font-size: 28px;
  line-height: 1.18;

  @media (max-width: ($s-tablet - 1)) {
    margin-top: 10px;
  }

  @media (min-width: $s-tablet) {
    font-size: 45px;
  }

  @media (min-width: $l-tablet) {
    font-size: 55px;
  }
}

.post__author {
  @include latobold;
  font-size: 12px;
  line-height: 1.5;
  margin: 12px 0 0;

  @media (min-width: $s-tablet) {
    font-size: 14px;
    margin: 20px 0 0;
  }

  span {
    color: $text-grey;
    text-transform: uppercase;
  }

  a {
    color: $primary;
    text-transform: uppercase;
  }
}

.post__featured {
  margin: 20px 0;

  @media (min-width: $s-tablet) {
    margin: 35px 0;
  }
}

.post__featured-image,
.post__featured-slider {
  @media (max-width: ($s-tablet - 1)) {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.post__featured-image,
.post__featured-slider .gallery__image {
  background-color: $grey;
  height: 0;
  overflow: hidden;
  padding-bottom: 66.58%;
  position: relative;

  &.loading {
    &:after {
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      z-index: 10;

      @media (min-width: $s-tablet) {
        margin-left: -20px;
        margin-top: -20px;
      }
    }
  }
}

.post__featured-video {
  background-color: $grey;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  @include loading;

  &:after {
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    position: absolute;
    top: 50%;
    z-index: 5;

    @media (min-width: $s-tablet) {
      margin-left: -20px;
      margin-top: -20px;
    }
  }

  > div:not(.post__featured-video__sentinel), > iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}

.post__body {
  display: flex;
  font-size: 18px;
  letter-spacing: 0.18px;
  line-height: 1.6;
  margin-top: 15px;
  width: 100%;

  @media (min-width: $s-tablet) {
    font-size: 20px;
    letter-spacing: 0.2px;
  }

  @media (max-width: ($l-tablet - 1)) {
    flex-direction: column;
  }

  @media (min-width: $l-tablet) {
    position: relative;
  }

  .post--quiz & {
    margin-top: 0;
  }
}

.post__share {
  flex-shrink: 0;
  padding: 0 50px 0 25px;

  @media (max-width: ($l-tablet - 1)) {
    display: none;
  }

  .post__sidebar & {
    margin-top: 40px;
    padding: 0;
  }

  .post__share-socialitem {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    button,
    a {
      align-items: center;
      color: rgba(0, 0, 0, 0.4);
      display: flex;
      font-size: 16px;
      height: 40px;
      justify-content: center;
      @include transition;
      width: 40px;

      .no-touchevents &:hover {
        color: $primary;
      }
    }
  }
}

.post__content > div:not(.post__intro):not(.disclaimer),
.post__spoiler > div {
  > p {
    hyphens: auto;
    margin: 10px 0;
    word-break: break-word;

    + p,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 20px;

      @media (min-width: $s-tablet) {
        margin-top: 35px;
      }
    }

    a:not([class*="icon-"]) {
      background-image: linear-gradient(#fff 50%, rgba(232, 0, 0, 0.6) 0);
      background-repeat: repeat-x;
      background-position: 0 22px;
      background-size: 1px 1px;
      color: $primary;
      hyphens: auto;
      text-decoration: none;
      position: relative;
      text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
      word-break: break-word;
      z-index: 2;
    }

    > iframe {
      width: 100%;
    }
  }

  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    color: #000;
    @include latoblack;
    line-height: 1.29;
    margin: 20px 0 15px;

    a:not([href^="#"]):not(.icon-play) {
      padding-right: 17px;
      position: relative;

      @media (min-width: $s-tablet) {
        padding-right: 20px;
      }
    }

    a {
      @include transition(color);

      .no-touchevents &:hover {
        color: $primary;

        &:after {
          color: $primary;
        }
      }
    }
  }

  > h2 {
    font-size: 25px;

    @media (min-width: $s-tablet) {
      font-size: 30px;
    }

    @media (min-width: $l-tablet) {
      font-size: 35px;
    }
  }

  > h3 {
    font-size: 22px;

    @media (min-width: $s-tablet) {
      font-size: 26px;
    }

    @media (min-width: $l-tablet) {
      font-size: 29px;
    }
  }

  > h4 {
    font-size: 20px;
  }

  > h5 {
    font-size: 18px;

    @media (min-width: $s-tablet) {
      font-size: 24px;
    }

    @media (min-width: $l-tablet) {
      font-size: 27px;
    }
  }

  > h6 {
    font-size: 17px;

    @media (min-width: $s-tablet) {
      font-size: 23px;
    }

    @media (min-width: $l-tablet) {
      font-size: 26px;
    }
  }

  > ul,
  > ol {
    padding: 0;

    ul,
    ol {
      padding: 0;
    }

    ul {
      list-style-type: circle;
      margin-left: 20px;

      ul {
        list-style-type: square;
      }
    }

    ol {
      margin-left: 30px;

      @media (min-width: $s-tablet) {
        margin-left: 40px;
      }
    }

    a:not([class*="icon-"]) {
      background-image: linear-gradient(#fff 50%, rgba(232, 0, 0, 0.6) 0);
      background-repeat: repeat-x;
      background-position: 0 22px;
      background-size: 1px 1px;
      color: $primary;
      hyphens: auto;
      text-decoration: none;
      position: relative;
      text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
      word-break: break-word;
      z-index: 2;
    }
  }

  > ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  > ol {
    margin-left: 30px;

    @media (min-width: $s-tablet) {
      margin-left: 40px;
    }
  }

  > figure,
  > article {
    margin: 20px 0;

    @media (min-width: $s-tablet) {
      margin: 35px 0;
    }

    &.post__quote {
      margin: 45px 0;

      @media (min-width: $s-tablet) {
        margin: 65px 0;
      }
    }
  }

  > .post__image--full {
    margin: 0;
  }

  > .post__embed {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.post__content {
  @media (min-width: $l-tablet) {
    width: calc(100% - 340px);
  }

  .post--quiz & {
    @media (min-width: $s-tablet) {
      display: flex;
    }
  }

  .post__share + & {
    @media (min-width: $l-tablet) {
      width: calc(100% - 455px);
    }
  }
}

.post__aside {
  flex-shrink: 0;

  @media (max-width: ($l-tablet - 1)) {
    display: none;
    margin: 20px auto 45px;
    order: -1;
  }

  @media (max-width: ($s-tablet - 1)) {
    margin: 20px auto 35px;
  }

  @media (min-width: $l-tablet) {
    margin-left: 40px;
    width: 300px;
  }

  .post--quiz & {
    @media (max-width: ($l-tablet - 1)) {
      order: initial;
    }
  }
}

.adv__aside {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  position: relative;
  text-align: center;

  @media (min-width: $s-tablet) {
    margin: 50px 0;
  }

  &.adv__aside--sticky {
    position: sticky;
    top: 0;

    .body--ontv & {
      top: 75px;
    }
  }

  &:first-child {
    margin-top: 0;
  }
}

.aside__others {
  .post__aside & {
    &:only-child {
      margin: 30px 0;

      @media (min-width: $s-tablet) {
        margin: 50px 0;
      }
    }
  }

  > h3 {
    @include latoblack;
    font-size: 16px;
    margin-bottom: 12px;
  }

  ul {
    > li {
      &:not(:last-child) {
        margin-bottom: 15px;
      }

      a {
        display: flex;
      }
    }
  }
}

.aside__others-image {
  flex-shrink: 0;
  margin-right: 20px;
  width: 110px;

  > div {
    background-color: $grey;
    height: 0;
    overflow: hidden;
    padding-bottom: 66.09%;
    position: relative;
  }

  img {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 1s ease, opacity 0.3s ease;
    width: 100%;

    .no-touchevents a:hover & {
      transform: scale(1.1);
    }
  }
}

.aside__others-caption {
  h4 {
    @include latoblack;
    font-size: 13px;
    line-height: 1.46;
    transition: color .3s ease;

    .no-touchevents a:hover & {
      color: $primary;
    }
  }
}

.post__intro {
  display: flex;
}

.post__desc {
  font-size: 20px;
  margin: 0;
  line-height: 1.57;

  @media (min-width: $l-tablet) {
    font-size: 28px;
  }

  .post--quiz & {
    font-size: 20px;
    letter-spacing: .2px;
    line-height: 1.6;
  }

  a:not([class*="icon-"]) {
    background-image: linear-gradient(#fff 50%, rgba(232, 0, 0, 0.6) 0);
    background-repeat: repeat-x;
    background-position: 0 22px;
    background-size: 1px 1px;
    color: $primary;
    text-decoration: none;
    position: relative;
    text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
    z-index: 2;
  }
}
//Prodotti presenti nel contenuto del post
.post__prodotti {
  border-bottom: 0;
  clear: both;
  overflow: hidden;

  + .post__prodotti {
    margin-top: -20px;

    @media (min-width: $s-tablet) {
      margin-top: -35px;
    }
  }
}

.prodotti__list {
  margin: 0 -15px;

  @media (min-width: $s-tablet) {
    margin: 0 -25px;
  }

  .prodotti__item {
    padding: 0 15px;
    @include transition;

    @media (min-width: $s-tablet) {
      padding: 0 25px;
    }
  }

  a {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $s-mobile) {
      flex-wrap: nowrap;
    }

    .no-touchevents &:hover {
      .prodotto__bottone {
        transform: scale(1.05);
      }
    }
  }

  .prodotto__immagine {
    align-items: center;
    display: flex;
    height: 230px;
    justify-content: center;
    position: relative;

    &.loading {

      &:after {
        height: 30px;
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        width: 30px;
        z-index: 10;
      }
    }

    img {
      display: block;
      height: 100%;
      left: 0;
      object-fit: contain;
      object-position: center center;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .prodotto__marca,
  .prodotto__title,
  .prodotto__prezzo {
    margin: 0;
    padding: 15px;

    @include transition;
    width: 50%;

    @media (min-width: $s-mobile) {
      width: 25%;
    }
  }

  .prodotto__marca {
    color: #000;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.32px;
    line-height: 1.21;

    @media (min-width: $s-tablet) {
      font-size: 16px;
    }
  }

  .prodotto__nome {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.28px;
    line-height: 1.21;

    @media (min-width: $s-tablet) {
      font-size: 14px;
    }
  }

  .prodotto__prezzo {
    color: $primary;
    @include latoblack;
    font-size: 18px;
    letter-spacing: 0.36px;
    line-height: 1.28;
  }

  .prodotto__bottone {
    align-items: center;
    background-color: $primary;
    border-radius: 5px;
    color: #fff;
    display: inline-flex;
    @include latobold;
    font-size: 12px;
    height: 30px;
    justify-content: center;
    letter-spacing: 1px;
    margin-top: 10px;
    padding: 0 15px;
    text-align: center;
    @include transition(transform, 0.5s);

    span {
      backface-visibility: hidden;
      transform: perspective(1px) translateZ(0);
    }
  }
}

.prodotti__list--espansa {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  text-align: center;

  .latest-products & {
    flex-wrap: nowrap;
    justify-content: initial;
  }

  .prodotti__item {
    display: flex;
    flex: 0 0 auto;
    width: calc(100% / 2);

    @media (min-width: $l-mobile) {
      width: calc(100% / 3);
    }

    .latest-products &,
    .products__container & {
      @media (min-width: $l-tablet) {
        width: calc(100% / 4);
      }
    }
  }

  .movie__gadgets & {
    justify-content: flex-start;
  }

  a {
    flex: 1 1 25%;
    flex-direction: column;
    padding: 20px 0;

    @media (min-width: $s-tablet) {
      padding: 35px 0;
    }
  }

  .prodotto__immagine {
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }

  .prodotto__marca,
  .prodotto__nome,
  .prodotto__prezzo {
    padding: 0 10%;
    width: 100%;
  }

  .prodotto__nome {
    margin-top: 10px;
  }

  .prodotto__prezzo {
    margin-top: 10px;
  }
}
//Credits delle immagini
.image__credits {
  background-color: #fff;
  color: #a7a7a7;
  @include latobold;
  font-size: 11px;
  margin: 0;
  padding: 3px 7px;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 5;

  .post__image--full & {
    bottom: 0;
    top: auto;
    right: 0;
  }
}
//Immagini all'interno del post
.post__image {
  &:not(.post__image--parallax):not(.post__image--full) {
    > div {
      background-color: $grey;
      height: 0;
      overflow: hidden;
      padding-bottom: 100%;
      position: relative;

      &.loading {
        &:after {
          left: 50%;
          margin-left: -15px;
          margin-top: -15px;
          position: absolute;
          top: 50%;
          z-index: 10;

          @media (min-width: $s-tablet) {
            margin-left: -20px;
            margin-top: -20px;
          }
        }
      }
    }

    img {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }

  .image__caption {
    color: #666;
    font-size: 16px;
    line-height: 1.4;
    padding: 20px 10px;
    text-align: center;
    @include transition(opacity);
  }
}

.post__image--hd {
  img {
    cursor: url(../img/zoom-in.png), zoom-in;
  }

  .image__button-full {
    align-items: center;
    bottom: 3px;
    color: #fff;
    display: flex;
    @include latobold;
    font-size: 12px;
    position: absolute;
    right: 5px;

    &:before {
      flex-shrink: 0;
      font-size: 18px;
      margin-left: 3px;
      order: 2;
    }
  }

  .image__button-nofull {
    background-color: transparent;
    border: 0;
    color: #fff;
    display: none;
    font-size: 25px;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    @include transition;
    z-index: 12;

    @media (min-width: $l-tablet) {
      font-size: 40px;
      right: 15px;
      top: 15px;
    }
  }
}

.post__image--full {
  align-items: center;
  background-color: #000;
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  margin: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 1000000010; //sennò il banner pubblicitario va sopra

  > div {
    padding-bottom: 0 !important;
    position: static;

    &.loading {
      &:after {
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        z-index: -1;

        @media (min-width: $s-tablet) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
    }
  }

  img {
    cursor: default;
    margin: auto;
    max-width: 100vw;
    max-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    max-height: calc(var(--vh, 1vh) * 100);
    width: auto;

    @media (min-width: $l-tablet) {
      max-height: calc( 100vh - 50px ); /* Fallback for browsers that do not support Custom Properties */
      max-height: calc(var(--vh, 1vh) * 100 - 50px);
    }
  }

  .image__button-full {
    display: none;
  }

  .image__caption {
    background-color: rgba(0, 0, 0, 0.6);
    border: 0;
    color: #fff;
    font-size: 19px;
    left: 15px;
    margin-right: 100px;
    min-height: 45px;
    opacity: 0;
    padding: 8px 20px;
    position: absolute;
    text-align: left;
    top: 40px;

    @media (min-width: $l-mobile) {
      @media (min-height: 500px) {
        opacity: 1;
      }
    }

    @media (min-width: $l-tablet) {
      top: 15px;
    }
  }

  .image__button-nofull {
    display: block;
    @include transition(color);

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

.post__image--parallax {
  display: flex;
  justify-content: center;
  height: 300px;
  position: relative;
  z-index: 10;

  @media only screen and (min-width: $s-tablet) {
    height: 600px;
  }

  @media only screen and (min-width: $wide) {
    height: 800px;
  }

  @media (min-width: $extreme) {
    height: 41.7vw;
  }

  > div {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 100%;
    justify-content: center;
    left: 50%;
    overflow: hidden;
    padding-bottom: 0;
    position: absolute;
    transform: translateX(-50%);
    width: 100vw;

    @media (min-width: $l-tablet) {
      left: calc(50% + 112px);
    }
  }

  img {
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0);
    z-index: 5;

    @media (max-width: ($s-tablet - 1)) {
      height: 140%;
      width: auto;
    }

    @media (min-width: $s-tablet) {
      min-width: 110vh; /* Fallback for browsers that do not support Custom Properties */
      min-width: calc(var(--vh, 1vh) * 110);
      width: 100%;
    }
  }
}
//Video all'interno del post e video nel news feed
.post__player,
.newsfeed__video {
  backface-visibility: hidden;
  background-color: #000;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;

  &.loading {
    a,
    div {
      &:before {
        opacity: 0;
      }
    }
  }

  a,
  div {
    display: block;

    &:before {
      align-items: center;
      background-color: rgba(232, 0, 0, 0.85);
      border-radius: 50%;
      color: #fff;
      display: flex;
      font-size: 21px;
      font-weight: 700;
      height: 65px;
      justify-content: center;
      left: 50%;
      padding-left: 6px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      @include transition;
      width: 65px;
      z-index: 10;

      @media (min-width: $s-tablet) {
        font-size: 35px;
        height: 105px;
        width: 105px;
      }

      .videos__others .newsfeed__item--small & {
        font-size: 12px;
        height: 30px;
        padding-left: 2px;
        width: 30px;
      }
    }

    .no-touchevents &:hover {
      &:before {
        background-color: $primary;
      }

      img {
        transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0);
      }
    }
  }

  img {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale3d(1.01, 1.01, 1) translate3d(0, 0, 0); //to fix pixel on transform bug in Chrome
    transition: transform 1s ease, opacity 0.3s ease;
    width: 100%;
  }

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  }
}

.newsfeed__video {
  .newsfeed__item--small &,
  .newsfeed__item--video & {
    .body--video & {
      height: auto;
      padding-bottom: 0;

      > div {
        height: 0;
        overflow: hidden;
        padding-bottom: 56.25%;
      }
    }
  }

  .newsfeed__item--small & {
    .body--video & {
      @media (max-width: $l-mobile - 1) {
        flex: 0 0 auto;
        width: 140px;
      }
    }
  }

  .newsfeed__item--video & {
    .body--video & {
      @media (max-width: 1211px) {
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        width: 100vw;
      }
    }
  }

  .newsfeed__item--video--sticky & {
    .icon-x {
      display: block;
    }
  }

  .icon-x {
    color: #fff;
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
  }
}

.post__player {
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  a {
    &:before {
      font-size: 28px;
      height: 60px;
      width: 60px;

      @media (min-width: $s-tablet) {
        font-size: 33px;
        height: 70px;
        width: 70px;
      }
    }
  }
}
//Modulo purchase all'interno del post
.post__purchase {
  .purchase__caption {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;

    @media (min-width: $s-tablet) {
      padding: 25px;
    }

    @media (min-width: $wide) {
      padding: 35px;
    }
  }

  .purchase__eyelet {
    align-items: center;
    color: $primary;
    display: flex;
    @include latoblack;
    font-size: 14px;
    letter-spacing: 0.28px;
    text-transform: uppercase;
  }

  .purchase__title {
    color: #000;
    font-size: 22px;
    font-weight: 700;
    margin: 10px 0 20px;

    @media (min-width: $s-tablet) {
      margin: 0 0 5px;
    }
  }

  .purchase__desc,
  .purchase__price {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.45;
  }

  .purchase__desc {
    line-height: 30px;
    margin: 0 0 5px;
  }

  .purchase__price {
    font-weight: 700;
  }

  .purchase__details {
    font-size: 16px;
    line-height: 1.6;
    padding-right: 30px;

    @media (max-width: ($s-tablet - 1)) {
      padding-right: 0;
    }
  }

  .purchase__button {
    background-color: $primary;
    border-radius: 5px;
    @include latoblack;
    font-size: 14px;
    height: 55px;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    width: 100%;

    @media (max-width: ($s-tablet - 1)) {
      margin-top: 25px;
    }

    .no-touchevents &:hover {
      transform: scale(1.05);
    }

    a {
      align-items: center;
      color: #fff;
      display: flex;
      height: 100%;
      justify-content: center;
      text-align: center;
      width: 100%;
    }
  }
}

.post__purchase--single {
  border: 1px solid $grey;

  > div {
    display: block;
    width: 100%;

    @media (min-width: $s-tablet) {
      display: flex;
    }
  }

  .purchase__image {
    padding: 20px;

    @media (min-width: $s-tablet) {
      align-items: center;
      border-right: 1px solid $grey;
      display: flex;
      flex: 0 0 auto;
      height: 435px;
      justify-content: center;
      overflow: hidden;
      width: 47.7%;
    }

    &.loading {
      position: relative;

      &:after {
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        z-index: 10;

        @media (min-width: $s-tablet) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
    }

    .purchase__pagination,
    .purchase__eyelet,
    .purchase__title {
      display: block;

      @media (min-width: $s-tablet) {
        display: none;
      }
    }

    .purchase__pagination {
      margin-bottom: 10px;
    }

    img {
      height: auto;
      width: 100%;

      @media (min-width: $s-tablet) {
        max-width: 100%;
        max-height: 100%;
        width: auto;
      }
    }
  }

  .purchase__caption {
    flex: 1 1 auto;
    width: 100%;

    .purchase__pagination,
    .purchase__eyelet,
    .purchase__title {
      @media (max-width: ($s-tablet - 1)) {
        display: none;
      }
    }
  }

  .purchase__pagination {
    @include latoblack;
    font-size: 14px;
    margin-bottom: 10px;

    strong {
      color: $primary;
      font-weight: normal;
    }
  }

  .purchase__eyelet {
    margin-bottom: 10px;
  }

  .purchase__button {
    @include transition(transform, 0.5s);

    a {
      backface-visibility: hidden;
      transform: perspective(1px) translateZ(0);
    }
  }
}
//Modulo citazione all'interno del post
.post__quote {
  blockquote {
    margin: 0;
    padding: 40px 0;
    position: relative;

    @media (min-width: $s-tablet) {
      padding: 50px 0;
    }

    &:before,
    &:after {
      color: $primary;
      font-family: "PT Serif", serif;
      font-size: 80px;
      height: 30px;
      left: 50%;
      position: absolute;
      text-align: center;
      transform: translateX(-50%);
      width: 60px;
    }

    &:before {
      content: "“";
      line-height: 1.05;
      top: 0;
    }

    &:after {
      content: "”";
      bottom: 0;
      line-height: 0.8;
    }
  }

  p {
    font-size: 24px;
    hyphens: auto;
    letter-spacing: 0.48px;
    line-height: 1.43;
    margin: 0;
    text-align: center;
    word-break: break-word;

    @media (min-width: $s-tablet) {
      font-size: 28px;
      letter-spacing: 0.56px;
    }
  }
}
//Modulo embed all'interno del post
.post__embed {
  .fb_iframe_widget {
    display: block;
    text-align: center;

    span,
    iframe {
      max-width: 100% !important;
    }
  }

  iframe {
    margin: 0 auto !important;
    min-width: 0 !important;
  }
}
//Modulo sondaggio all'interno del post
.post__poll {
  background-color: #f9f9f9;
  border-radius: 3px;
  clear: both;
  margin: 50px auto 20px;
  position: relative;

  &.post__poll--double {
    .poll__option {
      width: 50%;
    }
  }

  &.post__poll--three {
    .poll__option {
      @media (min-width: $l-mobile) {
        width: calc(100% / 3);
      }
    }
  }

  &.vai {
    .poll__approfondisci {
      opacity: 1;
      transition: opacity 0.3s ease, z-index 0s 0s;
      z-index: 5;

      &:after {
        transition: width 5s linear 0s;
        width: 100%;
      }

      p {
        display: inline-block;
        margin: 0;
      }
    }
  }

  > div {
    margin: 0;
    overflow: hidden;
  }

  .poll__header {
    border-bottom: 1px solid $border;
    padding: 15px;
    position: relative;

    @media (min-width: $s-tablet) {
      padding: 35px 40px;
    }
  }

  .poll__approfondisci {
    align-items: center;
    background-color: #fff;
    color: #000;
    display: flex;
    @include latobold;
    font-size: 15px;
    height: 100%;
    justify-content: space-between;
    left: 0;
    line-height: 1.3;
    opacity: 0;
    padding: 15px 10px;
    position: absolute;
    top: 0;
    transition: opacity 0.3s ease, z-index 0s 0.3s;
    width: 100%;
    z-index: -5;

    @media (min-width: $s-tablet) {
      font-size: 17px;
    }

    &:before {
      background-color: #000;
      content: "";
      height: 4px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:after {
      background-color: $primary;
      content: "";
      height: 4px;
      left: 0;
      position: absolute;
      top: 0;
      transition: width 0.1s linear 0.3s;
      width: 0;
    }
  }

  .poll__label {
    align-items: center;
    border-radius: 5px;
    background-color: $primary;
    color: #fff;
    display: inline-flex;
    @include latobold;
    font-size: 16px;
    height: 30px;
    justify-content: center;
    padding: 0 10px;
    text-align: center;

    @media (min-width: $s-tablet) {
      font-size: 19px;
      height: 35px;
      padding: 0 15px;
    }
  }

  .poll__domanda {
    align-items: center;
    color: #000;
    display: flex;
    @include latoblack;
    font-size: 20px;
    line-height: 1.3;
    margin: 0;
    padding-top: 15px;

    @media (min-width: $s-tablet) {
      font-size: 24px;
      padding-top: 20px;
    }
  }

  .poll__options {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2px 0 0;
    position: relative;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }

  .poll__option {
    display: flex;
    flex: 0 0 auto;
    margin: 0;
    padding-right: 1px;
    position: relative;
    width: 50%;

    @media (min-width: $l-mobile) {
      width: calc(100% / 3);
    }

    @media (min-width: $s-tablet) {
      width: 25%;
    }

    @media (min-width: $l-tablet) {
      width: calc(100% / 3);
    }

    @media (min-width: $medium) {
      width: 25%;
    }

    + .poll__option {
      overflow: hidden;

      p:first-child {
        padding: 15px;
        z-index: 9;

        @media (min-width: $s-tablet) {
          padding: 22px;
        }
      }
    }

    > button {
      color: #000;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-decoration: none;
      width: 100%;

      span {
        background-color: #fff;
        display: block;
        flex: none;
        position: relative;

        b {
          display: block;
          opacity: 0.9;

          @include transition(opacity);

          &.loading {
            background-color: $grey;
            height: 0;
            padding-bottom: 150%;
            position: relative;

            &:after {
              left: 50%;
              margin-left: -15px;
              margin-top: -15px;
              position: absolute;
              top: 50%;
              z-index: 10;

              @media (min-width: $s-tablet) {
                margin-left: -20px;
                margin-top: -20px;
              }
            }
          }
        }

        &:after {
          border: 4px solid $primary;
          bottom: 0;
          content: "";
          left: 0;
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;

          @include transition(opacity);
        }
      }

      .no-touchevents &:hover {
        p:first-child {
          background-color: $primary;
          color: #fff;
        }

        span {
          b {
            opacity: 1;
          }

          &:after {
            opacity: 1;
          }
        }
      }
    }

    p:first-child {
      align-items: center;
      color: #000;
      display: flex;
      flex-grow: 1;
      @include latoregular;
      font-size: 14px;
      justify-content: center;
      line-height: 1.25;
      margin: 0;
      max-width: 100%;
      padding: 15px;
      position: relative;
      text-align: center;
      @include transition;
      width: 100%;
      z-index: 10;

      @media (min-width: $s-tablet) {
        font-size: 18px;
        padding: 22px;
      }

      &:before {
        background-color: #ecebeb;
        content: "";
        height: 100%;
        position: absolute;
        right: -1px;
        top: 0;
        width: 1px;
      }
    }

    &.poll__option--ok {
      p:first-child {
        background-color: $primary;
        color: #fff;
      }

      span {
        &:before {
          background: url(../img/ok.png) 50% 50% no-repeat transparentize($primary, 0.5);
          background-size: 60px;
          bottom: 0;
          content: "";
          left: 0;
          position: absolute;
          right: 1px;
          top: 0;
          @include transition;
          z-index: 10;

          @media (min-width: $l-mobile) {
            background-size: auto;
          }
        }

        &:after {
          opacity: 1;
        }
      }

      .poll__numero {
        background-color: transparentize($primary, 0.1);
      }
    }
  }

  .poll__options--results {
    .poll__option {
      > a {
        pointer-events: none;
      }

      + .poll__option--ok {
        p:first-child {
          border-left-width: 1px;
          border-right-width: 0;
          padding-left: 10px;

          @media (min-width: $s-tablet) {
            padding-left: 30px;
          }
        }
      }
    }

    .poll__option--ok {
      p:first-child {
        background-color: $primary;
        border-left-width: 0;
        color: #fff;
        padding-left: 11px;
      }

      span:before {
        opacity: 0;
      }

      .poll__numero {
        background-color: transparentize($primary, 0.1);
      }
    }

    .poll__numero {
      bottom: 0;
      opacity: 1;
    }
  }

  .poll__numero {
    align-items: center;
    background-color: transparentize(#929292, 0.1);
    bottom: -100%;
    color: #fff;
    display: flex;
    @include latobold;
    font-size: 20px;
    justify-content: center;
    left: 0;
    margin: 0;
    max-height: 70%;
    min-height: 15%;
    opacity: 0;
    position: absolute;
    right: 1px;
    text-align: center;
    @include transition;
    z-index: 10;

    @media (min-width: $s-tablet) {
      font-size: 35px;
      max-height: 80%;
    }

    button {
      background: transparent;
      border: 0;
      color: #fff;
      cursor: pointer;
      font-size: 15px;
      height: 38px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;

      @include transition;
      -webkit-appearance: none;
      width: 38px !important;

      &:focus {
        outline: none;
      }

      .no-touchevents &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .poll__countdown {
    color: $primary;
    font-size: 34px;
    margin-left: 7px;
    position: relative;
    top: -3px;
    vertical-align: middle;

    @media (min-width: $s-tablet) {
      font-size: 39px;
      margin-left: 10px;
    }
  }

  .clear-timer {
    color: $primary;
    font-size: 12px;
    text-decoration: underline;

    @media (min-width: $s-tablet) {
      font-size: 14px;
    }
  }

  .poll__condividi {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.95);
    bottom: 0;
    color: #fff;
    flex-direction: column;
    left: 0;
    margin: 0;
    opacity: 0;
    padding-top: 75px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transition: opacity 0.3s ease, z-index 0s ease 0.3s;
    z-index: -15;

    &.open {
      opacity: 1;
      transition: opacity 0.3s ease, z-index 0s ease 0s;
      z-index: 15;
    }

    div {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;

      p {
        @include latobold;
        font-size: 14px;
        margin: 0 0 10px;

        @media (min-width: $s-tablet) {
          font-size: 17px;
        }
      }
    }

    ul {
      display: flex;
      list-style: none;
      margin: 0 10%;
      padding: 0;

      li {
        display: inline-block;
        flex: 1;
        vertical-align: middle;
      }
    }

    a {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 50%;
      color: #fff;
      display: block;
      height: 40px;
      overflow: hidden;
      padding-top: 8px;
      @include transition(background-color);

      @media (min-width: $s-tablet) {
        height: 50px;
        padding-top: 10px;
      }
    }

    .icon-facebook {
      background-color: $facebook;

      .no-touchevents &:hover {
        background-color: darken($facebook, 10%);
      }
    }

    .icon-twitter {
      background-color: $twitter;

      .no-touchevents &:hover {
        background-color: darken($twitter, 10%);
      }
    }

    .icon-whatsapp {
      background-color: $whatsapp;

      .no-touchevents &:hover {
        background-color: darken($whatsapp, 10%);
      }
    }

    .close {
      background-position: 100% 50%;
      background-size: auto;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
      height: auto;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 15px;
      text-indent: 0;
      top: 10px;
      width: auto;

      @media (min-width: $s-tablet) {
        right: 20px;
        top: 20px;
      }

      .no-touchevents &:hover {
        opacity: 0.8;

        &:before {
          transform: none;
        }
      }
    }
  }
}
//Modulo spoiler all'interno del post
.post__spoiler {
  &--active {
    height: 265px;
    overflow: hidden;
    position: relative;

    > div {
      filter: blur(12px);
      padding: 0 15px;

      > p,
      > h2,
      > h3,
      > h4,
      > h5,
      > h6 {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0 0 15px #000;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }

  > button {
    align-items: center;
    background-color: $primary;
    border: 3px solid #fff;
    border-radius: 30px;
    color: #fff;
    display: flex;
    @include latoblack;
    font-size: 13px;
    height: 63px;
    left: 50%;
    letter-spacing: 0.26px;
    justify-content: center;
    padding-left: 57px;
    position: absolute;
    text-align: center;
    top: 100px;
    transform: translateX(-50%);
    width: 260px;
    z-index: 5;

    @media (min-width: $xs-mobile) {
      width: 300px;
    }

    @media (min-width: $s-tablet) {
      border-radius: 40px;
      font-size: 16px;
      height: 73px;
      letter-spacing: 0.32px;
      padding-left: 67px;
      width: 390px;
    }

    &:before {
      background: #fff url(../img/icon-spoiler.svg) no-repeat 50% 50%;
      background-size: contain;
      border: 3px solid #fff;
      border-radius: 50%;
      content: "";
      display: block;
      height: 63px;
      left: -3px;
      position: absolute;
      top: -3px;
      transition: transform 0.3s;
      width: 63px;
      z-index: 5;

      @media (min-width: $s-tablet) {
        height: 73px;
        width: 73px;
      }
    }

    &:hover {
      &:before {
        background: #fff url(../img/icon-spoiler-hover.gif) no-repeat 50% 50%;
        background-size: 85px;
        transform: scale(1.1);
      }
    }
  }

  > div {
    opacity: 0.5;
    @include transition(opacity);

    &.-open {
      opacity: 1;
    }
  }
}

//Modulo recensione all'interno del post
.post__review {
  background-color: #f9f9f9;
  border-radius: 3px;
  padding: 20px 25px;

  @media (min-width: $s-tablet) {
    padding: 35px 40px;
  }

  .review__score {
    align-items: center;
    border-radius: 5px;
    background-color: $primary;
    color: #fff;
    display: inline-flex;
    @include latobold;
    font-size: 16px;
    height: 30px;
    justify-content: center;
    padding: 0 10px;
    text-align: center;

    @media (min-width: $s-tablet) {
      font-size: 19px;
      height: 35px;
      padding: 0 15px;
    }
  }

  .review__text {
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 1.6;
    margin: 15px 0;

    @media (min-width: $s-tablet) {
      font-size: 20px;
      margin: 25px 0;
    }

    p {
      margin: 0;
    }
  }

  .review__author {
    align-items: center;
    display: flex;

    &[href] {
      .no-touchevents &:hover {
        span {
          color: $primary;
        }
      }
    }

    img {
      border-radius: 50%;
      display: block;
      flex-shrink: 0;
      margin-right: 12px;
      width: 50px;
    }

    span {
      color: #000;
      @include latobold;
      font-size: 14px;
      @include transition;
    }
  }
}

//Modulo guida tv all'interno del post
.post__guide {
  > header {
    background-color: #000000;
    border-radius: 5px;
    color: #fff;
    @include latobold;
    padding: 25px;

    strong {
      display: block;
      font-size: 14px;
      margin-bottom: 7px;
    }

    h3 {
      font-size: 23px;
      line-height: 1.3;
    }
  }
}

//Modulo star all'interno del post
.post__star {
  border: 1px solid $border;
  border-radius: 5px;
  display: flex;

  @media (max-width: $l-mobile - 1) {
    align-items: center;
    flex-direction: column;
    padding: 35px 15px;
    text-align: center;
  }

  @media (min-width: $l-mobile) {
    padding: 15px;
  }

  @media (min-width: $s-tablet) {
    padding: 25px;
  }
}

.post__star-image {
  width: 160px;

  @media (min-width: $l-mobile) {
    width: 38.8%;
  }

  > div {
    background-color: $grey;
    border-radius: 5px;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;

    &.loading {
      &:after {
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        z-index: 10;

        @media (min-width: $s-tablet) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
    }
  }

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.post__star-caption {
  @media (max-width: $l-mobile - 1) {
    margin-top: 35px;
  }

  @media (min-width: $l-mobile) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 4.2%;
    width: calc(100% - 38.8%);
  }

  @media (min-width: $s-tablet) {
    padding-left: 6.2%;
  }

  strong {
    color: #000;
    display: block;
    @include latoblack;
    font-size: 23px;
    line-height: 1.1;

    @media (min-width: $s-tablet) {
      font-size: 27px;
    }

    @media (min-width: $l-tablet) {
      font-size: 30px;
    }
  }

  p {
    @include latoregular;
    font-size: 14px;
    letter-spacing: 0.14px;
    line-height: 1.3;
    margin: 7px 0 0;

    @media (min-width: $s-tablet) {
      font-size: 16px;
      letter-spacing: 0.16px;
    }
  }
}

//Modulo map all'interno del post
.post__maps {
  background-color: $grey;
  height: 0;
  overflow: hidden;
  padding-bottom: 75%;
  position: relative;
  width: 100%;

  &.loading {
    &:after {
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      z-index: 10;

      @media (min-width: $s-tablet) {
        margin-left: -20px;
        margin-top: -20px;
      }
    }
  }

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  }
}

//Modulo movie all'interno del post
.moviecard {
  background-color: #fff;
  border: 1px solid $border;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  padding: 20px 15px;

  @media (min-width: $s-tablet) {
    align-items: flex-start;
    display: flex;
    padding: 30px 30px 0;
  }
}

.moviecard__poster {
  width: 120px;

  @media (max-width: ($s-tablet - 1)) {
    margin: 0 auto;
  }

  @media (min-width: $s-tablet) {
    flex-shrink: 0;
    width: 175px;
  }

  > div {
    &.loading {
      background-color: $grey;
      height: 0;
      padding-bottom: 150%;
      position: relative;

      &:after {
        height: 30px;
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        width: 30px;
        z-index: 10;
      }
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.moviecard__info {
  position: relative;

  @media (max-width: ($s-tablet - 1)) {
    margin-top: 20px;
  }

  @media (min-width: $s-tablet) {
    padding-left: 35px;
  }

  h4 {
    @include latoblack;
    font-size: 20px;
    line-height: 1.2;
    margin-top: 10px;

    @media (min-width: $s-tablet) {
      font-size: 28px;
    }
  }

  > span {
    display: block;
    @include latoregular;
    font-size: 14px;
    letter-spacing: 0.14px;

    @media (min-width: $s-tablet) {
      font-size: 16px;
      letter-spacing: 0.16px;
    }
  }
}

.moviecard__intro-vote {
  align-items: center;
  display: flex;
  margin-top: 10px;

  @media (min-width: $s-tablet) {
    margin-top: 15px;
  }

  &.voted {
    button {
      cursor: default;
      pointer-events: none;
    }
  }

  strong {
    font-size: 14px;
    letter-spacing: .28px;
    margin-right: 10px;

    @media (min-width: $s-tablet) {
      font-size: 16px;
      letter-spacing: .32px;
    }
  }

  button {
    align-items: center;
    border: 1px solid #333;
    border-radius: 50%;
    display: flex;
    font-size: 17px;
    height: 34px;
    justify-content: center;
    position: relative;
    @include transition(transform);
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    width: 34px;

    &:before,
    &:after {
      background-repeat: no-repeat;
      content: '';
      display: block;
      height: 100%;
      left: -40%;
      position: absolute;
      transition: all ease-in-out 0.5s;
      width: 200%;
      z-index: 5;
    }

    &:before {
      background-image: radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, transparent 20%, $primary 20%, transparent 30%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, transparent 10%, $primary 15%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%);
      background-size: 25% 25%, 35% 35%, 30% 30%, 35% 35%, 33% 33%, 25% 25%, 30% 30%, 25% 25%, 33% 33%;
      display: none;
      top: -95%;
    }

    &:after {
      background-image: radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, transparent 10%, $primary 15%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%);
      background-size: 30% 30%, 35% 35%, 33% 33%, 35% 35%, 30% 30%, 25% 25%, 30% 30%;
      bottom: -105%;
      display: none;
    }

    .no-touchevents &:hover {
      transform: scale(1.1);
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    &.is-animating {
      &:before {
        animation: topBubbles ease-in-out 0.75s forwards;
        display: block;
      }

      &:after {
        animation: bottomBubbles ease-in-out 0.75s forwards;
        display: block;
      }
    }

    &.moviecard__intro-vote-yes {
      span:before {
        position: relative;
        top: -1px;
      }
    }

    &.moviecard__intro-vote-no {
      span:before {
        transform: rotate(180deg);
      }
    }

    span {
      &:before {
        backface-visibility: hidden;
        transform: translate3d(0,0,0) scaleX(1);
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}

.moviecard__intro-meta {
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 1.44;
  margin-top: 15px;

  @media (min-width: $s-tablet) {
    font-size: 16px;
    letter-spacing: 0.16px;
  }

  > li {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  a {
    color: $primary;
    text-decoration: underline;

    .no-touchevents &:hover {
      text-decoration: none;
    }
  }
}

.moviecard__footer {
  align-items: center;
  border: solid 1px #e0e0e0;
  border-radius: 0 0 5px 5px;
  display: flex;
  padding: 20px 15px;

  @media (max-width: ($s-tablet - 1)) {
    flex-direction: column;
  }

  @media (min-width: $s-tablet) {
    border-top: none;
    justify-content: space-between;
    padding: 20px 30px;
  }
}

.moviecard__button {
  > a {
    align-items: center;
    background-color: $primary;
    border-radius: 3px;
    color: #fff;
    display: flex;
    @include latoregular;
    font-size: 16px;
    letter-spacing: 0.16px;
    height: 35px;
    justify-content: center;
    text-align: center;
    @include transition(background-color);
    width: 175px;

    @media (min-width: $s-tablet) {
      font-size: 18px;
      height: 45px;
      letter-spacing: 0.18px;
    }

    .no-touchevents &:hover {
      background-color: #111;
    }
  }
}
//Modulo gallery all'interno del post
.gallery {
  position: relative;

  .post__gallery & {
    border-bottom: 2px solid $border;
    padding-bottom: 50px;
  }
}

.gallery__image {
  cursor: pointer;
  width: 100%;

  .post__gallery & {
    background-color: $grey;
    height: 0;
    padding-bottom: 66.15%;
    position: relative;
  }

  > a {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  img {
    display: block;

    .post__gallery & {
      left: 50%;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.gallery__controls {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 25px 0 20px;

  @media (min-width: $s-tablet) {
    justify-content: flex-end;
    margin: 25px 0 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background: none;
    height: 35px;
    margin-top: 0;
    position: static;
    width: 35px;

    &:before {
      font-size: 27px;
      @include transition;
    }

    &:focus {
      outline: none;
    }

    .no-touchevents &:hover {
      color: $primary;
    }
  }

  .swiper-button-prev {
    text-align: right;
  }

  .swiper-button-next {
    text-align: left;
  }

  .swiper-pagination {
    align-items: center;
    display: flex;
    @include latobold;
    font-size: 16px;
    justify-content: center;
    margin-top: -7px;
    padding: 0 5px;
    position: static;
    text-align: center;
    width: 75px;
  }

  .swiper-pagination-bullet {
    background: none;
    border-radius: 0;
  }
}

.gallery__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 45px;
  z-index: 15;

  @media (min-width: $s-tablet) {
    height: 55px;
  }

  .gallery--big & {
    @media (min-width: $s-tablet) {
      height: 85px;
    }
  }
}

.gallery__photogallery {
  align-items: center;
  background-color: $primary;
  color: #fff;
  display: flex;
  font-size: 19px;
  justify-content: center;
  left: 0;

  @include transition(width, 0.5s);
  width: 45px;

  @media (min-width: $s-tablet) {
    font-size: 23px;
    width: 55px;
  }

  .no-touchevents &:hover {
    width: 65px;

    @media (min-width: $s-tablet) {
      width: 75px;
    }
  }

  .gallery--big & {
    @media (min-width: $s-tablet) {
      font-size: 35px;
      width: 85px;
    }

    .no-touchevents &:hover {
      @media (min-width: $s-tablet) {
        width: 105px;
      }
    }
  }
}

.gallery__open {
  align-items: center;
  background-color: $primary;
  color: #fff;
  display: flex;
  @include latoblack;
  font-size: 13px;
  line-height: 1.14;
  padding: 0 15px;
  right: 0;
  text-align: left;
  @include transition(width, 0.5s);
  width: 130px;

  @media (min-width: $s-tablet) {
    font-size: 14px;
    width: 155px;
  }

  &:before {
    font-size: 13px;
    margin-left: 15px;
    order: 2;

    @media (min-width: $s-tablet) {
      font-size: 18px;
      margin-left: 25px;
    }

    .gallery--big & {
      @media (min-width: $s-tablet) {
        margin-left: 30px;
      }
    }
  }

  .no-touchevents &:hover {
    width: 160px;

    @media (min-width: $s-tablet) {
      width: 185px;
    }
  }

  .gallery--big & {
    @media (min-width: $s-tablet) {
      font-size: 16px;
      padding: 0 30px;
      width: 200px;
    }

    .no-touchevents &:hover {
      @media (min-width: $s-tablet) {
        width: 230px;
      }
    }
  }
}

.gallery__caption {
  padding: 0 10px;

  @media (min-width: $s-tablet) {
    margin-top: -30px;
    padding: 0 20px 0 30px;
  }
}

.gallery__caption-label {
  color: $primary;
  display: block;

  @include latoblack;
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 20px;
  text-transform: uppercase;

  @media (min-width: $s-tablet) {
    font-size: 16px;
    margin-bottom: 40px;
    padding-right: 130px;
  }

  span {
    color: #000;
    text-transform: none;
  }
}

.gallery__caption-title {
  color: #000;
  @include latoblack;
  font-size: 16px;
  line-height: 1.2;

  @media (min-width: $s-tablet) {
    font-size: 21px;
  }
}

.gallery__caption-desc {
  font-size: 13px;
  line-height: 1.2;
  margin: 5px 0 0;

  @media (min-width: $s-tablet) {
    font-size: 16px;
    margin: 10px 0 0;
  }
}

//Modulo faq all'interno del post
.post__faq {
  .movie__section & {
    margin-top: 15px;

    @media (min-width: $s-tablet) {
      margin-top: 30px;
    }
  }
}

.faq__item {
  border: solid 1px #e9e9e9;
  border-radius: 5px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 1.5;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.faq__button {
  display: flex;
  font-weight: 700;
  padding: 11px 15px;
  text-align: left;
  width: 100%;

  &:before {
    color: $primary;
    flex-shrink: 0;
    font-size: 18px;
    margin-right: 20px;
    transition: transform 0.3s ease;

    .faq__item.faq__item--open & {
      transform: rotate(90deg);
    }
  }
}

.faq__content {
  overflow: hidden;
  transition: height 0.3s ease;

  .faq__item:not(.faq__item--open) & {
    display: none;
  }

  > div {
    padding: 11px 15px 25px 41px;
  }

  p,
  ul,
  ol,
  img,
  iframe {
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    background-image: linear-gradient(#fff 50%, rgba(232, 0, 0, 0.6) 0);
    background-repeat: repeat-x;
    background-position: 0 19px;
    background-size: 1px 1px;
    color: $primary;
    text-decoration: none;
    position: relative;
    text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
    z-index: 2;
  }

  ul,
  ol {
    padding: 0;

    > ul {
      list-style-type: circle;

      > ul {
        list-style-type: square;
      }
    }
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  ol {
    margin-left: 30px;

    @media (min-width: $s-tablet) {
      margin-left: 40px;
    }
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  iframe {
    max-width: 100%;
  }
}

//Footer del post
.post__footer {
  width: 100%;

  @media (min-width: $l-tablet) {
    margin-top: 100px;
    max-width: 700px;
  }
}
//Correlati del post
.post__related {
  padding-top: 80px;
  position: relative;
  z-index: 5;

  @media (min-width: $l-tablet) {
    padding-top: 100px;
  }

  h3 {
    @include latoblack;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 20px;

    @media (min-width: $s-tablet) {
      font-size: 21px;
      margin-bottom: 35px;
    }
  }
}
//Tags del post
.post__footer-tags {
  .tags__list {
    display: flex;
    flex-wrap: wrap;
    margin: -7.5px;
  }

  .tags__item {
    padding: 7.5px;

    > a {
      align-items: center;
      background-color: #f9f9f9;
      border: 1px solid #f2f2f2;
      border-radius: 5px;
      display: flex;
      font-size: 14px;
      height: 35px;
      justify-content: center;
      letter-spacing: 0.1px;
      min-width: 90px;
      padding: 5px 15px;
      text-align: center;

      @include transition;

      .no-touchevents &:hover {
        background-color: $primary;
        border-color: $primary;
        color: #fff;
      }
    }
  }
}
//Share nel footer del post
.post__footer-share {
  color: rgba(0, 0, 0, 0.5);
  margin-top: 45px;

  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;

    &:first-child {
      @media (max-width: ($l-tablet - 1)) {
        display: none;
      }
    }
  }

  .post__share-social {
    align-items: center;
    display: flex;

    span {
      color: rgba(0, 0, 0, 0.5);
      @include latoregular;
      font-size: 12px;
      letter-spacing: 0.1px;
      margin-right: 15px;
    }

    button {
      &:not(:first-of-type) {
        margin-left: 25px;
      }

      &:before {
        color: rgba(0, 0, 0, 0.4);
        font-size: 20px;

        @include transition;
      }

      .no-touchevents &:hover {
        &:before {
          color: $primary;
        }
      }
    }
  }
}
//Commenta nel footer del post
.post__footer-review {
  background-color: $primary;
  border-radius: 5px;
  flex-direction: column;
  padding: 15px;

  @media (min-width: $s-tablet) {
    padding: 25px;
  }

  > span {
    align-self: flex-start;
    color: #fff;
    display: block;
    font-size: 18px;
    margin-bottom: 15px;

    @media (min-width: $xs-mobile) {
      font-size: 20px;
    }

    @media (min-width: $s-tablet) {
      font-size: 28px;
    }
  }

  .fb-comments {
    background-color: #fff;
    border-radius: 5px;
    display: block;
    min-height: 198px;
    min-width: 100%;
    padding: 10px;
    position: relative;

    &:before {
      border-bottom: 30px solid #fff;
      border-left: 30px solid transparent;
      content: "";
      display: block;
      height: 0;
      position: absolute;
      right: 7%;
      top: -30px;
      width: 0;
      z-index: 5;

      @media (min-width: $s-tablet) {
        border-bottom-width: 50px;
        border-left-width: 50px;
        right: 17%;
        top: -50px;
      }
    }

    iframe {
      min-width: 100%;
    }
  }
}

.post__footer-newsletter {
  background-color: $primary;
  border-radius: 5px;
  flex-direction: column;
  @include latoregular;
  padding: 15px;

  @media (min-width: $s-tablet) {
    padding: 25px;
  }

  > strong {
    align-self: flex-start;
    color: #fff;
    display: block;
    font-size: 18px;
    margin: 0 auto 15px;

    @media (min-width: $xs-mobile) {
      font-size: 20px;
    }

    @media (min-width: $s-tablet) {
      font-size: 28px;
    }
  }
}

.post__newsletter-box {
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;

  p {
    color: #000;
    font-size: 14px;
    letter-spacing: 0.14px;
    line-height: 1.4;
    margin: 1em 0;

    @media (min-width: $s-tablet) {
      font-size: 18px;
      letter-spacing: 0.18px;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  form {
    display: flex;
    margin: 20px 0 15px;

    @media (max-width: $s-tablet - 1) {
      align-items: center;
      flex-direction: column;
    }

    @media (min-width: $s-tablet) {
      margin: 25px 0 20px;
    }

    input {
      border: 1px solid $border;
      border-radius: 5px;
      display: block;
      @include latoregular;
      font-size: 14px;
      height: 40px;
      letter-spacing: 0.14px;
      padding: 0 15px;
      transition: border-color .3s ease, color .3s ease;
      width: 100%;

      @media (max-width: $s-tablet - 1) {
        margin-bottom: 10px;
        text-align: center;
      }

      @media (min-width: $s-tablet) {
        margin-right: 5px;
      }

      &:focus {
        outline: none;
      }

      &.is-error {
        border-color: #ff0000;
        color: #ff0000;
      }
    }
  }

  small {
    color: #999999;
    display: block;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 1.4;

    a {
      text-decoration: underline;
      @include transition(color);

      .no-touchevents &:hover {
        color: $primary;
      }
    }
  }
}

.post__newsletter-strip {
  display: flex;
  transition: transform .5s ease;

  > div {
    flex: 0 0 auto;
    height: 0;
    opacity: 0;
    padding: 15px;
    width: 100%;

    @media (min-width: $s-tablet) {
      padding: 20px;
    }

    &.is-active {
      height: auto;
      opacity: 1;
    }

    &:last-child {
      > div {
        display: none;

        &.is-active {
          display: block;
        }
      }
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.post__newsletter-alt {
  &:before {
    background: #fff url(../img/alt.svg) no-repeat 50% 50%;
    background-size: contain;
    content: "";
    display: block;
    height: 75px;
    margin: 0 auto 1em;
    width: 75px;

    @media (min-width: $s-tablet) {
      height: 100px;
      width: 100px;
    }
  }
}

.post__newsletter-check {
  &:before {
    background: #fff url(../img/check-email.svg) no-repeat 50% 50%;
    background-size: contain;
    content: "";
    display: block;
    height: 75px;
    margin: 0 auto 1em;
    width: 75px;

    @media (min-width: $s-tablet) {
      height: 100px;
      width: 100px;
    }
  }
}

//Bottone di share a mobile presente in più pagine
.share__button {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  bottom: 25px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  font-size: 17px;
  height: 45px;
  justify-content: center;
  position: fixed;
  right: 15px;
  @include transition;
  width: 45px;
  z-index: 100;

  @media (min-width: $l-tablet) {
    display: none;
  }

  &.share__button--active {
    color: $primary;
  }
}
//Layer di share presente in più pagine
.share {
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.95);
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 50px 40px;
  position: fixed;
  top: 0;
  @include transition;
  visibility: hidden;
  width: 100%;
  z-index: 99;

  @media (min-width: $s-tablet) {
    padding: 75px 40px;
  }

  @media (min-width: $l-tablet) {
    display: none;
  }

  &.share--open {
    backface-visibility: visible;
    opacity: 1;
    visibility: visible;
  }

  > span {
    color: $primary;
    display: block;
    @include latoregular;
    font-size: 15px;
    letter-spacing: 0.5px;
    line-height: normal;
    text-transform: uppercase;
  }
}

.share__list {
  display: inline-flex;
  flex-direction: column;
  margin-top: 18.5vh; /* Fallback for browsers that do not support Custom Properties */
  margin-top: calc(var(--vh, 1vh) * 18.5);
}

.share__item {
  &:not(:last-child) {
    margin-bottom: 30px;

    @media (min-width: $s-tablet) {
      margin-bottom: 50px;
    }
  }

  button {
    color: #fff;
    font-size: 24px;
    line-height: normal;
    letter-spacing: 0.7px;

    @include transition(opacity);

    @media (min-width: $s-tablet) {
      font-size: 30px;
      letter-spacing: 1.2px;
    }

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}
//Fullscreen gallery
.fullscreen {
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;

  @include transition;
  visibility: hidden;
  width: 100%;
  z-index: 1000000000; //sennò il banner pubblicitario va sopra

  @media (min-width: $l-tablet) {
    left: 50%;
    transform: translateX(-50%);
  }

  &--open {
    backface-visibility: visible;
    opacity: 1;
    visibility: visible;
  }

  .no-js & {
    transition: none;
  }

  .body--withskin & {
    @media (min-width: $l-tablet) {
      max-width: $container-skin;
      top: 0;
    }
  }
}

.fullscreen__wrapper {
  background-color: #fff;

  @media (max-width: ($l-tablet - 1)) {
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 100);
  }

  @media (min-width: $l-tablet) {
    height: 100%;
  }
}

.fullscreen__buttons {
  position: fixed;
  z-index: 20;

  @media (max-width: ($l-tablet - 1)) {
    bottom: 85px;
    right: 15px;
  }

  @media (min-width: $l-tablet) {
    left: 10px;
    top: 35px;
  }
}

.fullscreen__button {
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .no-touchevents &:hover,
  &.fullscreen__button--selected {
    button {
      background-color: $primary;
      border-color: $primary;
      color: #fff;
    }
  }

  button {
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    font-size: 16px;
    height: 45px;
    justify-content: center;

    @include transition;
    width: 45px;

    @media (min-width: $l-tablet) {
      font-size: 20px;
      height: 50px;
      width: 50px;
    }
  }
}

.fullscreen__inner {
  background-color: #fff;

  @media (max-width: ($l-tablet - 1)) {
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 100);
    padding: 0 50px;
  }

  @media (max-width: ($s-tablet - 1)) {
    padding: 35px 35px 0;
  }

  @media (min-width: $l-tablet) {
    display: flex;
    height: 100%;
    padding-left: 35px;
  }
}

.fullscreen__images {
  position: relative;

  @media (min-width: $l-tablet) {
    flex: 0 0 auto;
    width: 60%;
  }

  @media (min-width: $medium) {
    width: 64.4%;
  }

  .body--withskin & {
    @media (min-width: $l-tablet) {
      width: 60%;
    }

    @media (min-width: 1367px) {
      width: 64.4%;
    }
  }
}

.fullscreen__slider {
  display: none;
  height: 100%;
  position: relative;

  @media (max-width: ($l-tablet - 1)) {
    height: 68vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 68);
  }

  &.fullscreen__slider--active {
    display: block;
  }

  .swiper-container {
    height: 100%;
    user-select: none;
  }
}

.fullscreen__image {
  background-color: $grey;
  height: 100%;
  position: relative;
  width: 100%;

  img {
    display: block;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.fullscreen__slider-button {
  align-items: center;
  background: none;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  height: 90px;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @include transition(background-color);
  width: 35px;
  z-index: 10;

  @media (min-width: $s-tablet) {
    font-size: 25px;
    height: 110px;
    width: 50px;
  }

  &:focus {
    outline: none;
  }

  &--prev {
    left: -35px;

    @media (min-width: $s-tablet) {
      left: -50px;
    }

    @media (min-width: $l-tablet) {
      left: -25px;
    }
  }

  &--next {
    right: -35px;

    @media (min-width: $s-tablet) {
      right: -50px;
    }

    @media (min-width: $l-tablet) {
      right: -25px;
    }
  }

  &.swiper-button-disabled {
    display: none;
  }

  .no-touchevents &:hover {
    background-color: $primary;
  }
}

.fullscreen__images-list {
  background-color: #fff;
  display: none;
  height: 100%;
  left: 0;
  overflow-y: auto;
  padding: 40px 20px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  @media (min-width: $l-tablet) {
    padding: 35px 35px 35px 60px;
    position: absolute;
  }

  &--active {
    display: block;
  }

  > ul {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;

    @media (min-width: $s-tablet) {
      margin: -10px;
    }

    @media (min-width: $l-tablet) {
      margin: -15px;
    }
  }
}

.fullscreen__images-item {
  flex: 0 0 auto;
  padding: 5px;
  width: calc(100% / 3);

  @media (min-width: $s-tablet) {
    padding: 10px;
    width: calc(100% / 4);
  }

  @media (min-width: $l-tablet) {
    padding: 15px;
    width: calc(100% / 3);
  }

  @media (min-width: $medium) {
    width: calc(100% / 4);
  }

  &--active {
    a {
      &:before {
        align-items: center;
        background-color: rgba(232, 0, 0, 0.5);
        color: #fff;
        content: attr(data-idx);
        display: flex;
        @include latobold;
        font-size: 40px;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;

        @media (min-width: $s-tablet) {
          font-size: 60px;
        }
      }
    }
  }

  &.loading {
    position: relative;

    &:after {
      height: 30px;
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      width: 30px;
      z-index: 10;
    }

    a {
      background-color: $grey;
      display: block;
      height: 0;
      padding-bottom: 150%;
    }
  }

  a {
    align-items: center;
    display: flex;
    position: relative;
    height: 100%;
    @include transition(transform, 1s);

    .no-touchevents &:hover {
      transform: scale(1.04);
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.fullscreen__aside {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px 0;

  @media (min-width: $s-tablet) {
    padding: 35px 0;
  }

  @media (min-width: $l-tablet) {
    flex: 0 0 auto;
    padding: 35px 35px 35px 40px;
    position: relative;
    width: 40%;
  }

  @media (min-width: $medium) {
    width: 35.6%;
  }
}

.fullscreen__exit {
  align-items: center;
  color: #999;
  display: flex;
  font-size: 15px;
  height: 35px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  z-index: 15;

  @media (min-width: $s-tablet) {
    font-size: 20px;
    height: 50px;
    width: 50px;
  }
}

.fullscreen__slider-pagination {
  @include latobold;
  flex-shrink: 0;
  font-size: 11px;

  @media (min-width: $s-tablet) {
    font-size: 15px;
  }

  @media (min-width: $l-tablet) {
    font-size: 18px;
  }
}

.fullscreen__caption {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 15px 0 0;
  padding-right: 35px;

  @media (min-width: $s-tablet) {
    margin: 55px 0 0;
  }

  @media (min-width: $l-tablet) {
    padding-right: 0;
  }

  > div {
    &:first-child {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

.fullscreen__caption-title {
  color: #000;
  @include latoblack;
  font-size: 18px;
  line-height: 1;

  @media (min-width: $s-tablet) {
    font-size: 30px;
  }
}

.fullscreen__caption-desc,
.fullscreen__caption-location {
  display: block;
  font-size: 13px;
  line-height: 1.53;
  margin: 10px 0 0;

  @media (min-width: $s-tablet) {
    font-size: 16px;
    margin: 15px 0 0;
  }
}

.fullscreen__share {
  flex-shrink: 0;
  margin-left: 25px;

  @media (max-width: ($l-tablet - 1)) {
    display: none;
  }

  .fullscreen__share-socialitem {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    button,
    a {
      align-items: center;
      color: rgba(0, 0, 0, 0.4);
      display: flex;
      font-size: 16px;
      height: 35px;
      justify-content: center;
      @include transition;
      width: 35px;

      .no-touchevents &:hover {
        color: $primary;
      }
    }
  }
}

.fullscreen__banner {
  @media (max-width: ($l-tablet - 1)) {
    display: none;
  }
}
//Categorie
.categories {
  margin-top: 30px;

  @media (min-width: $s-tablet) {
    margin-top: 50px;
  }

  &.categories--movies {
    @media (min-width: $s-tablet) {
      margin: 50px 0;
    }
  }

  .videos & {
    background-color: #000;
    margin-top: 0;
    padding: 30px 0;

    @media (min-width: $s-tablet) {
      padding: 50px 0 0;
    }
  }

  .guide & {
    margin-bottom: 30px;

    @media (min-width: $s-tablet) {
      margin-bottom: 50px;
    }
  }
}

.categories__list {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;

  @media (min-width: $s-tablet) {
    margin: -14px;
  }

  @media (min-width: $l-tablet) {
    margin: -19px;
  }

  .categories--movies & {
    justify-content: center;
    margin: -10px;
  }
}

.categories__item {
  flex: 0 0 auto;
  padding: 10px;
  width: calc(100% / 2);

  @media (min-width: $s-tablet) {
    padding: 14px;
    width: calc(100% / 3);
  }

  @media (min-width: $l-tablet) {
    padding: 19px;
    width: calc(100% / 4);
  }

  @media (min-width: $medium) {
    width: calc(100% / 5);
  }

  &.categories__item--xs {
    padding: 10px;
    width: calc(100% / 4);

    @media (min-width: $s-mobile) {
      width: calc(100% / 5);
    }

    @media (min-width: $l-mobile) {
      width: calc(100% / 8);
    }

    @media (min-width: $l-tablet) {
      width: calc(100% / 10);
    }
  }

  > a {
    .categories:not(.categories--bgcolor):not(.categories--bggrey) & {
      .no-touchevents &:hover {
        .categories__image {
          transform: translate3d(0, 0, 0) scale(1.07);
        }

        .categories__title {
          color: $primary;
        }
      }
    }

    .categories--bgcolor &,
    .categories--bggrey & {
      align-items: center;
      backface-visibility: hidden;
      border-radius: 5px;
      display: flex;
      height: 80px;
      padding: 0 20px;
      justify-content: center;
      transform: translateZ(0);
      @include transition(transform, 1s);

      @media (min-width: $s-tablet) {
        height: 90px;
      }

      .no-touchevents &:hover {
        transform: translate3d(0, 0, 0) scale(1.07);
      }
    }

    .categories--bggrey & {
      background-color: #f7f7f7;
    }
  }
}

.categories__image {
  backface-visibility: hidden;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px;
  height: 0;
  padding-bottom: 50%;
  position: relative;
  transform: translate3d(0, 0, 0);
  @include transition(transform, 1s);

  &:before {
    color: #fff;
    font-size: 25px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: $s-tablet) {
      font-size: 35px;
    }
  }

  &.loading {
    background-color: $grey;

    &:after {
      height: 30px;
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      width: 30px;
      z-index: 10;
    }
  }

  .categories__item--all & {
    background-color: $primary;
  }

  .categories__item--xs & {
    padding-bottom: 100%;

    &.loading {
      background-color: #000;
    }
  }

  .categories--bgcolor &,
  .categories--bggrey & {
    display: none;
  }
}

.categories__title {
  display: block;
  @include latobold;
  font-size: 14px;
  line-height: 1.31;
  margin-top: 10px;
  text-align: center;
  @include transition(color);

  @media (min-width: $s-tablet) {
    font-size: 16px;
  }

  .main__header--catalogue &,
  .videos & {
    color: #fff;
  }

  .categories--bgcolor &,
  .categories--bggrey & {
    font-size: 15px;
    line-height: 1;
    margin-top: 0;

    @media (min-width: $s-tablet) {
      font-size: 17px;
    }
  }

  .categories--bgcolor & {
    color: #fff;
    text-transform: uppercase;
  }
}

.products__container-desc {
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 0.8px;
  margin: 10px 0 0;
  position: relative;

  @media (min-width: $s-tablet) {
    margin: 15px 0 0;
    padding: 0 20px;
  }

  .more {
    background-color: #fff;
    bottom: 0;
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: 0.8px;
    padding: 5px 5px 0 0;
    position: absolute;
    right: 0;
    text-align: left;
    width: 83%;
    z-index: 2;

    @media (min-width: $s-tablet) {
      display: none;
    }

    &:before {
      content: "...";
      margin-right: 2px;
    }

    span {
      background-image: linear-gradient(#fff 50%, rgba(0, 0, 0, 0.6) 0);
      background-repeat: repeat-x;
      background-position: 0 22px;
      background-size: 1px 1px;
      display: inline-block;
      text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
    }
  }
}

.content__news {
  h2 {
    @include latoblack;
    font-size: 18px;
    line-height: 1.5;
    margin: 0 0 15px;

    @media (min-width: $s-tablet) {
      font-size: 25px;
      margin-bottom: 25px;
    }
  }
}

.content__news-inner {
  border-bottom: 1px solid $border;
  border-top: 1px solid $border;
  padding: 30px 0;

  @media (min-width: $s-tablet) {
    padding: 35px 0;
  }
}
/* Banner dei cookies */
.cookies {
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  color: #000;
  display: flex;
  @include latoregular;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  @include hidden;
  left: 0;
  position: fixed;
  top: 0;
  transition: visibility 0.3s, opacity 0.3s;
  width: 100%;
  z-index: 1000000000; //sennò il banner pubblicitario va sopra

  &.is-open {
    @include visible;
  }

  > div {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: 25px 15px;
    width: 100%;

    @media (min-width: $s-tablet) {
      padding: 50px 30px;
    }

    @media (min-width: $l-tablet) {
      align-items: flex-end;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
    }

    @media (min-width: $wide) {
      padding: 75px 55px;
    }

    > div {
      &:last-child {
        flex-shrink: 0;

        @media (max-width: $l-tablet - 1) {
          margin-top: 15px;
        }

        @media (max-width: $s-tablet - 1) {
          margin-top: 10px;
        }
      }
    }
  }

  img {
    display: block;
    height: auto;
    margin-bottom: 30px;
    width: 120px;

    @media (max-width: $l-tablet - 1) {
      display: none;
    }
  }

  p {
    flex: 1 1 auto;
    font-size: 12px;
    letter-spacing: 0.3px;
    line-height: 1.23;
    margin: 0 0 10px;

    @media (min-width: $s-tablet) {
      font-size: 15px;
      margin: 0 0 15px;
    }

    @media (min-width: $l-tablet) {
      margin: 0 50px 0 0;
    }

    @media (min-width: $medium) {
      margin: 0 100px 0 0;
    }

    a,
    button {
      text-decoration: underline;
      transition: color 0.5s;

      .no-touchevents &:hover {
        color: $primary;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.cookies__title {
  display: block;
  font-size: 20px;
  @include latobold;
  line-height: 1.1;
  margin-bottom: 10px;

  @media (min-width: $s-tablet) {
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 15px;
  }

  @media (min-width: $wide) {
    font-size: 27px;
  }
}

.cookies__link {
  display: block;
  font-size: 12px;
  margin: 0 0 15px;
  text-decoration: underline;
  transition: color 0.5s;

  @media (min-width: $s-tablet) {
    font-size: 12px;
    margin: 0 0 20px;
  }

  @media (min-width: $l-tablet) {
    margin: 0 auto 20px;
  }

  .no-touchevents &:hover {
    color: $primary;
  }
}
//Pagina dell'archivio news
.archive__filters {
  border-bottom: 1px solid $border;
  display: flex;
  padding: 30px 0 35px;

  @media (max-width: ($s-tablet - 1)) {
    flex-wrap: wrap;
  }

  > div {
    flex: 1 1 auto;
    position: relative;
    width: 100%;

    @media (min-width: $s-tablet) {
      width: calc(100% / 3);
    }

    @media (min-width: $l-tablet) {
      flex: 0 0 auto;
      width: calc(100% / 5);
    }

    &:not(:last-child) {
      @media (max-width: ($s-tablet - 1)) {
        margin-bottom: 20px;
      }

      @media (min-width: $s-tablet) {
        margin-right: 20px;
      }
    }

    &:before {
      align-items: center;
      background-color: $grey;
      color: #000;
      display: flex;
      font-size: 16px;
      height: 100%;
      justify-content: center;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      z-index: 5;
    }
  }

  select {
    color: #000;
    background-color: $grey;
    border: none;
    font-family: "PT Serif", serif;
    font-size: 18px;
    font-weight: 700;
    height: 40px;
    padding: 0 25px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
}

.archive__pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 100px;

  @media (min-width: $l-tablet) {
    margin-top: 150px;
  }
}

.archive__pages {
  display: flex;
  margin: 0 10px;

  @media (min-width: $xs-mobile) {
    margin: 0 20px;
  }

  @media (min-width: $s-tablet) {
    margin: 0 30px;
  }
}

.archive__page {
  margin: 0 5px;

  @media (min-width: $xs-mobile) {
    margin: 0 7px;
  }

  @media (min-width: $s-tablet) {
    margin: 0 10px;
  }

  a {
    color: #000;
    font-size: 17px;
    @include transition(color);

    @media (min-width: $s-tablet) {
      font-size: 19px;
    }

    &.current,
    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

.archive__prev,
.archive__next {
  color: #000;
  flex-shrink: 0;
  font-size: 14px;
  @include transition(color);

  @media (min-width: $s-tablet) {
    font-size: 16px;
  }

  .no-touchevents &:hover {
    color: $primary;
  }
}

.archive__prev {
  order: -1;
}
//Pagina della redazione
.team__container {
  border-bottom: 1px solid $border;
  padding: 30px 0;

  @media (min-width: $s-tablet) {
    padding: 45px 0;
  }

  @media (min-width: $medium) {
    padding: 60px 0;
  }
}

.team__subtitle {
  color: #000;
  @include latoblack;
  font-size: 25px;
  margin-bottom: 30px;

  @media (min-width: $s-tablet) {
    font-size: 30px;
    margin-bottom: 40px;
  }
}

.team__item {
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  > a {
    align-items: center;
    display: inline-flex;
  }
}

.team__avatar {
  border-radius: 50%;
  height: 60px;
  overflow: hidden;
  width: 60px;

  @media (min-width: $s-tablet) {
    height: 80px;
    width: 80px;
  }

  @media (min-width: $medium) {
    height: 100px;
    width: 100px;
  }

  > img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.team__caption {
  color: #000;
  font-size: 18px;
  margin-left: 20px;

  @media (min-width: $s-tablet) {
    font-size: 22px;
    margin-left: 30px;
  }

  @media (min-width: $medium) {
    font-size: 25px;
    margin-left: 35px;
  }

  strong,
  span {
    display: block;
  }

  strong {
    @include latoblack;
    margin-bottom: 5px;
  }
}

//Pagina singolo redattore
.author__avatar {
  border-radius: 50%;
  float: left;
  height: 45px;
  margin-right: 15px;
  position: relative;
  top: -3px;
  width: 45px;

  @media (min-width: $s-tablet) {
    height: 60px;
    margin-right: 20px;
    width: 60px;
  }

  @media (min-width: $l-tablet) {
    height: 80px;
    margin-right: 30px;
    top: -8px;
    width: 80px;
  }
}

.author__socials {
  border-bottom: 1px solid $border;
  display: flex;
  padding: 50px 0;
}

.author__social {
  &:not(:last-child) {
    margin-right: 55px;
  }

  > a {
    color: #9a9a9a;
    font-size: 25px;
    @include transition(color);

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

//Pagina di errore
.error__image {
  margin-top: 15px;
  text-align: center;

  @media (min-width: $s-tablet) {
    margin-top: 25px;
  }

  @media (min-width: $l-tablet) {
    margin-top: 35px;
  }

  img, video {
    display: inline-block;
    height: auto;
    max-width: 100%;
  }
}

//Nuova sezione Film&Serie
.movies__filters {
  margin-top: 30px;

  @media (min-width: $s-tablet) {
    margin-top: 45px;
  }

  &.is-hidden {
    @media (max-width: ($l-mobile - 1)) {
      display: none;
    }
  }

  &.movies__filters--mobile {
    align-items: flex-end;
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    height: 100%;
    left: 0;
    margin-top: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.3s ease 0.1s, visibility 0.3s ease 0.2s, z-index 0s ease 0.3s;
    visibility: hidden;
    width: 100%;
    z-index: -1000000000; //sennò il banner pubblicitario va sopra

    @media (min-width: $l-mobile) {
      display: none;
    }

    &.is-open {
      backface-visibility: visible;
      opacity: 1;
      transition: opacity 0.3s ease 0s, visibility 0.3s ease 0s, z-index ease 0s;
      visibility: visible;
      z-index: 1000000000; //sennò il banner pubblicitario va sopra

      > div {
        opacity: 1;
        transform: translateX(0);
        transition: transform 0.3s ease 0.2s, opacity 0.3s ease 0.2s;
      }
    }

    > div {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      height: 100%;
      transform: translateX(-100%);
      transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;

      > ul {
        flex: 1;
        margin: 0;
        overflow-y: auto;
        padding-bottom: 120px;
        width: 100%;

        @media (max-width: ($l-mobile - 1)) {
          flex-direction: column;
          flex-wrap: nowrap;
        }
      }
    }
  }

  .categories--movies + .movies & {
    border-top: 2px solid #f3f3f3;
    margin-top: 25px;
    padding-top: 30px;

    @media (min-width: $s-tablet) {
      margin-top: 50px;
    }
  }

  .movies__panel--genres & {
    margin-top: 0;
  }

  > div {
    > ul {
      display: flex;
      flex-wrap: wrap;
      margin: -7.5px;

      @media (min-width: $s-tablet) {
        margin: -12.5px;
      }
    }
  }
}

.movie__filters-header {
  display: flex;
  @include latobold;
  padding: 15px;

  .movies__filters--desktop & {
    display: none;
  }

  span {
    font-size: 14px;
  }

  button {
    &:first-child {
      font-size: 14px;
      margin-right: 25px;
    }

    &:last-child {
      font-size: 13px;
      font-weight: 700;
      margin-left: auto;
    }
  }
}

.movies__filter {
  flex: 0 0 auto;
  padding: 7.5px;
  position: relative;
  width: calc(100% / 2);

  @media (min-width: $l-mobile) {
    flex: 1 1 auto;
    width: calc(100% / 4);
  }

  @media (min-width: $s-tablet) {
    padding: 12.5px;
  }

  &.movies__filter--select {
    > button {
      padding-right: 30px;

      @media (min-width: $s-tablet) {
        padding-right: 45px;
      }

      &:before {
        font-size: 10px;
        margin-top: 1px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 5;

        @media (min-width: $s-tablet) {
          font-size: 14px;
          right: 15px;
        }
      }
    }
  }

  &:not(.movies__filter--select) {
    > button,
    > a {
      .no-touchevents &:hover,
      &.is-active {
        background-color: #111;
        color: #fff;

        &:before {
          color: #fff;
        }
      }

      .no-touchevents &:hover {
        background-color: $primary;
      }
    }
  }

  .movies__filters--catalogue & {
    flex: 0 0 auto;

    @media (min-width: $l-mobile) {
      width: calc(100% / 3);
    }

    @media (min-width: $l-tablet) {
      width: calc(100% / 4);
    }
  }

  .movies__search-form & {
    > button,
    > input {
      background-color: #fff;
      border: none;
      height: 40px;
      justify-content: flex-start;
      text-align: left;

      @media (min-width: $s-tablet) {
        height: 45px;
      }
    }
  }

  .movies__filters--desktop & {
    @media (max-width: ($l-mobile - 1)) {
      display: none;
    }
  }

  .movies__filters--mobile & {
    padding: 15px;
    width: 100%;

    > button {
      display: none;
    }

    > input {
      font-size: 14px;
      height: 35px;
    }
  }

  .movies__panel--genres & {
    flex: 0 0 auto;
  }

  > button,
  > input,
  > a {
    align-items: center;
    background-color: #f8f8f8;
    border: solid 1px #e9e9e9;
    border-radius: 5px;
    color: #000;
    display: flex;
    @include latobold;
    font-size: 14px;
    height: 45px;
    justify-content: center;
    padding: 0 10px;
    position: relative;
    text-align: center;
    @include transition;
    width: 100%;

    @media (min-width: $s-tablet) {
      font-size: 16px;
      height: 55px;
      padding: 0 15px;
    }

    .movies__filters--catalogue &,
    .movies__filters--mobile & {
      justify-content: flex-start;
      text-align: left;
    }

    .movies__filters--desktop & {
      padding: 0 20px;
    }
  }

  > input {
    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      /* Edge */
      color: #bababa;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bababa;
    }

    &::placeholder {
      color: #bababa;
    }
  }
}

.movies__filter--search {
  @media (min-width: $l-mobile) {
    flex: 0 0 50px;
  }

  > a {
    @media (min-width: $l-mobile) {
      padding: 0;
      width: 55px;
    }
  }
}

.movies__filter-options {
  backface-visibility: hidden;
  background-color: #fff;
  box-shadow: 1px 1.7px 50px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: row;
  left: 5px;
  opacity: 0;
  position: absolute;
  right: 5px;
  top: 47px;
  transform: translateY(20px);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.2s linear;
  visibility: hidden;
  z-index: 20;

  @media (min-width: $s-tablet) {
    top: 65px;
  }

  &:before {
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    right: 13px;
    top: -6px;
    width: 0;
    z-index: 5;

    @media (min-width: $s-tablet) {
      right: 25px;
    }
  }

  &.is-open {
    backface-visibility: visible;
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  .movies__filters--desktop & {
    top: 75px;

    @media (min-width: $s-tablet) {
      top: 93px;
    }
  }

  .movies__filters--mobile & {
    @media (max-width: ($l-mobile - 1)) {
      background-color: transparent;
      backface-visibility: visible;
      box-shadow: none;
      position: static;
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }

  .movie__filter-label ~ & {
    top: 70px; //44px + 26px (altezza della label)
    @media (min-width: $s-tablet) {
      top: 83px; //55px + 28px (altezza della label)
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    max-height: 270px;
    padding: 10px;
    width: 100%;

    @media (min-width: $l-mobile) {
      max-height: 370px;
    }

    .movies__filters--mobile & {
      max-height: none;
      padding: 0;

      ul {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
        overflow-y: visible;
        padding-right: 0;
      }
    }

    ul {
      height: 100%;
      margin-right: -10px;
      overflow-y: auto;
      padding-right: 10px;
    }
  }
}

.movies__filter-options-search {
  margin: 10px 0;
  position: relative;

  &:before {
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;

    @media (min-width: $s-tablet) {
      font-size: 14px;
    }
  }

  input {
    border: 1px solid #ebebeb;
    font-size: 14px;
    height: 35px;
    padding: 0 10px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
}

.movies__filter-options-slider {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px;

  > span {
    flex-shrink: 0;
    @include latobold;
    font-size: 14px;

    @media (min-width: $s-tablet) {
      font-size: 16px;
    }
  }

  > div {
    flex: 1;
    margin: 0 20px;
    width: 100%;
  }
}

.movies__filter-option {
  &:not(:last-child) {
    margin-bottom: 7px;

    @media (min-width: $s-tablet) {
      margin-bottom: 10px;
    }
  }

  .movies__filters--mobile & {
    flex: 0 0 auto;
    padding: 5px;
    width: 50%;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .movies__filter-options-search + ul & {
    &:only-child {
      display: none;
    }
  }

  button {
    align-items: center;
    background-color: #f6f6f6;
    border: 1px solid transparent;
    border-radius: 3px;
    color: #000;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    height: 35px;
    justify-content: center;
    padding: 0 10px;
    text-align: center;
    @include transition;
    width: 100%;

    &.is-selected {
      border-color: $primary;
      background-color: #fff;
      color: $primary;
    }

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

.movies__filter-apply {
  align-items: center;
  background-color: $primary;
  border: 1px solid transparent;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-size: 12px;
  margin-top: 10px;
  height: 40px;
  justify-content: center;
  padding: 0 10px;
  text-align: center;
  transition: all 0.3s ease;
  width: 100%;

  @media (min-width: $s-tablet) {
    font-size: 14px;
    height: 45px;
    margin-top: 20px;
  }

  .movies__filters--desktop > div > ul + & {
    @media (min-width: $l-mobile) {
      display: none;
    }
  }

  .movies__filters--desktop > div > ul + & {
    display: none;
  }

  .movies__filters--mobile .movies__filter-options & {
    display: none;
  }

  .movies__filters--mobile & {
    border-radius: 0;
    flex-shrink: 0;
    margin-top: 0;
  }
}

.movie__filter-label {
  color: #000;
  display: block;
  @include latobold;
  font-size: 14px;
  margin-bottom: 10px;

  @media (min-width: $s-tablet) {
    font-size: 16px;
  }

  .movies__filters--mobile & {
    color: $primary;
  }
}

.movies__filter-filtered {
  .movies__filters--mobile & {
    display: none;
  }

  > div {
    align-items: center;
    display: flex;
    @include latobold;
    font-size: 12px;
    margin-top: 10px;

    @media (min-width: $s-tablet) {
      font-size: 14px;
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  button {
    &:before {
      align-items: center;
      background-color: #f4f4f4;
      color: #000;
      display: flex;
      flex-shrink: 0;
      font-size: 8px;
      height: 20px;
      justify-content: center;
      margin-right: 5px;
      @include transition(color);
      width: 20px;
    }

    .no-touchevents &:hover {
      &:before {
        color: $primary;
      }
    }
  }
}

.movie__filter-suggested {
  background-color: #fff;
  border: solid 1px #e9e9e9;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  display: none;
  left: 50%;
  position: absolute;
  top: calc(100% - 15px);
  transform: translateX(-50%);
  width: calc(100% - 30px);
  z-index: 10;

  @media (min-width: $l-mobile) {
    top: 78px;
    width: calc(100% - 15px);
  }

  @media (min-width: $s-tablet) {
    top: 95px;
    width: calc(100% - 25px);
  }

  &.is-open {
    display: block;

    + input {
      border-radius: 5px 5px 0 0;
    }
  }

  > div {
    backface-visibility: hidden;
    max-height: 205px;
    min-height: 60px;
    overflow-y: auto;
    padding: 20px 10px;
    transform: translate3d(0, 0, 0);

    @media (min-width: $l-mobile) {
      max-height: 200px;
      min-height: 100px;
      padding: 25px 15px;
    }

    @media (min-width: $s-tablet) {
      padding: 20px;
    }
  }

  button {
    display: block;
    font-size: 14px;
    text-align: left;
    @include transition(color);
    width: 100%;

    @media (min-width: $s-tablet) {
      font-size: 16px;
    }

    &:not(:first-child) {
      margin-top: 20px;

      @media (min-width: $l-mobile) {
        margin-top: 20px;
      }
    }

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

.movies__container {
  margin-top: 25px;

  @media (min-width: $s-tablet) {
    margin-top: 35px;
  }

  .search__results & {
    margin-top: 0;
  }
}

.movies__wrapper {
  margin-top: 45px;

  @media (min-width: $s-tablet) {
    margin-top: 80px;
  }
}

.movies__list {
  display: flex;
  flex-wrap: wrap;
  margin: -7.5px;

  @media (min-width: $s-tablet) {
    margin: -12.5px;
  }
}

.movies__item {
  flex: 0 0 auto;
  font-family: "Lato", sans-serif;
  height: auto;
  padding: 7.5px;
  width: calc(100% / 2);

  @media (min-width: $s-mobile) {
    width: calc(100% / 3);
  }

  @media (min-width: $s-tablet) {
    padding: 12.5px;
    width: calc(100% / 5);
  }

  @media (min-width: $l-tablet) {
    width: calc(100% / 6);
  }

  &.movies__item--banner {
    width: 100%;
  }

  .movies__panel-slider &,
  .movies__strip &,
  .star__movies & {
    padding-bottom: 0;
    padding-top: 0;
  }

  .movie__relateds & {
    @media (min-width: $s-tablet) {
      width: calc(100% / 4);
    }
  }

  .star__movies & {
    @media (min-width: $medium) {
      width: calc(100% / 7);
    }
  }
}

.movies__item--adv {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ($s-tablet - 1)) {
    width: 100%;
  }
}

.movies__item--noresult {
  color: #000;
  @include latobold;
  font-size: 14px;
  text-align: center;
  width: 100%;

  @media (min-width: $s-tablet) {
    font-size: 16px;
  }

  img {
    border-radius: 50%;
    display: block;
    margin: 0 auto 10px;

    @media (max-width: ($s-tablet - 1)) {
      height: auto;
      width: 100px;
    }
  }
}

.adv__item {
  flex-shrink: 0;
}

.movie__card {
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out, opacity 0.2s ease-out;
  transition-delay: 0.1s;

  @media (max-width: ($s-tablet - 1)) {
    display: none;
  }

  .no-touchevents &:hover {
    box-shadow: rgba(45, 45, 45, 0.03) 0px 1px 1px, rgba(49, 49, 49, 0.03) 0px 2px 2px, rgba(42, 42, 42, 0.03) 0px 4px 4px, rgba(32, 32, 32, 0.03) 0px 8px 8px, rgba(49, 49, 49, 0.03) 0px 16px 16px, rgba(35, 35, 35, 0.03) 0px 32px 32px;
    transform: translate(0, -4px);
  }
}

.movie__cover {
  flex-shrink: 0;
  position: relative;

  > a {
    background-color: $grey;
    border-radius: 3px 3px 0 0;
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    z-index: 5;

    &.loading {
      &:after {
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        z-index: 10;

        @media (min-width: $s-tablet) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
    }
  }

  img {
    display: block;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.movie__tags {
  align-items: flex-start;
  bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  font-family: "Lato", sans-serif;
  left: 10px;
  max-width: calc(100% - 20px);
  position: absolute;
  z-index: 10;

  .moviecard__info &,
  .movie__intro-social & {
    position: static;
    max-width: none;
    width: auto;
  }

  .movie__intro-social & {
    @media (max-width: ($l-tablet - 1)) {
      display: none;
    }

    @media (min-width: $l-tablet) {
      flex-direction: column;
      margin-top: 40px;
    }

    .movie__intro--collapsed & {
      margin-top: 0;
    }
  }
}

.movie__tag {
  background-color: #fff;
  border-radius: 3px;
  color: #111;
  display: block;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 0.1px;
  line-height: 1.6;
  margin-top: 7px;
  padding: 1px 7px;
  pointer-events: none;
  text-transform: uppercase;
  @include transition;

  @media (min-width: $s-tablet) {
    font-size: 12px;
    margin-top: 5px;
    padding: 3px 10px;
  }

  &:not(:last-child) {
    margin-right: 5px;
  }

  &.movie__tag--xs {
    @media (min-width: $s-tablet) {
      font-size: 10px;
      padding: 1px 10px;
    }
  }

  &.movie__tag--xl {
    @media (min-width: $s-tablet) {
      font-size: 13px;
      padding: 5px 10px;
    }
  }

  &.movie__tag--series {
    background-color: $series;
    color: #fff;
  }

  &.movie__tag--movie {
    background-color: $movie;
    color: #fff;
  }

  &.movie__tag--netflix {
    background-color: $netflix;
    color: #fff;
  }

  &.movie__tag--apple-tv-plus {
    background-color: $apple-tv-plus;
    color: #fff;
  }

  &.movie__tag--amazon-prime-video {
    background-color: $amazon-prime-video;
    color: #fff;
  }

  &.movie__tag--disney-plus {
    background-color: $disney-plus;
    color: #fff;
  }

  &.movie__tag--now-tv {
    background-color: $now-tv;
    color: #fff;
  }

  &.movie__tag--timvision {
    background-color: $timvision;
    color: #fff;
  }

  &.movie__tag--infinity {
    background-color: $infinity;
    color: #fff;
  }

  &.movie__tag--sky-go {
    background-color: $sky-go;
    color: #fff;
  }

  &.movie__tag--rai-play {
    background-color: $rai-play;
    color: #fff;
  }

  &.movie__tag--apple-itunes {
    background-color: $apple-itunes;
    color: #fff;
  }

  &.movie__tag--google-play-movies {
    background-color: $google-play-movies;
    color: #fff;
  }

  &.movie__tag--microsoft-store {
    background-color: $microsoft-store;
    color: #fff;
  }

  &.movie__tag--playstation {
    background-color: $playstation;
    color: #fff;
  }

  &.movie__tag--rakuten-tv {
    background-color: $rakuten-tv;
    color: #fff;
  }

  &.movie__tag--youtube-premium {
    background-color: $youtube-premium;
    color: #fff;
  }

  &.movie__tag--mubi {
    background-color: $mubi;
    color: #fff;
  }

  &.movie__tag--mediaset-play {
    background-color: $mediaset-play;
    color: #fff;
  }

  &.movie__tag--chili {
    background-color: $chili;
    color: #fff;
  }

  &.movie__tag--discovery-plus {
    background-color: $discovery-plus;
    color: #fff;
  }

  &.movie__tag--vvvvid {
    background-color: $vvvvid;
    color: #fff;
  }

  &.movie__tag--starz-play-amazon-channel {
    background-color: $starz-play-amazon;
    color: #fff;
  }

  .movie__intro-social & {
    @media (min-width: $l-tablet) {
      margin-right: 0;
      text-align: center;
      width: 100%;
    }
  }

  .moviecard__info & {
    margin-top: 0;
  }
}

.movie__caption {
  border: 1px solid #f3f3f3;
  border-radius: 0 0 3px 3px;
  border-top: none;
  color: #000;
  height: 100%;
  min-height: 72px;
  @include transition(border-color);

  .no-touchevents .movie__card:hover & {
    border-color: #fff;
  }

  > a {
    display: block;
    padding: 15px 20px;
  }
}

.movie__title {
  font-size: 18px;
  font-weight: 700;
  @include transition(color);

  .no-touchevents .movie__card:hover & {
    color: $primary;
  }
}

.movie__release {
  display: block;
  font-size: 14px;
  letter-spacing: 0.14px;
  margin-top: 5px;
}
//Catalogo serie tv e film per broadcaster
.search__inner {
  margin-top: 30px;

  @media (min-width: $s-tablet) {
    margin-top: 65px;
  }
}

.search__button {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @media (min-width: $l-mobile) {
    margin-top: 40px;
    padding: 0 15px;
  }

  @media (min-width: $s-tablet) {
    margin-top: 60px;
    padding: 0 25px;
  }

  &.is-hidden {
    @media (max-width: ($l-mobile - 1)) {
      display: none;
    }
  }

  .movies__filters--desktop ~ & {
    &:not(.search__button--adv) {
      @media (max-width: ($l-mobile - 1)) {
        display: none;
      }
    }
  }

  button {
    background-color: #111;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 45px;
    position: relative;
    width: 100%;

    @media (min-width: $l-mobile) {
      width: calc(100% / 3);
    }

    @media (min-width: $s-tablet) {
      font-size: 16px;
      height: 60px;
    }

    &:before {
      font-size: 13px;
      position: absolute;
      right: 15px;
      transform: translateY(-50%);
      top: 50%;
    }
  }
}

.search__button--adv {
  margin-top: 10px;

  @media (min-width: $l-mobile) {
    display: none;
  }

  button {
    background-color: #f8f8f8;
    border: solid 1px #e9e9e9;
    color: #000;
  }
}

.search__reset {
  @include latoregular;
  font-size: 11px;
  letter-spacing: 0.7px;
  margin: 30px 0 10px;
  transition: color 0.3s ease;

  .no-touchevents &:hover {
    color: $primary;
  }

  @media (max-width: ($l-mobile - 1)) {
    display: none;
  }
}

.search__results {
  margin-top: 25px;

  @media (min-width: $s-tablet) {
    margin-top: 55px;
  }
}
//Scheda del film
.movie__intro {
  background-color: #222;
  font-family: "Lato", sans-serif;
  overflow: hidden;
  position: relative;
}

.movie__intro-bg {
  width: 100%;

  @media (max-width: ($l-tablet - 1)) {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }

  @media (min-width: $l-tablet) {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 5;
  }

  &:before {
    @media (min-width: $l-tablet) {
      background-color: rgba(34, 34, 34, 0.9);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 8;
    }
  }

  img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}


.movie__intro-video {
  &.movie__intro-video--full {
    @media (min-width: $l-tablet) {
      align-items: center;
      background-color: rgba(34, 34, 34, 0.95);
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      padding: 10vw;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1000000010; //sennò il banner pubblicitario va sopra

      &:before {
        display: none;
      }
    }

    @media (min-width: $extreme + 1) {
      padding: 15vw;
    }

    > div {
      @media (min-width: $l-tablet) {
        height: 0;
        position: relative;
        top: 0;
        transform: translateY(0);
      }
    }

    iframe {
      @media (min-width: $l-tablet) {
        height: 100%;
        width: 100%;
      }
    }
  }

  &:not(.movie__intro-video--full) {
    @media (min-width: $l-tablet) {
      &:before {
        background-color: rgba(34, 34, 34, 0.6);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 8;
      }
    }
  }

  > div {
    height: 0;
    left: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 7;

    @media (min-width: $l-tablet) {
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  iframe {
    height: 100%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @media (min-width: $l-tablet) {
      height: 56.25vw;
      width: 177.77777778vh;
    }
  }
}

.movie__intro-video-close {
  background-color: transparent;
  border: 0;
  color: #fff;
  display: none;
  font-size: 25px;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  @include transition;
  z-index: 12;

  @media (min-width: $l-tablet) {
    font-size: 40px;
    right: 15px;
    top: 15px;
  }

  .movie__intro-video--full & {
    @media (min-width: $l-tablet) {
      display: block;

      .no-touchevents &:hover {
        color: $primary;
      }
    }
  }
}

.movie__intro-rating {
  align-items: center;
  background-color: #000;
  border: 2px solid $tonightfilms;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 23px;
  height: 50px;
  justify-content: center;
  letter-spacing: -0.46px;
  position: absolute;
  width: 50px;
  z-index: 15;

  @media (min-width: $s-tablet) {
    border: 3px solid $tonightfilms;
    font-size: 28px;
    height: 60px;
    letter-spacing: -0.56px;
    width: 60px;
  }

  @media (max-width: $l-tablet - 1) {
    right: $container-padding;
    top: $container-padding;
  }

  @media (min-width: $l-tablet) {
    left: calc((100vw - #{$container-padding}*2 - 175px) / 3);
    top: 70px;
  }

  @media (min-width: $container) {
    left: calc((100vw - #{$container} + #{$container-padding} * 2 + 610px) / 2);
  }
}

.movie__intro-inner {
  padding: 20px 0;
  position: relative;
  z-index: 10;

  @media (min-width: $s-tablet) {
    padding: 50px 0 30px;
  }

  @media (min-width: $l-tablet) {
    padding: 50px 0 35px;
  }

  > div {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: ($l-tablet - 1)) {
      flex-direction: column;
    }
  }
}

.movie__intro-poster {
  flex-shrink: 0;
  position: relative;
  text-align: center;
  width: calc(100% / 3);

  @media (max-width: ($l-tablet - 1)) {
    order: 3;
    width: 100%;
  }

  .movie__intro--collapsed & {
    @media (min-width: $l-tablet) {
      width: 130px;
    }
  }

  > div:first-child {
    background-color: #fff;
    height: 0;
    padding-bottom: 150%;
    position: relative;

    @media (max-width: ($l-tablet - 1)) {
      display: none;
    }

    &.loading {
      &:after {
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        z-index: 10;

        @media (min-width: $s-tablet) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
    }
  }

  img {
    display: block;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 15;
  }
}

.movie__intro-channels {
  border-top: 1px solid #444;
  margin-top: 20px;
  padding-top: 20px;
  position: relative;
  width: 100%;
  z-index: 5;

  @media (min-width: $s-tablet) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px;
    padding-top: 10px;
    width: calc(100vw - (#{$container-padding} * 2));
  }

  @media (min-width: $container) {
    width: calc(#{$container} - (#{$container-padding} * 2));
  }

  .moviecard__footer & {
    border-top: none;
    margin-top: 0;
    padding-top: 0;

    @media (min-width: $s-tablet) {
      justify-content: flex-end;
      width: 100%;
    }
  }

  .movie__main & {
    border-top: none;
    margin-top: 0;
    width: 100%;
  }
}

.movie__intro-channel {
  align-items: center;
  color: $primary;
  display: flex;
  font-size: 14px;
  letter-spacing: 0.14px;

  @media (min-width: $s-tablet) {
    margin-top: 20px;
    white-space: nowrap;
  }

  &:before {
    align-items: center;
    background-color: $primary;
    border-radius: 50%;
    color: #fff;
    display: flex;
    flex-shrink: 0;
    font-size: 8px;
    height: 23px;
    justify-content: center;
    margin-right: 8px;
    text-indent: 2px;
    width: 23px;
  }

  .no-touchevents &:hover {
    > span {
      &:after {
        transform: scaleX(1);
      }
    }
  }

  &:not(:last-child) {
    @media (max-width: ($s-tablet - 1)) {
      border-bottom: 1px solid #444;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }

    @media (min-width: $s-tablet) {
      margin-right: 50px;
    }
  }

  &.icon-buy {
    &:before {
      content: "€";
      @include latoregular;
      font-size: 15px;
      text-indent: 0;
    }
  }

  &.movie__intro-channel--netflix {
    color: $netflix;

    &:before {
      background-color: $netflix;
    }

    > span {
      &:after {
        background-color: $netflix;
      }
    }
  }

  &.movie__intro-channel--apple-tv-plus {
    color: $apple-tv-plus;

    &:before {
      background-color: $apple-tv-plus;
    }

    > span {
      &:after {
        background-color: $apple-tv-plus;
      }
    }
  }

  &.movie__intro-channel--amazon-prime-video {
    color: $amazon-prime-video;

    &:before {
      background-color: $amazon-prime-video;
    }

    > span {
      &:after {
        background-color: $amazon-prime-video;
      }
    }
  }

  &.movie__intro-channel--disney-plus {
    color: $disney-plus;

    &:before {
      background-color: $disney-plus;
    }

    > span {
      &:after {
        background-color: $disney-plus;
      }
    }
  }

  &.movie__intro-channel--chili {
    color: $chili;

    &:before {
      background-color: $chili;
    }

    > span {
      &:after {
        background-color: $chili;
      }
    }
  }

  &.movie__intro-channel--now-tv {
    color: $now-tv;

    &:before {
      background-color: $now-tv;
    }

    > span {
      &:after {
        background-color: $now-tv;
      }
    }
  }

  &.movie__intro-channel--timvision {
    color: $timvision;

    &:before {
      background-color: $timvision;
    }

    > span {
      &:after {
        background-color: $timvision;
      }
    }
  }

  &.movie__intro-channel--infinity {
    color: $infinity;

    &:before {
      background-color: $infinity;
    }

    > span {
      &:after {
        background-color: $infinity;
      }
    }
  }

  &.movie__intro-channel--sky-go {
    color: $sky-go;

    &:before {
      background-color: $sky-go;
    }

    > span {
      &:after {
        background-color: $sky-go;
      }
    }
  }

  &.movie__intro-channel--rai-play {
    color: $rai-play;

    &:before {
      background-color: $rai-play;
    }

    > span {
      &:after {
        background-color: $rai-play;
      }
    }
  }

  &.movie__intro-channel--apple-itunes {
    color: $apple-itunes;

    &:before {
      background-color: $apple-itunes;
    }

    > span {
      &:after {
        background-color: $apple-itunes;
      }
    }
  }

  &.movie__intro-channel--google-play-movies {
    color: $google-play-movies;

    &:before {
      background-color: $google-play-movies;
    }

    > span {
      &:after {
        background-color: $google-play-movies;
      }
    }
  }

  &.movie__intro-channel--microsoft-store {
    color: $microsoft-store;

    &:before {
      background-color: $microsoft-store;
    }

    > span {
      &:after {
        background-color: $microsoft-store;
      }
    }
  }

  &.movie__intro-channel--playstation {
    color: $playstation;

    &:before {
      background-color: $playstation;
    }

    > span {
      &:after {
        background-color: $playstation;
      }
    }
  }

  &.movie__intro-channel--rakuten-tv {
    color: $rakuten-tv;

    &:before {
      background-color: $rakuten-tv;
    }

    > span {
      &:after {
        background-color: $rakuten-tv;
      }
    }
  }

  &.movie__intro-channel--youtube-premium {
    color: $youtube-premium;

    &:before {
      background-color: $youtube-premium;
    }

    > span {
      &:after {
        background-color: $youtube-premium;
      }
    }
  }

  &.movie__intro-channel--mubi {
    color: $mubi;

    &:before {
      background-color: $mubi;
    }

    > span {
      &:after {
        background-color: $mubi;
      }
    }
  }

  &.movie__intro-channel--mediaset-play {
    color: $mediaset-play;

    &:before {
      background-color: $mediaset-play;
    }

    > span {
      &:after {
        background-color: $mediaset-play;
      }
    }
  }

  &.movie__intro-channel--discovery-plus {
    color: $discovery-plus;

    &:before {
      background-color: $discovery-plus;
    }

    > span {
      &:after {
        background-color: $discovery-plus;
      }
    }
  }

  &.movie__intro-channel--vvvvid {
    color: $vvvvid;

    &:before {
      background-color: $vvvvid;
    }

    > span {
      &:after {
        background-color: $vvvvid;
      }
    }
  }

  &.movie__intro-channel--starz-play-amazon-channel {
    color: $starz-play-amazon;

    &:before {
      background-color: $starz-play-amazon;
    }

    > span {
      &:after {
        background-color: $starz-play-amazon;
      }
    }
  }

  .movie__intro-poster & {
    @media (max-width: ($s-tablet - 1)) {
      border-top: 1px solid #333;
      margin-top: 15px;
      padding-top: 15px;
    }
  }

  .moviecard__footer & {
    margin-top: 10px;

    @media (max-width: ($s-tablet - 1)) {
      justify-content: center;
      text-align: center;
    }

    @media (min-width: $s-tablet) {
      margin-top: 0;
    }
  }

  .movie__main & {
    border-bottom: none;

    &:not(:last-child) {
      @media (max-width: $s-tablet - 1) {
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }
  }

  > span {
    line-height: initial;

    &:after {
      background-color: $primary;
      content: "";
      display: block;
      height: 1px;
      transform: scaleX(0);
      transform-origin: 0 0;
      @include transition(transform);
      width: 100%;
    }
  }
}

.movie__intro-info {
  color: #fff;
  flex: 1;
  width: 100%;

  @media (min-width: $l-tablet) {
    padding: 40px 50px 0;
  }

  .movie__intro--collapsed & {
    padding-top: 0;
  }

  h1, > strong {
    font-size: 25px;
    font-weight: 900;

    @media (min-width: $s-tablet) {
      font-size: 45px;
    }

    a {
      @include transition(color);

      .no-touchevents &:hover {
        color: $primary;
      }
    }
  }

  > span {
    display: block;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.1px;
    margin-top: 10px;

    @media (min-width: $s-tablet) {
      font-size: 20px;
      letter-spacing: 0.2px;
    }
  }
}

.movie__intro-vote {
  align-items: center;
  display: flex;
  margin-top: 20px;

  @media (max-width: $l-tablet - 1) {
    border-bottom: 1px solid #444;
    padding-bottom: 20px;
  }

  &.voted {
    button {
      cursor: default;
      pointer-events: none;
    }
  }

  strong {
    font-size: 16px;
    letter-spacing: .32px;
    margin-right: 15px;

    @media (min-width: $s-tablet) {
      font-size: 19px;
      letter-spacing: .38px;
    }
  }

  button {
    align-items: center;
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 22px;
    height: 44px;
    justify-content: center;
    position: relative;
    @include transition(transform);
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    width: 44px;

    &:before,
    &:after {
      background-repeat: no-repeat;
      content: '';
      display: block;
      height: 100%;
      left: -40%;
      position: absolute;
      transition: all ease-in-out 0.5s;
      width: 200%;
      z-index: -100;
    }

    &:before {
      background-image: radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, transparent 20%, $primary 20%, transparent 30%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, transparent 10%, $primary 15%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%);
      background-size: 25% 25%, 35% 35%, 30% 30%, 35% 35%, 33% 33%, 25% 25%, 30% 30%, 25% 25%, 33% 33%;
      display: none;
      top: -95%;
    }

    &:after {
      background-image: radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, transparent 10%, $primary 15%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%), radial-gradient(circle, $primary 20%, transparent 20%);
      background-size: 30% 30%, 35% 35%, 33% 33%, 35% 35%, 30% 30%, 25% 25%, 30% 30%;
      bottom: -105%;
      display: none;
    }

    .no-touchevents &:hover {
      transform: scale(1.1);
    }

    &:not(:last-child) {
      margin-right: 15px;
    }

    &.is-animating {
      &:before {
        animation: topBubbles ease-in-out 0.75s forwards;
        display: block;
      }


      &:after {
        animation: bottomBubbles ease-in-out 0.75s forwards;
        display: block;
      }
    }

    &.movie__intro-vote-yes {
      span:before {
        position: relative;
        top: -1px;
      }
    }

    &.movie__intro-vote-no {
      span:before {
        transform: rotate(180deg);
      }
    }

    span {
      &:before {
        backface-visibility: hidden;
        transform: translate3d(0,0,0) scaleX(1);
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}

.movie__intro-meta {
  margin-top: 20px;

  > li {
    &:not(:last-child) {
      margin-bottom: 7px;

      @media (min-width: $s-tablet) {
        margin-bottom: 15px;
      }
    }
  }

  span {
    font-family: "Pt Serif", serif;
    font-size: 14px;
    letter-spacing: 0.18px;
    line-height: 1.4;

    @media (min-width: $s-tablet) {
      font-size: 18px;
    }
  }

  a {
    color: $primary;

    .no-touchevents &:hover {
      text-decoration: underline;
    }
  }
}

.movie__intro-social {
  color: #fff;
  flex-shrink: 0;

  @media (min-width: $l-tablet) {
    margin-left: auto;
  }

  > ul {
    text-align: center;

    @media (max-width: ($l-tablet - 1)) {
      border-top: 1px solid #444;
      display: flex;
      margin-top: 30px;
      padding-top: 30px;
    }

    @media (max-width: ($s-tablet - 1)) {
      margin-top: 20px;
      padding-top: 20px;
    }

    @media (min-width: $l-tablet) {
      margin-top: 20px;
    }

    > li {
      &:not(:last-child) {
        @media (max-width: ($l-tablet - 1)) {
          margin-right: 35px;
        }

        @media (min-width: $l-tablet) {
          margin-bottom: 15px;
        }
      }
    }

    a {
      font-size: 15px;
      transition: color 0.3s ease;

      .no-touchevents &:hover {
        color: $primary;
      }
    }
  }
}

.movie__main {
  position: relative;

  > div {
    display: flex;
    flex-wrap: wrap;
  }
}

.movie__sections {
  width: 100%;

  @media (min-width: $l-tablet) {
    width: calc(100% - 350px);
  }
}

.movie__section {
  padding: 30px 0 25px;

  @media (min-width: $s-tablet) {
    padding: 55px 0 35px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #f3f3f3;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.movie__section-back {
  margin-top: 20px;
  width: 100%;

  @media (min-width: $s-tablet) {
    margin-top: 30px;
  }

  > a {
    display: inline-flex;
    position: static;
  }
}

.movie__section-title {
  color: #000;
  @include latoblack;
  font-size: 20px;
  position: relative;

  @media (min-width: $s-tablet) {
    font-size: 28px;
  }

  @media (max-width: $medium - 1) {
    align-items: center;
    display: flex;
  }

  .no-touchevents &:hover {
    .icon-anchor {
      @media (min-width: $medium) {
        opacity: 1;
      }
    }
  }

  .icon-anchor {
    flex-shrink: 0;
    font-size: 12px;
    margin-right: 5px;
    @include transition;

    @media (min-width: $s-tablet) {
      font-size: 16px;
      margin-right: 7px;
    }

    @media (min-width: $medium) {
      margin-right: 0;
      opacity: 0;
      padding-right: 7px;
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
    }

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

.movie__section-text {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.21px;
  margin-top: 15px;

  @media ($s-tablet) {
    font-size: 21px;
    margin-top: 30px;
  }

  &:not(.is-expanded) {
    p {
      &:not(:first-child) {
        display: none;
      }
    }
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    a:not([class*="icon-"]) {
      background-image: linear-gradient(#fff 50%, rgba(232, 0, 0, 0.6) 0);
      background-repeat: repeat-x;
      background-position: 0 22px;
      background-size: 1px 1px;
      color: $primary;
      text-decoration: none;
      position: relative;
      text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
      z-index: 2;
    }
  }
}

.movie__section-cta {
  color: $primary;
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0.16px;
  margin-top: 25px;

  @media (min-width: $s-tablet) {
    margin-top: 35px;
  }

  &:after {
    background-color: $primary;
    content: "";
    display: block;
    height: 1px;
    margin-top: 2px;
    transform: scaleX(0);
    transform-origin: 0 0;
    @include transition(transform);
    width: 100%;
  }

  .no-touchevents &:hover {
    &:after {
      transform: scaleX(1);
    }
  }

  .movie__section-text &,
  .movie__season-text & {
    margin-top: 0;
  }

  .movie__season-text & {
    font-size: 14px;
  }
}

.movie__seasons {
  margin: 0 0 -15px;

  @media (min-width: $s-tablet) {
    margin: 15px 0 -15px;
  }
}

.movie__season {
  padding: 15px 0;
}

.movie__season-box {
  border: 1px solid #f3f3f3;
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  padding: 15px;
}

.movie__season-poster {
  background-color: $grey;
  flex-shrink: 0;
  height: 83px;
  position: relative;
  width: 55px;

  @media (min-width: $s-tablet) {
    height: 195px;
    width: 130px;
  }

  &.loading {
    &:after {
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      z-index: 10;

      @media (min-width: $s-tablet) {
        margin-left: -20px;
        margin-top: -20px;
      }
    }
  }

  img {
    display: block;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.movie__season-caption {
  font-size: 16px;
  letter-spacing: 0.16px;
  padding: 10px 0 10px 15px;
  @include transition(border-color);
  width: 100%;

  @media (min-width: $s-tablet) {
    padding: 10px 0 10px 20px;
  }

  .no-touchevents .movie__season > a:hover & {
    border-color: #fff;
  }

  h3 {
    color: #000;
    @include latobold;
    font-size: 18px;
    @include transition(color);

    @media (min-width: $s-tablet) {
      font-size: 20px;
    }

    .no-touchevents .movie__season > a:hover & {
      color: $primary;
    }
  }

  span {
    color: #000;
    display: block;
    @include latoregular;
    margin-top: 5px;

    @media (max-width: ($s-tablet - 1)) {
      font-size: 14px;
    }
  }
}

.movie__season-text {
  line-height: 1.56;
  margin: 57px 0 0 -70px;
  position: relative;

  @media (min-width: $s-tablet) {
    margin: 20px 0 0;
  }

  &:after {
    @media (max-width: ($s-tablet - 1)) {
      background-color: #f3f3f3;
      top: -10px;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  &:not(.is-expanded) {
    p {
      &:not(:first-child) {
        display: none;
      }
    }
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $primary;

    .no-touchevents &:hover {
      text-decoration: underline;
    }
  }
}

.movie__season-ep {
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  padding: 20px 15px;
  position: relative;
}

.movie__season-ep-image {
  background-color: $grey;
  flex-shrink: 0;
  height: 74px;
  margin-right: 20px;
  position: relative;
  width: 130px;

  @media (max-width: ($s-tablet - 1)) {
    display: none;
  }

  &.loading {
    &:after {
      height: 15px;
      left: 50%;
      margin-left: -7.5px;
      margin-top: -7.5px;
      position: absolute;
      top: 50%;
      width: 15px;
    }
  }
}

.movie__season-ep-caption {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: ($s-tablet - 1)) {
    padding-right: 25px;
  }

  > b {
    display: flex;
    @include latobold;
    font-size: 14px;
    letter-spacing: 0.14px;

    @media (min-width: $s-tablet) {
      font-size: 16px;
      letter-spacing: 0.16px;
      max-width: calc(100% - 130px);
    }

    span {
      color: $primary;
      display: block;
      flex-shrink: 0;
      width: 65px;

      @media (min-width: $s-tablet) {
        width: 75px;
      }
    }
  }

  > button {
    height: 30px;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;

    @media (min-width: $s-tablet) {
      display: none;
    }

    &:before {
      color: #444;
      font-size: 10px;
    }
  }

  > span {
    color: #999;
    @include latoregular;
    font-size: 12px;
    letter-spacing: 0.12px;

    @media (max-width: ($s-tablet - 1)) {
      margin-left: 65px;
      margin-top: 5px;
      width: 100%;
    }

    @media (min-width: $s-tablet) {
      font-size: 13px;
      letter-spacing: 0.13px;
      margin-left: auto;
    }
  }

  > div {
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: 0.13px;
    margin: 15px 0 0;
    min-width: 100%;

    @media (max-width: ($s-tablet - 1)) {
      display: none;
    }

    &.is-expanded {
      display: block;
    }

    p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-top: 0;
      }
    }

    a {
      color: $primary;

      .no-touchevents &:hover {
        text-decoration: underline;
      }
    }
  }
}

.movie__stars {
  margin-top: 15px;

  @media (min-width: $s-tablet) {
    margin-top: 30px;
  }
}

.movie__stars-list {
  margin: -10px -7.5px;

  @media (min-width: $s-tablet) {
    margin: -10px -12.5px;
  }

  &.swiper-container {
    @media (max-width: ($s-tablet - 1)) {
      margin: -10px 32.5px -10px -7.5px; //40 - margin
      overflow: visible;
    }
  }

  &:not(.swiper-container) {
    display: flex;
    flex-wrap: wrap;
  }
}

.movie__videos {
  margin-top: 15px;

  @media (min-width: $s-tablet) {
    margin-top: 30px;
  }
}

.movie__videos-list {
  margin: -7.5px;

  @media (min-width: $s-tablet) {
    margin: -12.5px;
  }

  &.swiper-container {
    @media (max-width: ($s-tablet - 1)) {
      margin: -7.5px 32.5px -7.5px -7.5px; //40 - margin
      overflow: visible;
    }
  }

  &:not(.swiper-container) {
    display: flex;
    flex-wrap: wrap;
  }
}

.movie__video {
  flex: 0 0 auto;
  padding: 7.5px;
  width: 100%;

  @media (min-width: $l-mobile) {
    width: calc(100% / 2);
  }

  @media (min-width: $s-tablet) {
    padding: 12.5px;
  }

  > div {
    background-color: #000;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    &.loading {
      background-color: $grey;

      &:after {
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        z-index: 10;

        @media (min-width: $s-tablet) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }

      a {
        &:before {
          opacity: 0;
        }
      }
    }
  }

  a {
    display: block;

    &:before {
      align-items: center;
      background-color: rgba(232, 0, 0, 0.85);
      border-radius: 50%;
      color: #fff;
      display: flex;
      font-size: 16px;
      font-weight: 700;
      height: 50px;
      justify-content: center;
      left: 50%;
      padding-left: 6px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      @include transition;
      width: 50px;
      z-index: 10;

      @media (min-width: $s-tablet) {
        font-size: 21px;
        height: 70px;
        width: 70px;
      }
    }

    .no-touchevents &:hover {
      &:before {
        background-color: $primary;
      }

      img {
        transform: scale(1.1);
      }
    }
  }

  img {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 1s ease, opacity 0.3s ease;
    width: 100%;
  }

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  }
}

.movie__images {
  margin-top: 15px;

  @media (min-width: $s-tablet) {
    margin-top: 30px;
  }
}

.movie__images-list {
  margin: -7.5px;

  @media (min-width: $s-tablet) {
    margin: -12.5px;
  }

  &.swiper-container {
    @media (max-width: ($s-tablet - 1)) {
      margin: -7.5px 32.5px -7.5px -7.5px; //40 - margin
      overflow: visible;
    }
  }

  &:not(.swiper-container) {
    display: flex;
    flex-wrap: wrap;
  }
}

.movie__image {
  flex: 0 0 auto;
  padding: 7.5px;
  width: calc(100% / 2);

  @media (min-width: $s-tablet) {
    padding: 12.5px;
    width: calc(100% / 4);
  }

  > div {
    background-color: $grey;
    height: 0;
    padding-bottom: 150%;
    position: relative;

    &.loading {
      &:after {
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        z-index: 10;

        @media (min-width: $s-tablet) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
    }

    > img {
      display: block;
      height: auto;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.movie__news {
  margin-top: -15px;

  @media (min-width: $s-tablet) {
    margin-top: 30px;
  }
}

.movie__relateds {
  margin-top: 15px;

  @media (min-width: $s-tablet) {
    margin-top: 30px;
  }

  .swiper-container {
    overflow: visible;

    @media (max-width: ($s-tablet - 1)) {
      margin: 0 32.5px 0 -7.5px; //40 - margin
    }

    @media (min-width: $s-tablet) {
      margin: -12.5px;
    }
  }
}

.movie__poster {
  height: 100%;

  .movie__relateds &,
  .movies__strip & {
    display: block;
  }

  > a {
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    transition: box-shadow 0.3s ease-out, transform 0.3s ease-out, opacity 0.2s ease-out;
    transition-delay: 0.1s;

    .no-touchevents &:hover {
      box-shadow: rgba(45, 45, 45, 0.05) 0px 1px 1px, rgba(49, 49, 49, 0.05) 0px 2px 2px, rgba(42, 42, 42, 0.05) 0px 4px 4px, rgba(32, 32, 32, 0.05) 0px 8px 8px, rgba(49, 49, 49, 0.05) 0px 16px 16px, rgba(35, 35, 35, 0.05) 0px 32px 32px;
      transform: translate(0, -4px);
    }

    .movies__strip & {
      .no-touchevents &:hover {
        box-shadow: none;
      }
    }
  }
}

.movie__poster-image {
  background-color: $grey;
  flex-shrink: 0;
  height: 0;
  padding-bottom: 150%;
  position: relative;

  &.loading {
    &:after {
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      z-index: 10;

      @media (min-width: $s-tablet) {
        margin-left: -20px;
        margin-top: -20px;
      }
    }
  }

  > img {
    display: block;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.movie__poster-caption {
  border: 1px solid #f3f3f3;
  font-family: "Lato", sans-serif;
  height: 100%;
  padding: 10px;
  @include transition(border-color);

  .no-touchevents .movie__related > a:hover & {
    border-color: #fff;
  }

  .movies__strip & {
    border: none;
  }

  h3 {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    @include transition(color);

    .no-touchevents .movie__related > a:hover & {
      color: $primary;
    }

    .movies__strip & {
      color: #fff;
    }
  }

  span {
    display: block;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.14px;
    margin-top: 5px;

    .movies__strip & {
      color: #999;
    }
  }
}

.movie__aside {
  flex-shrink: 0;
  padding-top: 30px;
  width: 100%;

  @media (max-width: ($l-tablet - 1)) {
    display: none;
    order: -1;
  }

  @media (min-width: $l-tablet) {
    margin-left: 50px;
    width: 300px;
  }

  .movie__main--detail & {
    @media (min-width: $l-tablet) {
      margin-top: -15px;
      padding-top: 0;
    }
  }
}

//Box cerca nel catalogo
.movies__search {
  margin-top: 40px;

  @media (min-width: $s-tablet) {
    margin-top: 60px;
  }

  > div {
    display: flex;

    @media (max-width: ($l-tablet - 1)) {
      flex-direction: column;
    }

    @media (min-width: $l-tablet) {
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  h2 {
    margin-bottom: 15px;
    width: 100%;

    @media (min-width: $s-tablet) {
      margin-bottom: 25px;
    }
  }
}

.movies__search-box {
  background-color: #f8f8f8;
  border: solid 2px #efeded;
  border-radius: 5px;
  flex: 1;
}

.adv__search {
  flex-shrink: 0;
  order: 5;
  width: 300px;

  @media (max-width: ($l-tablet - 1)) {
    margin: 100px auto 0;
  }

  @media (max-width: ($s-tablet - 1)) {
    margin: 50px auto 0;
  }

  @media (min-width: $l-tablet) {
    margin-left: 30px;
    margin-top: 2px;
  }

  @media (min-width: $medium) {
    margin-left: 50px;
    margin-top: -101px;
  }
}

.movies__search-form {
  display: flex;
  padding: 30px 20px;

  @media (max-width: ($s-tablet - 1)) {
    flex-direction: column;
  }

  @media (min-width: $s-tablet) {
    align-items: flex-end;
  }

  @media (min-width: $l-tablet) {
    padding: 37px 30px;
  }

  > div {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin: -10px;

    @media (min-width: $l-tablet) {
      margin: -15px;
    }

    > div {
      padding: 10px;
      width: 100%;

      @media (min-width: $s-tablet) {
        width: calc(100% / 4);
      }

      @media (min-width: $l-tablet) {
        padding: 15px;
      }

      &.button {
        flex-shrink: 0;
        margin: 0 auto;
      }

      .adv__search + .movies__search-box & {
        @media (min-width: $l-tablet) {
          width: calc(100% / 2);
        }

        @media (min-width: $medium) {
          width: calc(100% / 4);
        }
      }
    }
  }
}

/*.movies__search-submit {
    @media (max-width: ($s-tablet - 1)) {
        margin-top: 20px;
    }

    > button {
        background-color: $primary;
        border-radius: 5px;
        color: #fff;
        @include latoblack;
        font-size: 12px;
        height: 35px;
        letter-spacing: 0.6px;
        @include transition;
        width: 100%;

        @media (max-width: ($s-tablet - 1)) {
            align-self: center;
        }

        @media (min-width: $s-tablet) {
            height: 45px;
        }

        .no-touchevents &:hover {
            background-color: #111;
            color: #fff;
        }
    }
}*/
//Sezione movies con slider
.movies__panel {
  margin-top: 40px;

  @media (min-width: $s-tablet) {
    margin-top: 60px;
  }
}

.movies__panel-header {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
}

.movies__panel-slider {
  @media (min-width: $s-tablet) {
    margin: -50px; //padding swiper-container
  }

  .swiper-container {
    @media (max-width: ($s-tablet - 1)) {
      margin: 0 32.5px 0 -7.5px; //40 - margin
      overflow: visible;
    }

    @media (min-width: $s-tablet) {
      margin: 0 -12.5px;
      padding: 50px; //padding swiper-container
    }

    &:before,
    &:after {
      @media (min-width: $s-tablet) {
        background-color: #fff;
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        width: 25px;
        z-index: 5;
      }
    }

    &:before {
      @media (min-width: $s-tablet) {
        left: 0;
      }
    }

    &:after {
      @media (min-width: $s-tablet) {
        right: 0;
      }
    }
  }
}

.movies__panel-sliderbtn {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @include transition;
  width: 55px;
  z-index: 10;

  @media (max-width: ($s-tablet - 1)) {
    display: none;
  }

  @media (min-width: $s-tablet) {
    height: calc(100% - (50px * 2)); //padding swiper-container
  }

  &:after {
    @media (min-width: $s-tablet) {
      background-color: #fff;
      content: "";
      height: 102%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      z-index: 5;
    }
  }

  &.swiper-button-disabled {
    display: none;
  }

  .no-touchevents &:hover {
    background-color: #000;
  }
}

.movies__panel-sliderbtn--prev {
  left: 0;

  @media (min-width: $s-tablet) {
    left: 62.5px;
  }

  &:after {
    @media (min-width: $s-tablet) {
      right: 100%;
    }
  }
}

.movies__panel-sliderbtn--next {
  right: 0;

  @media (min-width: $s-tablet) {
    right: 62.5px;
  }

  &:after {
    @media (min-width: $s-tablet) {
      left: 100%;
    }
  }
}
//Strip delle locandine dei film in home
.movies__strip {
  background-color: #000;
  padding: 30px 0 40px;

  @media (min-width: $s-tablet) {
    padding: 40px 0 70px;
  }

  @media (min-width: $l-tablet) {
    padding: 60px 0 100px;
  }

  > div {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  h2 {
    color: #fff;
    @include latoblack;
    font-size: 30px;

    @media (min-width: $s-tablet) {
      font-size: 45px;
    }
  }
}

.movies__strip-buttons {
  margin: 13px -7px -7px;

  @media (max-width: ($l-tablet - 1)) {
    align-items: center;
    display: flex;
    order: 3;
    text-align: center;
    width: calc(100% + 20px); //padding * 2
  }

  @media (max-width: ($s-tablet - 1)) {
    flex-wrap: wrap;
    width: calc(100% + 14px); //padding * 2
  }

  @media (min-width: $s-tablet) {
    margin: 30px -10px -10px;
  }

  @media (min-width: $l-tablet) {
    margin: -10px;
  }

  > div {
    flex: 1 1 auto;
    padding: 7px;

    @media (max-width: ($l-tablet - 1)) {
      width: calc(100% / 4);
    }

    @media (max-width: ($l-mobile - 1)) {
      width: calc(100% / 2);
    }

    @media (min-width: $s-tablet) {
      padding: 10px;
    }
  }
}

.movies__strip-slider {
  margin-top: 30px;
  width: 100%;

  @media (min-width: $s-tablet) {
    margin: 45px -5px -5px; //50px - 5px margin
    width: calc(100% + 10px);
  }

  @media (min-width: $medium) {
    margin: 75px -5px -5px; //80px - 5px margin
  }

  .swiper-container {
    @media (max-width: ($s-tablet - 1)) {
      margin: 0 32.5px 0 -7.5px; //40 - margin
      overflow: visible;
    }

    @media (min-width: $s-tablet) {
      margin: 0 -12.5px;
      padding: 5px; //padding swiper-container
    }
  }
}
//Strip dei broadcasters
.networks__slider {
  background-color: #333;

  > div {
    display: flex;
    max-width: calc(#{$container} - (20px * 2));
    overflow: hidden;
    padding: 0;
  }

  .swiper-container {
    margin: 0;
    padding: 10px 0;

    @media (max-width: ($container - 1)) {
      padding: 10px 20px;
    }

    @media (max-width: ($s-tablet - 1)) {
      padding: 10px 15px;
    }
  }
}

.networks__item {
  width: auto;

  &:not(.networks__item--current):not(:last-child) {
    margin-right: 10px;
  }

  &.networks__item--current {
    background-color: rgba(51, 51, 51, 0.95);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    flex-shrink: 0;
    margin: 10px 0;
    padding-right: 20px;
    position: relative;
    z-index: 10;

    @media (max-width: ($container - 1)) {
      padding: 0 20px;
    }

    @media (max-width: ($s-tablet - 1)) {
      padding: 0 15px;
    }

    + .swiper-container {
      overflow: visible;
      padding-left: 15px;
      padding-right: 15px;
      width: calc(100% - 85px);

      @media (min-width: $s-tablet) {
        padding-left: 20px;
        padding-right: 20px;
        width: calc(100% - 75px);
      }
    }

    > a {
      position: relative;

      &:after {
        bottom: -10px;
        content: "";
        display: block;
        height: 3px;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%;
      }
    }
  }

  &.networks__item--netflix {
    > a {
      &:after {
        background-color: $netflix;
      }
    }
  }

  &.networks__item--apple-tv-plus {
    > a {
      &:after {
        background-color: $apple-tv-plus;
      }
    }
  }

  &.networks__item--amazon-prime-video {
    > a {
      &:after {
        background-color: $amazon-prime-video;
      }
    }
  }

  &.networks__item--disney-plus {
    > a {
      &:after {
        background-color: $disney-plus;
      }
    }
  }

  &.networks__item--now-tv {
    > a {
      &:after {
        background-color: $now-tv;
      }
    }
  }

  &.networks__item--timvision {
    > a {
      &:after {
        background-color: $timvision;
      }
    }
  }

  &.networks__item--infinity {
    > a {
      &:after {
        background-color: $infinity;
      }
    }
  }

  &.networks__item--sky-go {
    > a {
      &:after {
        background-color: $sky-go;
      }
    }
  }

  &.networks__item--rai-play {
    > a {
      &:after {
        background-color: $rai-play;
      }
    }
  }

  &.networks__item--apple-itunes {
    > a {
      &:after {
        background-color: $apple-itunes;
      }
    }
  }

  &.networks__item--google-play-movies {
    > a {
      &:after {
        background-color: $google-play-movies;
      }
    }
  }

  &.networks__item--microsoft-store {
    > a {
      &:after {
        background-color: $microsoft-store;
      }
    }
  }

  &.networks__item--playstation {
    > a {
      &:after {
        background-color: $playstation;
      }
    }
  }

  &.networks__item--rakuten-tv {
    > a {
      &:after {
        background-color: $rakuten-tv;
      }
    }
  }

  &.networks__item--youtube-premium {
    > a {
      &:after {
        background-color: $youtube-premium;
      }
    }
  }

  &.networks__item--mubi {
    > a {
      &:after {
        background-color: $mubi;
      }
    }
  }

  &.networks__item--mediaset-play {
    > a {
      &:after {
        background-color: $mediaset-play;
      }
    }
  }

  &.networks__item--discovery-plus {
    > a {
      &:after {
        background-color: $discovery-plus;
      }
    }
  }

  &.networks__item--vvvvid {
    > a {
      &:after {
        background-color: $vvvvid;
      }
    }
  }

  &.networks__item--starz-play-amazon-channel {
    > a {
      &:after {
        background-color: $starz-play-amazon;
      }
    }
  }

  > a {
    display: block;
    width: 55px;
  }
}

.network__image {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  &:after {
    left: 50%;
    margin: -15px;
    position: absolute;
    top: 50%;
  }
}

.networks__sliderbtn {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 21px;
  height: 75px;
  justify-content: center;
  opacity: 0;
  position: absolute;
  top: 0;
  @include transition;
  width: 40px;
  z-index: 10;

  &.swiper-button-disabled {
    display: none;
  }

  .no-touchevents &:hover {
    background-color: #000;
  }

  .no-touchevents .swiper-container:hover & {
    opacity: 1;
  }
}

.networks__sliderbtn--prev {
  left: 0;

  &:after {
    @media (min-width: $s-tablet) {
      right: 100%;
    }
  }
}

.networks__sliderbtn--next {
  right: 0;

  &:after {
    @media (min-width: $s-tablet) {
      left: 100%;
    }
  }

  .touchevents & {
    opacity: 1;
  }
}

/* Popup */
.popup {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  @include latoregular;
  height: 100%;
  @include hidden;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: opacity 0.5s, visibility 0.5s;
  width: 100%;
  z-index: 1000000001; //sennò il banner pubblicitario va sopra

  &.is-open {
    @include visible;
  }
}

.popup__inner {
  background-color: #fff;
  color: #333;
  max-height: calc(100% - 15px * 2);
  max-width: calc(100% - 15px * 2);
  opacity: 0;
  overflow-y: auto;
  padding: 35px;
  position: relative;
  text-align: center;
  transform: translateY(50px);
  transition: opacity 0.5s, transform 0.5s;
  width: 600px;

  @media (min-width: $s-tablet) {
    max-height: calc(100% - 30px * 2);
    max-width: calc(100% - 30px * 2);
    padding: 55px;
    width: 650px;
  }

  .popup.is-open & {
    opacity: 1;
    transform: translateY(0);
  }

  .popup.popup--advform & {
    @media (min-width: $s-tablet) {
      padding: 55px 45px;
    }
  }
}

.popup__close {
  font-size: 14px;
  height: 30px;
  position: absolute;
  right: 5px;
  top: 5px;
  transition: color 0.5s, opacity 0.5s 0.5s, visibility 0.5s 0.5s;
  width: 30px;
  z-index: 5;

  @media (min-width: $s-tablet) {
    font-size: 16px;
    right: 20px;
    top: 20px;
  }

  &:before {
    transition: color 0.5s;
  }

  .no-touchevents &:hover {
    &:before {
      color: $primary;
    }
  }
}

.popup__title {
  display: block;
  @include latoregular;
  font-size: 25px;
  line-height: 1.1;

  @media (min-width: $s-tablet) {
    font-size: 30px;
  }

  &.popup__title--error {
    color: #ff0000;
  }
}

.popup__text {
  line-height: 1.4;
  margin-top: 15px;

  @media (min-width: $s-tablet) {
    margin-top: 25px;
  }

  strong {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;

    @media (min-width: $s-tablet) {
      font-size: 18px;
    }

    @media (min-width: $wide) {
      font-size: 20px;
    }
  }

  small {
    color: #999;
    display: block;
    font-size: 11px;
    line-height: 1.2;
    margin-top: 5px;

    @media (min-width: $s-tablet) {
      font-size: 13px;
    }

    a {
      text-decoration: underline;
      @include transition(color);

      .no-touchevents &:hover {
        color: $primary;
      }
    }
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
/* Form */
.form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  text-align: left;
  transition: opacity 0.5s;

  @media (min-width: $l-mobile) {
    margin: 25px -10px 0;
  }

  @media (min-width: $wide) {
    margin: 35px -10px 0;
  }

  a {
    transition: color 0.5s;

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

.form__field {
  position: relative;
  width: 100%;

  @media (min-width: $l-mobile) {
    padding: 0 10px;
    width: calc(100% / 2);
  }

  &:not(:last-child) {
    margin-bottom: 15px;

    @media (min-width: $s-tablet) {
      margin-bottom: 25px;
    }
  }

  input,
  select,
  textarea {
    background-color: #fff;
    border: solid 1px #cdcdcd;
    color: #666;
    @include latobold;
    font-size: 12px;
    height: 45px;
    letter-spacing: 0.05em;
    padding: 0 15px;
    width: 100%;

    @media (min-width: $s-tablet) {
      font-size: 13px;
      height: 50px;
      padding: 0 20px;
    }

    &::-webkit-input-placeholder {
      color: #9d988c;
    }

    &::-moz-placeholder {
      color: #9d988c;
    }

    &:-ms-input-placeholder {
      color: #9d988c;
    }

    &:-moz-placeholder {
      color: #9d988c;
    }

    &:focus {
      border-color: $primary;
      outline: none;

      &::-ms-value {
        background-color: #fff;
        color: #000;
      }
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    &::-ms-expand {
      display: none;
    }

    &.-error {
      border-color: #ff0000;
    }
  }

  select {
    -webkit-appearance: none;

    .hero__select & {
      border: none;
    }
  }

  textarea {
    min-height: 120px;
    padding: 13px 15px;

    @media (min-width: $s-tablet) {
      min-height: 150px;
      padding: 17px 20px;
    }
  }
}

.form__terms {
  border-top: 1px solid #dedede;
  color: #666;
  padding: 20px 0;
  width: 100%;

  @media (max-width: $l-mobile - 1) {
    text-align: left;
  }

  &:last-child {
    border-bottom: 1px solid #dedede;
  }

  > div {
    display: flex;
  }

  p {
    font-size: 13px;
    letter-spacing: normal;
    line-height: 1.4;
    margin: 0 0 15px;

    @media (min-width: $s-tablet) {
      margin: 0 0 20px;
    }

    strong {
      color: #000;
      display: block;
      @include latobold;
      font-size: 14px;
      letter-spacing: 0.05em;
      margin-bottom: 7px;
    }

    a {
      text-decoration: underline;
    }
  }

  label {
    align-items: center;
    cursor: pointer;
    display: flex;
    @include latobold;
    font-size: 11px;
    position: relative;

    @media (min-width: $s-tablet) {
      font-size: 12px;
    }

    &:not(:last-child) {
      margin-right: 15px;

      @media (min-width: $s-tablet) {
        margin-right: 35px;
      }
    }

    &.-disabled {
      cursor: default;
    }

    > input {
      height: 15px;
      margin-right: 5px;
      opacity: 0;
      position: relative;
      z-index: 5;
      width: 15px;

      @media (min-width: $s-tablet) {
        height: 20px;
        width: 20px;
      }

      &:not([disabled]) {
        cursor: pointer;
      }

      &:checked {
        + span {
          &:before {
            border-color: $primary;
            color: $primary;
          }
        }
      }

      &:focus {
        & + span {
          &:before {
            border-color: $primary;
          }
        }
      }

      &.-error {
        + span {
          color: #ff0000;
        }
      }
    }

    span {
      &:before {
        align-items: center;
        border: 1px solid #9d988c;
        border-radius: 50%;
        color: transparent;
        display: flex;
        font-size: 6px;
        height: 15px;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 15px;

        @media (min-width: $s-tablet) {
          font-size: 8px;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

.form__submit {
  margin-top: 25px;
  text-align: center;
  width: 100%;

  @media (min-width: $s-tablet) {
    margin-top: 50px;
  }
}

//Lista alfabetica
.alpha {
  @media (max-width: ($s-tablet - 1)) {
    align-items: flex-end;
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: transform 0.3s ease, opacity 0.3s 0.15s ease, visibility 0.3s 0.15s ease;
    visibility: hidden;
    width: 100%;
    z-index: 1000000010; //sennò il banner pubblicitario va sopra
  }

  @media (min-width: $s-tablet) {
    display: flex;
    margin: 45px 0 75px;
  }

  &.alpha--open {
    @media (max-width: ($s-tablet - 1)) {
      backface-visibility: visible;
      opacity: 1;
      transition: transform 0.3s ease, opacity 0.3s 0s ease, visibility 0.3s 0s ease;
      visibility: visible;

      .alpha__list {
        transform: translateY(0);
        transition: transform 0.5s ease, opacity 0s 0s ease;
      }
    }
  }
}

.alpha__button {
  height: 45px;
  margin: 40px 0;

  @media (min-width: $s-tablet) {
    display: none;
  }
}

.alpha__button-button {
  align-items: center;
  background-color: #f6f6f6;
  font-size: 14px;
  display: flex;
  height: 45px;
  justify-content: center;
  letter-spacing: 0.7px;
  padding-right: 35px;
  position: relative;
  text-align: center;
  width: 100%;

  @media (max-width: ($s-tablet - 1)) {
    margin-bottom: -45px;

    @include transition(margin-bottom);
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  &.selected {
    color: $primary;
  }

  &.alpha__button-button--sticky {
    @media (max-width: ($s-tablet - 1)) {
      bottom: 0;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
      left: 0;
      margin-bottom: 0;
      position: fixed;
      right: 0;
      width: 100%;
      z-index: 20;
    }
  }

  &:before {
    flex-shrink: 0;
    font-size: 13px;
    order: 3;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.alpha__list {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ($s-tablet - 1)) {
    background-color: #fff;
    min-height: 185px;
    overflow-y: auto;
    padding: 30px 20px;
    transform: translateY(100%);
    transition: transform 0.5s ease, opacity 0s 0.5s ease;
    width: 100%;
  }

  @media (min-width: $s-tablet) {
    margin: -5px -12.5px;
  }
}

.alpha__item {
  @media (max-width: ($s-tablet - 1)) {
    height: 0;
    padding-bottom: calc(100% / 9);
    position: relative;
    width: calc(100% / 9);
  }

  @media (max-width: ($l-mobile - 1)) {
    padding-bottom: calc(100% / 8);
    width: calc(100% / 8);
  }

  @media (max-width: ($s-mobile - 1)) {
    padding-bottom: calc(100% / 5);
    width: calc(100% / 5);
  }

  @media (max-width: ($xs-mobile - 1)) {
    padding-bottom: calc(100% / 4);
    width: calc(100% / 4);
  }

  @media (min-width: $s-tablet) {
    padding: 5px 12.5px;
  }

  &.alpha__item--selected {
    a {
      color: $primary;
    }
  }

  a {
    display: block;
    @include latobold;
    font-size: 30px;
    text-transform: uppercase;
    @include transition(color);

    @media (max-width: ($s-tablet - 1)) {
      align-items: center;
      border: solid 1px #ededed;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
    }

    @media (min-width: $s-tablet) {
      font-size: 20px;
    }

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

.alpha__group {
  align-items: center;
  display: flex;
  margin-bottom: 20px;

  @media (min-width: $s-tablet) {
    margin-bottom: 25px;
  }

  &:after {
    background-color: $border;
    content: "";
    display: block;
    height: 2px;
    margin-left: 5px;
    width: 100%;

    @media (min-width: $s-tablet) {
      margin-left: 15px;
    }
  }

  div {
    align-items: center;
    background-color: $primary;
    border-radius: 3px;
    color: #fff;
    display: flex;
    flex-shrink: 0;
    @include latobold;
    font-size: 25px;
    height: 35px;
    justify-content: center;
    text-transform: uppercase;
    width: 35px;

    @media (min-width: $s-tablet) {
      font-size: 35px;
      height: 50px;
      width: 50px;
    }
  }
}

//Strip della guida tv in home
.guide__strip {
  background-color: #000;
  padding: 30px 0 40px;

  @media (min-width: $s-tablet) {
    padding: 50px 0;
  }

  > div {
    @media (max-width: ($s-tablet - 1)) {
      display: flex;
      flex-direction: column;
    }
  }
}

.guide__strip-header {
  align-items: center;
  display: flex;

  @media (min-width: $s-tablet) {
    margin-top: 25px;
  }

  @media (max-width: ($medium - 1)) {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media (max-width: ($s-tablet - 1)) {
    order: -1;
  }

  h2 {
    color: #fff;
    flex-shrink: 0;
    @include latoblack;
    font-size: 30px;

    @media (min-width: $s-tablet) {
      font-size: 45px;
    }

    @media (max-width: ($medium - 1)) {
      margin-bottom: 20px;
      width: 100%;
    }

    @media (max-width: ($s-tablet - 1)) {
      margin-bottom: 30px;
    }
  }
}

.guide__strip-filters,
.guide__strip-buttons {
  align-items: center;
  display: flex;

  @media (max-width: ($medium - 1)) {
    justify-content: space-between;
    width: 100%;
  }

  > div {
    @media (max-width: ($medium - 1)) {
      flex: 1;
    }
  }
}

.guide__strip-filters {
  @media (max-width: ($medium - 1)) and (min-width: $s-tablet) {
    flex: 1;
  }

  @media (min-width: $medium) {
    margin-left: auto;
  }

  > div {
    margin-right: 20px;
    position: relative;

    @media (max-width: ($l-tablet - 1)) and (min-width: $s-tablet) {
      flex: 1;
    }

    @media (min-width: $medium) {
      margin-right: 25px;
    }

    &:last-child {
      @media (max-width: ($medium - 1)) {
        margin-right: 0;
      }
    }

    .body--withskin & {
      @media (min-width: $medium) {
        margin-right: 15px;
      }
    }
  }
}

.guide__strip-options {
  backface-visibility: hidden;
  background-color: #222;
  box-shadow: 1px 1.7px 50px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: row;
  opacity: 0;
  position: absolute;
  right: -15px;
  top: 35px;
  transform: translateY(20px);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.2s linear;
  visibility: hidden;
  width: 205px;
  z-index: 20;

  &:before {
    border-bottom: 6px solid #222;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    right: 24px;
    top: -6px;
    width: 0;
    z-index: 5;
  }

  &.is-open {
    backface-visibility: visible;
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  > div {
    display: flex;
    flex-direction: column;
    max-height: 270px;
    padding: 10px;
    width: 100%;

    @media (min-width: $l-mobile) {
      max-height: 370px;
    }

    ul {
      height: 100%;
      margin-right: -10px;
      overflow-y: auto;
      padding-right: 10px;
    }
  }
}

.guide__strip-option {
  &:not(:last-child) {
    margin-bottom: 7px;

    @media (min-width: $s-tablet) {
      margin-bottom: 10px;
    }
  }

  a {
    align-items: center;
    background-color: #000;
    border: 1px solid transparent;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    display: flex;
    @include latoregular;
    font-size: 14px;
    height: 35px;
    letter-spacing: .28px;
    padding: 0 10px;
    @include transition;
    width: 100%;

    &:before {
      color: #fff;
      margin-right: 7px;
      @include transition(color);

      @media (min-width: $s-tablet) {
        margin-right: 10px;
      }
    }

    &.is-active {
      border-color: $primary;
      color: $primary;

      &:before {
        color: $primary;
      }
    }


    &.icon-morning {
      &:before {
        font-size: 16px;

        @media (min-width: $s-tablet) {
          font-size: 19px;
        }
      }
    }

    &.icon-coffee {
      &:before {
        font-size: 19px;

        @media (min-width: $s-tablet) {
          font-size: 22px;
        }
      }
    }

    &.icon-moon {
      &:before {
        font-size: 18px;

        @media (min-width: $s-tablet) {
          font-size: 21px;
        }
      }
    }

    &.icon-moon-full {
      &:before {
        font-size: 16px;

        @media (min-width: $s-tablet) {
          font-size: 19px;
        }
      }
    }

    .no-touchevents &:hover {
      color: $primary;

      &:before {
        color: $primary;
      }
    }
  }
}

.guide__strip-buttons {
  @media (max-width: ($medium - 1)) {
    flex-wrap: wrap;
  }

  > div {
    @media (max-width: ($medium - 1)) {
      margin-top: 15px;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.guide__strip-slider {
  position: relative;

  @media (max-width: ($medium - 1)) and (min-width: $s-tablet) {
    padding: 0 40px;
  }

  .swiper-container {
    @media (min-width: $s-tablet) {
      margin: 15px -15px -15px;
      position: static;
    }

    @media (min-width: $medium) {
      margin: 0 -30px -30px;
    }
  }

  .swiper-wrapper {
    @media (max-width: ($s-tablet - 1)) {
      flex-direction: column;
      transform: translate3d(0,0,0) !important;
    }
  }

  .swiper-slide {
    width: 100%;

    @media (min-width: $s-tablet) {
      padding: 15px;
      width: calc(100%/2);
    }

    @media (min-width: $l-tablet) {
      width: calc(100%/3);
    }

    @media (min-width: $medium) {
      padding: 30px;
    }

    &:not(:last-child) {
      @media (max-width: ($s-tablet - 1)) {
        margin-bottom: 40px;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    align-items: center;
    background: #000;
    display: flex;
    height: 100%;
    margin-top: 0;
    transform: translateY(-50%);
    width: 40px;

    @media (min-width: $medium) {
      width: 55px;
    }

    @media (max-width: ($s-tablet - 1)) {
      display: none;
    }

    &:before {
      color: #fff;
      font-size: 40px;
    }

    &:focus {
      outline: none;
    }
  }

  .swiper-button-prev {
    left: 0;

    @media (min-width: $medium) {
      transform: translate(-100%, -50%);
    }
  }

  .swiper-button-next {
    justify-content: flex-end;
    right: 0;

    @media (min-width: $medium) {
      transform: translate(100%, -50%);
    }
  }

  .swiper-button-disabled {
    display: none;
  }
}

.guide__strip-button {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  @media (min-width: $s-tablet) {
    margin-top: 50px;
  }
}

//Quiz
.quiz {
  margin-top: 40px;

  @media (min-width: $s-tablet) {
    margin-top: 50px;
  }
}

.quiz__timeline {
  background-color: $grey;
  border-radius: 1px;
  height: 10px;
  margin-bottom: 30px;
  width: 100%;

  @media (min-width: $s-tablet) {
    margin-bottom: 35px;
  }

  span {
    background-color: #5aa72b;
    border-radius: 1px;
    display: block;
    height: 100%;
    position: relative;

    &:not(.no-progress) {
      &:before {
        align-items: flex-end;
        border-right: 1px solid $border;
        bottom: 100%;
        content: attr(data-progress);
        display: flex;
        @include latobold;
        font-size: 12px;
        height: 15px;
        padding-right: 5px;
        position: absolute;
        right: 0;
        z-index: 5;
      }
    }
  }
}

.quiz__intro,
.quiz__question {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.2;
  margin-bottom: 20px;

  @media (min-width: $s-tablet) {
    font-size: 28px;
    margin-bottom: 35px;
  }
}

.quiz__intro {
  line-height: 1.57;
  margin: 0;
  padding: 25px 0;

  @media (max-width: ($s-tablet - 1)) {
    font-size: 24px;
  }

  @media (min-with: $s-tablet) {
    padding: 40px 0;
  }

  + .quiz__others {
    border-top: 1px solid $border;
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.quiz__wrapper {
  > img {
    display: block;
    height: auto;
    margin-bottom: 25px;
    width: 100%;

    @media (min-width: $s-tablet) {
      margin-bottom: 60px;
    }
  }
}

.quiz__media {
  height: 0;
  margin-bottom: 25px;
  padding-bottom: 56.25%;
  position: relative;

  @media (min-width: $s-tablet) {
    margin-bottom: 60px;
  }

  iframe {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.quiz__answers {
  display: flex;
  flex-wrap: wrap;
  margin: -12.5px -15px;

  &.quiz__answers--images,
  &.quiz__answers--battle {
    @media (max-width: ($s-tablet - 1)) {
      margin: -7.5px;
    }
  }
}

.quiz__answer {
  display: flex;
  flex: 0 0 auto;
  padding: 12.5px 15px;
  width: 100%;

  @media (min-width: $s-tablet) {
    width: calc(100%/2);
  }

  &.quiz__answer--wrong {
    .quiz__answers.has-answered & {
      label {
        background-color: #ff0000;
        color: #fff;

        > span {
          &:before {
            border-color: #fff;
          }

          span {
            &:after {
              background-color: #ff0000;
            }
          }
        }
      }
    }
  }

  &.quiz__answer--correct {
    .quiz__answers.has-answered & {
      label {
        background-color: green;
        color: #fff;

        > span {
          &:before {
            border-color: #fff;
          }

          span {
            &:after {
              background-color: green;
            }
          }
        }
      }
    }
  }

  .quiz__answers--images &,
  .quiz__answers--battle & {
    width: calc(100%/2);

    @media (max-width: ($s-tablet - 1)) {
      padding: 7.5px;
    }
  }

  .quiz__answers--battle & {
    &:not(:last-child) {
      picture {
        &:after {
          align-items: center;
          background-color: #fff;
          border-radius: 50%;
          content: "VS";
          display: flex;
          font-size: 17px;
          height: 50px;
          justify-content: center;
          right: -7.5px;
          position: absolute;
          top: 50%;
          transform: translate(50%, -50%);
          width: 50px;
          z-index: 10;

          @media (min-width: $s-tablet) {
            font-size: 25px;
            height: 75px;
            right: -15px;
            width: 75px;
          }
        }
      }
    }
  }

  picture {
    height: 0;
    order: -1;
    padding-bottom: 100%;
    position: relative;
    width: 100%;

    .quiz__answers--battle & {
      padding-bottom: 120.47%;
    }

    img {
      border-radius: 5px 5px 0 0;
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  input {
    display: none;

    &:checked {
      + label {
        background-color: #fcfceb;
      }
    }

    &:not(:checked) {
      + label > span span {
        &:before, &:after {
          display: none;
        }
      }
    }
  }

  label {
    background-color: $grey;
    border: solid 1px $border;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 1.1;
    text-align: left;
    width: 100%;

    @media (min-width: $s-tablet) {
      font-size: 18px;
    }

    > span {
      display: flex;
      height: 100%;
      padding: 10px;
      width: 100%;

      @media (min-width: $s-tablet) {
        padding: 15px;
      }

      &:before {
        border: solid 2px #282828;
        border-radius: 50%;
        content: "";
        display: block;
        flex-shrink: 0;
        height: 25px;
        margin-right: 15px;
        width: 25px;
      }

      .quiz__answers--images &,
      .quiz__answers--battle & {
        @media (max-width: ($s-tablet - 1)) {
          font-size: 14px;
        }

        &:before {
          @media (max-width: ($s-tablet - 1)) {
            height: 20px;
            margin-right: 10px;
            width: 20px;
          }
        }
      }

      span {
        margin-top: 4px;
        position: relative;

        @media (min-width: $s-tablet) {
          margin-top: 3px;
        }

        &:before {
          font-size: 13px;
          left: -33px;
          position: absolute;
          top: 0;
          z-index: 10;

          @media (min-width: $s-tablet) {
            top: 1px;
          }
        }

        &:after {
          background-color: #fcfceb;
          content: "";
          display: block;
          height: 15px;
          left: -25px;
          position: absolute;
          top: -4px;
          width: 15px;
          z-index: 5;

          @media (min-width: $s-tablet) {
            top: -3px;
          }
        }

        .quiz__answers--images &,
        .quiz__answers--battle & {
          &:before {
            @media (max-width: ($s-tablet - 1)) {
              font-size: 10px;
              left: -25px;
              top: 0;
            }
          }

          &:after {
            @media (max-width: ($s-tablet - 1)) {
              height: 10px;
              left: -20px;
              top: -3px;
              width: 10px;
            }
          }
        }
      }
    }
  }
}

.quiz__button {
  margin-top: 60px;

  > button, > a {
    align-items: center;
    background-color: $primary;
    border: solid 1px $border;
    border-radius: 5px;
    color: #fff;
    display: flex;
    @include latobold;
    font-size: 18px;
    height: 60px;
    justify-content: center;
    transition: background-color .3s ease;
    width: 100%;

    &:before {
      font-size: 27px;
      margin-right: 15px;
    }

    &[disabled] {
      background-color: #000;
      cursor: default;
      opacity: .1;
      pointer-events: none;
      width: 100%;
    }

    .no-touchevents &:hover {
      background-color: $primaryDarken;
    }
  }
}

.quiz__solution {
  border: solid 1px $border;
  border-radius: 10px;
  @include latoregular;
  font-size: 16px;

  @media (min-width: $s-tablet) {
    font-size: 18px;
  }
}

.quiz__solution-header,
.quiz__solution-share,
.quiz__solution-chart {
  padding: 20px;

  @media (min-width: $s-tablet) {
    padding: 25px;
  }
}

.quiz__solution-header {
  > * {
    display: block;
    line-height: 1.2;
  }

  strong {
    @include latobold;

    &:first-child {
      font-size: 12px;
      margin-bottom: 15px;

      @media (min-width: $s-tablet) {
        font-size: 14px;
      }
    }
  }

  span {
    margin-bottom: 3px;

    @media (min-width: $s-tablet) {
      margin-bottom: 5px;
    }
  }
}

.quiz__solution-image {
  height: 0;
  padding-bottom: 52.5%;
  position: relative;

  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.quiz__solution-share {
  span {
    display: block;
    margin-bottom: 10px;
  }

  > div {
    display: flex;
  }

  button {
    align-items: center;
    border-radius: 5px;
    color: #fff;
    display: flex;
    @include latobold;
    flex: 1 1 auto;
    height: 50px;
    justify-content: center;
    @include transition(background-color);
    width: calc(100%/3);

    @media (max-width: ($s-tablet - 1)) {
      font-size: 14px;
    }

    &[name="facebook"] {
      background-color: $facebook;

      .no-touchevents &:hover {
        background-color: darken($facebook, 10%);
      }
    }

    &[name="twitter"] {
      background-color: $twitter;

      .no-touchevents &:hover {
        background-color: darken($twitter, 10%);
      }
    }

    &[name="whatsapp"] {
      background-color: $whatsapp;

      .no-touchevents &:hover {
        background-color: darken($whatsapp, 10%);
      }
    }

    &:not(:last-child) {
      margin-right: 10px;

      @media (min-width: $s-tablet) {
        margin-right: 15px;
      }
    }
  }
}

.quiz__solution-chart {
  padding-bottom: 0;
  padding-top: 0;

  ul {
    > li {
      align-items: center;
      border-top: 1px solid $border;
      counter-increment: position;
      display: flex;
      line-height: 1.2;
      padding: 10px 0;

      &:before {
        content: "#" counter(position);
        flex-shrink: 0;
        width: 35px;

        @media (min-width: $s-tablet) {
          width: 45px;
        }
      }

      &:last-child {
        border-bottom: 1px solid $border;
      }

      > div {
        flex-shrink: 0;
        margin-right: 20px;
        width: 70px;

        @media (min-width: $s-tablet) {
          margin-right: 25px;
          width: 80px;
        }

        > div {
          height: 0;
          padding-bottom: 120.47%;
          position: relative;
        }

        img {
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }

      span {
        display: block;
        margin-right: 20px;

        @media (min-width: $s-tablet) {
          margin-right: 25px;
        }
      }

      strong {
        display: block;
        flex-shrink: 0;
        margin-left: auto;
      }
    }
  }
}

.quiz__launch {
  > a {
    display: block;
    position: relative;
  }
}

.quiz__launch-image {
  background-color: $grey;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.quiz__launch-button {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
  transition: transform .5s ease;
  z-index: 10;

  .no-touchevents .quiz__launch > a:hover & {
    transform: translate(-50%,-50%) scale(1.05);
  }

  > span {
    align-items: center;
    backface-visibility: hidden;
    background-color: $primary;
    border: solid 3px #f3f3f3;
    border-radius: 10px;
    color: #ffffff;
    display: flex;
    @include latobold;
    font-size: 18px;
    height: 50px;
    justify-content: center;
    transform: perspective(1px) translateZ(0);
    width: 190px;

    @media (min-width: $s-tablet) {
      font-size: 22px;
      height: 60px;
      width: 270px;
    }
  }
}

.quiz__others {
  padding-top: 40px;

  @media (min-width: $s-tablet) {
    padding-top: 50px;
  }

  h2 {
    @include latoblack;
    font-size: 20px;

    @media (min-width: $s-tablet) {
      font-size: 25px;
    }
  }
}

.quiz__others-item {
  margin-top: 25px;

  > a {
    border: 1px solid $primary;
    display: block;

    @media (min-width: $s-tablet) {
      display: flex;
    }
  }
}

.quiz__others-image {
  @media (min-width: $s-tablet) {
    flex-shrink: 0;
    width: 240px;
  }

  > div {
    height: 0;
    overflow: hidden;
    padding-bottom: 57.5%;
    position: relative;
  }

  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 1s ease, opacity 0.3s ease;
    width: 100%;

    .no-touchevents .quiz__others-item > a:hover & {
      transform: scale(1.1);
    }
  }
}

.quiz__others-caption {
  padding: 15px 20px;

  @media (min-width: $s-tablet) {
    padding: 15px 30px;
  }

  span {
    color: $primary;
    display: block;
    @include latobold;
    font-size: 12px;
    letter-spacing: 0.12px;
    margin-bottom: 3px;

    @media (min-width: $s-tablet) {
      font-size: 14px;
      letter-spacing: 0.14px;
      margin-bottom: 5px;
    }
  }

  h3 {
    @include latoblack;
    font-size: 18px;
    line-height: 1.35;
    @include transition(color);

    @media (min-width: $s-tablet) {
      font-size: 20px;
    }

    .no-touchevents .quiz__others-item > a:hover & {
      color: $primary;
    }
  }
}

//Search
.search__body {
  margin: 30px 0;

  @media (min-width: $s-tablet) {
    margin: 50px 0;
  }
}

.search__content {
  .gsc-control-cse {
    padding: 0;
  }
}

//Search overylay
.search__overlay {
  background-color: rgba(0,0,0,.95);
  color: #fff;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  opacity: 0;
  padding-top: 50px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000000010; //sennò il banner pubblicitario va sopra

  @media (max-width: ($s-tablet - 1)) {
    transform: translateX(100%);
    transition: transform 0.3s ease, opacity 0s 0.3s ease;
  }

  @media (min-width: $s-tablet) {
    backface-visibility: hidden;
    padding-top: 75px;
    transition: opacity .3s ease, visibility .3s ease;
    visibility: hidden;
  }

  @media (min-width: $l-tablet) {
    padding-top: 100px;
  }

  &.is-open {
    @media (max-width: ($s-tablet - 1)) {
      opacity: 1;
      transform: translateX(0);
      transition: transform 0.3s ease, opacity 0s 0s ease;
    }

    @media (min-width: $s-tablet) {
      backface-visibility: visible;
      opacity: 1;
      visibility: visible;
    }
  }

  .icon-x {
    color: #fff;
    font-size: 15px;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 30px;
    width: 30px;
    z-index: 5;

    @media (min-width: $s-tablet) {
      right: 30px;
    }
  }

  strong {
    display: block;
    @include latobold;
    font-size: 30px;

    @media (min-width: $s-tablet) {
      font-size: 40px;
    }

    @media (min-width: $l-tablet) {
      font-size: 50px;
    }

    @media (min-width: $medium) {
      font-size: 60px;
    }
  }
}

.search__overlay-form {
  margin-top: 20px;
  position: relative;

  @media (min-width: $s-tablet) {
    margin-top: 30px;
  }

  @media (min-width: $l-tablet) {
    margin-top: 40px;
  }

  @media (min-width: $medium) {
    margin-top: 50px;
  }

  input {
    background: none;
    border: none;
    border-bottom: 1px solid #fff;
    box-shadow: none;
    color: #fff;
    @include latoregular;
    font-size: 30px;
    padding: 15px 0;
    width: 100%;

    @media (min-width: $s-tablet) {
      border-bottom: 2px solid #fff;
      font-size: 40px;
    }

    @media (min-width: $l-tablet) {
      font-size: 50px;
    }

    @media (min-width: $medium) {
      font-size: 60px;
    }

    &:focus {
      outline: none;
    }
  }

  button {
    color: #fff;
    font-size: 15px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;

    @media (min-width: $s-tablet) {
      font-size: 20px;
      right: 20px;
    }

    @media (min-width: $medium) {
      right: 25px;
    }
  }
}

//Strip della TV Stories in home
.stories__strip {
  > div {
    &:after {
      background-color: $border;
      content: "";
      display: block;
      height: 2px;
      width: calc(100% - $container-padding * 2);
    }
  }
}

.stories__strip-slider {
  padding: 25px 0;
  position: relative;

  @media (min-width: $s-tablet) {
    padding: 75px 0;
  }

  @media (min-width: $medium) {
    margin: 0 -15px;
    padding: 60px 0;
  }

  .swiper-container {
    @media (max-width: $medium - 1) {
      margin: 0 35px 0 -5px;
      overflow: visible;
    }

    @media (min-width: $medium) {
      margin: 0 -5px;
      padding: 15px;
    }

    &:before, &:after {
      @media (min-width: $medium) {
        background-color: #fff;
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        width: 15px;
        z-index: 5;
      }
    }

    &:before {
      @media (min-width: $medium) {
        left: 0;
      }
    }

    &:after {
      @media (min-width: $medium) {
        right: 0;
      }
    }
  }

  .swiper-slide {
    height: auto;
    padding: 5px;
    width: calc(100%/2);

    @media (min-width: $s-mobile) {
      width: calc(100%/3);
    }

    @media (min-width: $s-tablet) {
      width: calc(100%/5);
    }

    @media (min-width: $l-tablet) {
      width: calc(100%/6);
    }

    @media (min-width: $medium) {
      width: calc(100%/7);
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 1.5px 2.6px 60px 0 rgba(0, 0, 0, 0.4);
    display: flex;
    height: 55px;
    justify-content: center;
    margin-top: 0;
    width: 55px;

    @media (max-width: $medium - 1) {
      display: none;
    }

    &:before {
      color: #000;
      font-size: 20px;
      @include transition(color);
    }

    &:focus {
      outline: none;
    }

    .no-touchevents &:hover {
      &:before {
        color: $primary;
      }
    }
  }

  .swiper-button-prev {
    left: 0;
    transform: translate(-50%, -50%);

    @media (min-width: $medium) {
      left: 15px;
    }
  }

  .swiper-button-next {
    right: 0;
    transform: translate(50%, -50%);

    @media (min-width: $medium) {
      right: 15px;
    }
  }

  .swiper-button-disabled {
    display: none;
  }
}

//TV Stories
.stories {
  margin-top: 20px;
  padding: 0 $container-padding;

  @media (min-width: $s-tablet) {
    margin-top: 35px;
  }

  @media (min-width: $medium) {
    padding: 0 65px;
  }
}

.stories__list {
  display: flex;
  flex-wrap: wrap;
  margin: -7.5px;

  @media (min-width: $s-tablet) {
    margin: -12.5px;
  }
}

.stories__item {
  padding: 7.5px;
  width: calc(100%/2);

  @media (min-width: $s-mobile) {
    width: calc(100%/3);
  }

  @media (min-width: $s-tablet) {
    padding: 12.5px;
    width: calc(100%/5);
  }

  @media (min-width: $l-tablet) {
    width: calc(100%/6);
  }

  @media (min-width: $medium) {
    width: calc(100%/7);
  }

  @media (min-width: $wide) {
    width: calc(100%/8);
  }

  @media (min-width: $l-wide) {
    width: calc(100%/10);
  }

  &.stories__item--banner {
      width: 100%;
  }
}

.story__card {
  &.story__card--all {
    > a {
      align-items: center;
      background-color: $primary;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 0 10px;

      &:before {
        background: url(../img/television.svg) no-repeat 50% 50%;
        background-size: contain;
        content: "";
        display: block;
        flex-shrink: 0;
        height: 55px;
        margin: 0 auto 10px;
        width: 55px;

        @media (min-width: $s-tablet) {
          height: 65px;
          width: 65px;
        }
      }

      .no-touchevents &:hover {
        span {
          &:before {
            background-color: #fff;
            color: #000;
          }
        }
      }
    }

    span {
      align-items: center;
      color: #fff;
      display: flex;
      flex-direction: column;
      @include latobold;
      font-size: 16px;
      text-align: center;

      @media (min-width: $s-tablet) {
        font-size: 17px;
      }

      &:before {
        align-items: center;
        border: 1px solid #fff;
        border-radius: 50%;
        display: flex;
        font-size: 9px;
        height: 25px;
        justify-content: center;
        margin-top: 20px;
        order: 2;
        transform: rotate(-180deg);
        @include transition;
        width: 25px;
      }
    }
  }

  > a {
    background-color: $grey;
    box-shadow: rgba(45,45,45,.15) 0 1px 5px;
    display: block;
    @include transition(transform);

    .no-touchevents &:hover {
      transform: translateY(-4px);
    }

    > div {
      height: 0;
      padding-bottom: 177.77%;
      position: relative;

      &.loading {
        &:after {
          left: 50%;
          margin-left: -15px;
          margin-top: -15px;
          position: absolute;
          top: 50%;
          z-index: 10;

          @media (min-width: $s-tablet) {
            margin-left: -20px;
            margin-top: -20px;
          }
        }
      }
    }
  }

  img {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

//Ads Video
@keyframes transIn {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes transInOp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleInOp {
  0% {
    @include hidden;
    transform: scale(0);
  }

  100% {
    @include visible;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    @include hidden;
  }

  100% {
    @include visible;
  }
}

.video-widget {
  &:not(.is-active):not(.video-widget--nested) {
    display: none;
  }

  &:not(.video-widget--nested) {
    bottom: 0;
    position: fixed;
    z-index: 9500;

    @media (max-width: 979px) {
      left: 0;
      right: 0;
      width: 100%;
    }

    @media (min-width: 980px) {
      right: $container-padding;
    }

    @media (min-width: $medium) {
      right: calc((100% - #{$container} + #{$container-padding} * 2) / 2);
    }
  }

  &.video-widget--fs {
    align-items: flex-end;
    display: flex;
    top: 40px;

    @media (min-width: 980px) {
      left: 60px;
      right: 60px;
      top: 35px;
    }

    @media (min-width: 1500px) {
      left: 50%;
      right: auto;
      top: 40px;
      transform: translateX(-50%);
      width: $container;
    }

    &:before {
      background-color: rgba(0, 0, 0, 0.7);
      content: "";
      display: block;
      height: 120vh;
      left: 50%;
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;
      z-index: -1;
    }
  }
}

.video-widget__sentinel,
.post__featured-video__sentinel {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.video-widget__inner {
  @media (max-width: 979px) {
    align-items: flex-end;
    background-color: #000;
    border-top: 1px solid $primary;
    display: flex;
    height: 75px;
    justify-content: center;
  }

  @media (max-width: $s-tablet - 1) {
    height: 70px;
  }

  .video-widget--nested & {
    background: none;
    border-top: none;
    display: block;
    height: 100%;
  }

  .video-widget:not(.video-widget--fs):not(.video-widget--ig):not(.video-widget--nested) & {
    @media (min-width: 980px) {
      height: 235px;
    }
  }

  .video-widget--fs & {
    animation: scaleInOp 0.3s ease forwards;
    backface-visibility: hidden;
    background-color: #000;
    display: flex;
    height: 100%;
    opacity: 0;
    padding: 30px 15px 0;
    transform: scale(0);
    transform-origin: center bottom;
    visibility: hidden;
    width: 100%;

    @media (min-width: $s-tablet) {
      padding: 30px 20px 0;
    }

    @media (min-width: 1500px), (max-width: 979px) {
      align-items: center;
      flex-direction: column;
    }

    @media (min-width: 980px) {
      transform-origin: right bottom;
    }
  }

  .video-widget--ig & {
    align-items: center;
    background-color: #000;
    border-top: 1px solid $primary;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    height: 70px;
    justify-content: center;

    @media (min-width: $s-tablet) {
      height: 80px;
    }

    @media (min-width: 980px) {
      height: 89px;
    }
  }
}

.video-widget__main {
  @media (min-width: 980px) {
    background-color: #000;
    border-top: 1px solid $primary;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    padding: 20px 10px 10px;
  }

  .video-widget.is-active:not(.video-widget--fs) & {
    animation: transIn 0.3s ease forwards;
  }

  .video-widget:not(.video-widget--fs):not(.video-widget--ig):not(.video-widget--nested) & {
    align-items: flex-end;
    display: flex;

    @media (max-width: 979px) {
      position: relative;
      z-index: 10;
    }
  }

  .video-widget--nested & {
    animation: none;
    background-color: transparent;
    border-radius: 0;
    border-top: 0;
    box-shadow: none;
    padding: 0;
  }

  .video-widget--fs & {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    width: 100%;

    @media (max-width: 1499px) and (min-width: 980px) {
      height: 100%;
      margin-right: 2.5%;
      overflow-y: auto;
      width: calc(70% - 2.5%);
    }
  }

  .video-widget--ig & {
    display: none;
  }
}

.video-widget__others {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  @media (max-width: 979px) {
    margin: 15px 0;
  }

  @media (max-width: 1499px) and (min-width: 980px) {
    width: 30%;
  }

  @media (min-width: 1500px) {
    margin: 15px 0;
  }

  .no-touchevents & {
    padding-right: 20px;
  }

  .video-widget:not(.video-widget--fs):not(.video-widget--ig) & {
    display: none;
  }

  .video-widget--ig & {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;

    @media (min-width: 980px) {
      width: 385px;
    }
  }
}

.video-widget__others-list {
  margin: -12.5px -17.5px;

  @media (min-width: 980px) {
    margin: -12.5px -25px;
  }

  @media (min-width: 1500px), (max-width: 979px) {
    display: flex;
    flex-wrap: wrap;
  }

  .video-widget--ig & {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
    margin: -5px;
    overflow: hidden;

    @media (min-width: 980px) {
      margin: -7.5px;
    }
  }
}

.video-widget__others-item {
  padding: 12.5px 17.5px;

  @media (max-width: 979px) {
    flex: 0 0 auto;
    width: calc(100% / 2);
  }

  @media (max-width: $s-tablet - 1) {
    width: 100%;
  }

  @media (min-width: 980px) {
    padding: 12.5px 25px;
  }

  @media (min-width: 1500px) {
    flex: 0 0 auto;
    width: calc(100% / 3);
  }

  &.is-active {
    > button {
      .video-widget--ig & {
        border: 2px solid $primary;
      }
    }
  }

  .video-widget--ig & {
    animation: transInOp 0.3s ease forwards;
    display: none;
    opacity: 0;
    padding: 5px;
    width: auto;

    @media (min-width: 980px) {
      padding: 7.5px;
    }

    &.is-first {
      display: block;

      + li {
        display: block;
        animation-delay: 0.1s;
      }

      + li + li {
        display: block;
        animation-delay: 0.15s;
      }

      + li + li + li {
        display: block;
        animation-delay: 0.2s;
      }

      + li + li + li + li {
        display: block;
        animation-delay: 0.25s;
      }
    }
  }

  > button {
    display: flex;
    text-align: left;
    width: 100%;

    .no-touchevents &:hover {
      .video-widget__media img {
        transform: scale(1.05) translateZ(0);
      }

      .video-widget__title {
        color: $primary;
      }
    }

    .video-widget--ig & {
      border: 2px solid #3f3f3f;
      border-radius: 50%;
    }
  }
}

.video-widget__header {
  .video-widget--ig & {
    display: none;
  }

  .video-widget--nested & {
    display: none;
  }

  > button {
    align-items: center;
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: 0 0 2.9px 0.2px rgba(0, 0, 0, 0.2);
    color: #fff;
    display: flex;
    font-size: 15px;
    height: 30px;
    justify-content: center;
    position: absolute;
    transition: background-color 0.3s ease;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    top: 0;
    transform: translateY(-50%);
    width: 30px;
    z-index: 15;

    .no-touchevents &:hover {
      background-color: $primaryDarken;
    }

    &:first-child {
      left: 5px;

      @media (min-width: $s-tablet) {
        left: 15px;
      }

      @media (min-width: 980px) {
        left: 25px;
      }
    }

    &:last-child {
      right: 5px;

      @media (min-width: $s-tablet) {
        right: 15px;
      }

      @media (min-width: 980px) {
        right: 25px;
      }
    }

    &.icon-close {
      display: none;

      &.is-active {
        display: flex;
      }
    }

    .video-widget.is-active:not(.video-widget--fs) & {
      animation: fadeIn 0.5s ease forwards 0.3s;
      @include hidden;
    }
  }
}

.video-widget__media {
  position: relative;

  .video-widget:not(.video-widget--nested) & {
    flex-shrink: 0;
    width: 290px;

    @media (min-width: 980px) {
      width: 365px;
    }
  }

  .video-widget__main & {
    .video-widget--fs & {
      width: calc(100% + 20px);

      @media (max-width: $s-tablet - 1) {
        margin: 0 -10px;
      }

      @media (min-width: $s-tablet) {
        width: 100%;
      }
    }
  }

  .video-widget__others & {
    flex-shrink: 0;
    overflow: hidden;

    .video-widget--fs & {
      width: 45%;

      @media (min-width: 1500px), (max-width: 979px) {
        width: 165px;
      }

      @media (min-width: 1500px) {
        @media (max-height: 799px) {
          width: 135px;
        }
      }
    }

    .video-widget--ig & {
      border: 2px solid #000;
      border-radius: 50%;
      height: 55px;
      position: relative;
      width: 55px;
      z-index: 5;
    }

    > div {
      border-radius: 0;
    }
  }

  .video-widget__others .is-active & {
    .video-widget--fs & {
      &:before {
        color: #fff;
        content: "IN RIPRODUZIONE";
        @include latobold;
        font-size: 11px;
        left: 7px;
        position: absolute;
        text-shadow: 0 0 rgba(0, 0, 0, 0.5);
        top: 7px;
        z-index: 5;
      }
    }
  }

  > div {
    background-color: $grey;
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    @media (max-width: 979px) {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    }

    .video-widget--ig & {
      background-color: #000;
      padding-bottom: 100%;
    }

    .video-widget__main & {
      background-color: #000;

      .video-widget--nested & {
        background-color: transparent;
      }
    }

    &.loading {
      &:after {
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        z-index: 10;

        @media (min-width: $s-tablet) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
    }
  }

  iframe,
  img {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .video-widget--ig & {
      border-radius: 50%;
    }
  }

  img {
    object-fit: cover;
    object-position: center;
    transition: transform 0.3s ease;
  }
}

.video-widget__caption {
  color: #fff;
  @include latoregular;
  padding: 10px 0;

  .video-widget__main & {
    .video-widget:not(.video-widget--fs) & {
      display: none;
    }

    .video-widget--ig & {
      display: none;
    }
  }

  .video-widget__others & {
    padding: 0 0 0 15px;
  }

  .video-widget--ig & {
    display: none;
  }
}

.video-widget__title {
  display: block;
  font-size: 18px;
  line-height: 1.2;
  transition: color 0.3s ease;

  .video-widget__others & {
    @include latobold;
    font-size: 14px;
    max-height: 33px;
    overflow: hidden;
  }
}

.video-widget__info {
  color: #838383;
  font-size: 13px;
  line-height: 1.31;
  margin: 5px 0 0;

  .video-widget__main & {
    span {
      &:not(:last-child) {
        &:after {
          content: "-";
          margin: 0 2px;
        }
      }
    }
  }

  .video-widget__others & {
    span {
      display: block;
    }
  }
}

.video-widget__desc {
  font-size: 13px;
  line-height: 1.46;
  margin: 10px 0 0;

  @media (min-width: 1500px), (max-width: 979px) {
    display: none;
  }

  .video-widget__others & {
    display: none;
  }
}

//Star
.star__card {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;

  &.star__card--long {
    flex-direction: row;
    width: 100%;

    @media (min-width: $l-mobile) {
      width: 50%;
    }

    > a {
      flex-direction: row;
    }
  }

  &:not(.star__card--long) {
    .movie__stars &,
    .star__relateds &,
    .stars__strip-slider & {
      width: calc(100% / 2);

      @media (min-width: $s-mobile) {
        width: calc(100% / 3);
      }

      @media (min-width: $s-tablet) {
        width: calc(100% / 5);
      }
    }

    .stars__strip-slider & {
      width: calc(100% / 2);

      @media (min-width: $l-mobile) {
        width: calc(100% / 3);
      }

      @media (min-width: $s-tablet) {
        width: calc(100% / 4);
      }

      @media (min-width: $l-tablet) {
        width: calc(100% / 5);
      }
    }
  }

  .movie__stars &,
  .star__relateds & {
    padding: 10px 7.5px;

    @media (min-width: $s-tablet) {
      padding: 10px 12.5px;
    }
  }

  .stars__section & {
    padding: 15px;
    width: calc(100% / 2);

    @media (min-width: $l-mobile) {
      width: calc(100% / 3);
    }

    @media (min-width: $s-tablet) {
      width: calc(100% / 4);
    }

    @media (min-width: $l-tablet) {
      padding: 20px;
    }
  }

  .stars__strip-slider & {
    padding: 12.5px;

    @media (min-width: $l-tablet) {
      padding: 20px;
    }
  }

  > a {
    display: flex;
    flex-direction: column;
  }
}

.star__card-image {
  .star__card--long & {
    flex-shrink: 0;
    margin-right: 15px;
    width: 120px;

    @media (min-width: $s-tablet) {
      margin-right: 25px;
      width: 130px;
    }
  }

  > div {
    border-radius: 5px;
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;

    &.loading {
      background-color: $grey;

      &:after {
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        z-index: 10;

        @media (min-width: $s-tablet) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
    }
  }

  img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    top: 0;
    @include transition(transform);
    width: 100%;

    .no-touchevents .star__card > a:hover & {
      transform: scale(1.1);
    }
  }
}

.star__card-caption {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 100%;
  letter-spacing: 0.14px;
  padding: 10px 10px 0 3px;

  .stars__section &,
  .stars__strip-slider & {
    font-size: 16px;
    letter-spacing: 0.16px;
  }
}

.star__card-name {
  color: #000;
  font-size: inherit;
  @include latobold;
  line-height: 1.15;
  @include transition(color);

  @media (min-width: $s-tablet) {
    font-size: 16px;
    letter-spacing: 0.16px;
  }

  .no-touchevents .star__card > a:hover & {
    color: $primary;
  }

  .stars__section &,
  .stars__strip-slider & {
    @media (min-width: $s-tablet) {
      font-size: 18px;
      letter-spacing: 0.18px;
    }
  }

  .stars__strip-slider & {
    color: #fff;
  }
}

.star__card-desc {
  color: #999;
  display: block;
  margin-top: 4px;
}
//Strip stars in home
.stars__strip {
  background-color: #000;
  padding: 30px 0 40px;

  @media (min-width: $s-tablet) {
    padding: 40px 0 70px;
  }

  @media (min-width: $l-tablet) {
    padding: 60px 0 100px;
  }

  > div {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > p {
      color: #fff;
      @include latoregular;
      font-size: 16px;
      letter-spacing: 0.16px;
      margin: 10px 0 0;
      order: -1;
      width: 100%;

      @media (min-width: $s-tablet) {
        font-size: 18px;
        letter-spacing: 0.18px;
        margin: 20px 0 0;
      }
    }
  }

  h2 {
    color: #fff;
    @include latoblack;
    font-size: 30px;
    order: -3;

    @media (min-width: $s-tablet) {
      font-size: 45px;
    }
  }
}

.stars__strip-buttons {
  @media (max-width: ($s-tablet - 1)) {
    display: none;
  }

  @media (min-width: $s-tablet) {
    margin: -10px;
    order: -2;
  }

  > div {
    flex: 1 1 auto;
    padding: 7px;

    @media (max-width: ($l-tablet - 1)) {
      width: calc(100% / 4);
    }

    @media (max-width: ($l-mobile - 1)) {
      width: calc(100% / 2);
    }

    @media (min-width: $s-tablet) {
      padding: 10px;
    }
  }
}

.stars__strip-slider {
  margin-top: 20px;
  width: 100%;

  @media (min-width: $s-tablet) {
    margin-top: 45px;
  }

  .swiper-container {
    @media (max-width: ($s-tablet - 1)) {
      margin: 0 32.5px 0 -7.5px; //40 - margin
      overflow: visible;
    }

    @media (min-width: $s-tablet) {
      margin: 0 -12.5px;
    }
  }
}

.stars__strip-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;

  @media (min-width: $s-tablet) {
    margin-top: 50px;
  }
}

//Disclaimer
.disclaimer {
  font-size: 12px;
  letter-spacing: 0.12px;
  line-height: 1.3;
  margin-top: 20px;
  text-align: center;

  @media (min-width: $s-tablet) {
    font-size: 14px;
    letter-spacing: 0.14px;
    margin-top: 35px;
  }

  &:not(:empty) {
    &:before {
      content: "***";
    }
  }

  .post__content & {
    margin-bottom: 50px;

    @media (min-width: $l-tablet) {
      margin-bottom: 100px;
    }
  }

  .fullscreen__caption & {
    margin-top: auto;
    padding-top: 25px;

    @media (min-width: $s-tablet) {
      padding-top: 35px;
    }

    @media (min-width: $l-tablet) {
      padding-top: 55px;
    }
  }

  > p {
    &:first-child {
      > i {
        &:before {
          content: "Disclaimer";
          display: block;
          font-weight: 700;
          margin-bottom: 1em;
        }
      }
    }
  }

  a {
    background-image: linear-gradient(#fff 50%, rgba(232, 0, 0, 0.6) 0);
    background-repeat: repeat-x;
    background-position: 0 22px;
    background-size: 1px 1px;
    color: $primary;
    text-decoration: none;
    position: relative;
    text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
    z-index: 2;
  }
}

//Preview
.preview {
  background-color: #000;
  bottom: 15px;
  color: #fff;
  font-family: sans-serif;
  font-size: 14px;
  opacity: 0.5;
  padding: 15px;
  position: fixed;
  left: 15px;
  z-index: 1000000000; //sennò il banner pubblicitario va sopra

  a {
    color: #fff;
    font-family: sans-serif;
    font-size: 14px;
    text-decoration: underline;

    .no-touchevents &:hover {
      text-decoration: none;
    }
  }

  p {
    margin: 0;
  }
}

//Guida TV
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(10deg);
  }

  24% {
    transform: rotate(-9deg);
  }

  28% {
    transform: rotate(8deg);
  }

  32% {
    transform: rotate(-7deg);
  }

  36% {
    transform: rotate(6deg);
  }

  40% {
    transform: rotate(-5deg);
  }

  44% {
    transform: rotate(4deg);
  }

  48% {
    transform: rotate(-3deg);
  }

  52% {
    transform: rotate(2deg);
  }

  56% {
    transform: rotate(1deg);
  }

  60% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1,1);
  }

  30% {
    opacity: .3;
  }

  60% {
    opacity: 0;
    transform: scale(1.5,1.5);
  }

  100% {
    opacity: 0;
    transform: scale(1.5,1.5);
  }
}

@keyframes pulseButton {
  0% {
    transform: scale(.97);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(232,0,0,0);
    transform: scale(1);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(232,0,0,0);
    transform: scale(.97);
  }
}

.guide__channels-fxd {
  background-color: #333;
  height: 65px;
  left: 0;
  position: fixed;
  top: 85px;
  width: 100%;
  z-index: 99;

  + .guide__header {
    margin-top: 64px;
  }
}

.guide__channels {
  background-color: #333;
  height: 65px;

  @media (min-width: $s-tablet) {
    height: 75px;
  }

  .guide & {
    position: sticky;
    top: 0;
    z-index: 15;

    .app__header--sticky.is-active ~ .app__body & {
      top: 53px;

      @media (min-width: $l-tablet) {
        top: 95px;
      }

      @media (min-width: 1366px) {
        top: 48px;
      }
    }
  }

  .guide__strip & {
    background-color: #000;
    border-bottom: 1px solid #111;
    border-top: 1px solid #111;

    @media (max-width: ($s-tablet - 1)) {
      margin: 20px 0;
    }
  }

  > div {
    > div {
      display: flex;
      max-width: calc(#{$container} - (20px * 2));
      padding: 0;
    }
  }
}

.guide__channels-slider {
  margin: 0;
  padding: 10px 0;
  width: 100%;

  @media (max-width: ($container - 1)) {
    padding: 10px 20px;
  }

  @media (max-width: ($s-tablet - 1)) {
    padding: 10px 15px;
  }
}

.guide__channels-item {
  align-items: center;
  display: flex;
  opacity: .8;
  transition: opacity .3s ease;
  width: auto;

  &:not(.swiper-slide):not(.guide__channels-item--fixed) {
    padding-top: 2px;

    &:last-child {
      padding-right: 15px;
    }
  }

  &:not(.guide__channels-item--fixed):not(:last-child) {
    padding-right: 10px;
  }

  &:not(.guide__channels-item--fixed) {
    .guide__channels-image {
      background-color: #000;
    }
  }

  &.guide__channels-item--fixed {
    background-color: rgba(51, 51, 51, 0.95);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    flex-shrink: 0;
    margin: 10px 0;
    opacity: 1;
    padding-right: 20px;
    position: relative;
    z-index: 10;

    @media (max-width: ($container - 1)) {
      padding: 0 20px;

      .guide__strip & {
        padding-left: 0;
      }
    }

    @media (max-width: ($s-tablet - 1)) {
      padding: 0 15px;
    }

    + .swiper-container {
      overflow: hidden;
      padding-left: 15px;
      padding-right: 15px;
      width: calc(100% - 75px);

      @media (min-width: $s-tablet) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .guide__strip & {
        @media (max-width: ($s-tablet - 1)) {
          width: calc(100% - 60px);
        }
      }
    }

    .guide__strip & {
      background-color: rgba(0, 0, 0, 0.95);
    }

    > button {
      border-radius: 50%;
      overflow: hidden;

      &:before {
        background-color: $primary;
        border-radius: 50%;
        content: "";
        display: block;
        height: 45px;
        left: 15px;
        position: absolute;
        top: 0;
        width: 45px;
        z-index: -1;

        @media (min-width: $s-tablet) {
          height: 55px;
          left: 20px;
          width: 55px;
        }

        @media (min-width: $container) {
          left: 0;
        }

        .guide__strip & {
          left: 0;
        }
      }
    }
  }

  &.guide__channels-item--current {
    opacity: 1;

    > a {
      position: relative;

      > span {
        display: block;
      }
    }
  }

  .no-touchevents &:hover {
    opacity: 1;
  }

  > button, > a {
    display: block;
    height: 45px;
    width: 45px;

    @media (min-width: $s-tablet) {
      height: 55px;
      width: 55px;
    }

    &.is-anim {
      &:after {
        animation: pulse 3s linear 5.7s infinite;
        background-color: #b17e75;
        border-radius: 50%;
        bottom: 0;
        content: "";
        display: block;
        height: 45px;
        left: 15px;
        position: absolute;
        width: 45px;
        z-index: -2;

        @media (min-width: $s-tablet) {
          height: 55px;
          left: 20px;
          width: 55px;
        }

        @media (min-width: $container) {
          left: 0;
        }

        .guide__strip & {
          left: 0;
        }
      }

      .guide__channels-image {
        animation: shake 3s linear 5s infinite;
        transform-origin: 50% 100%;
      }
    }

    > span {
      bottom: -10px;
      display: none;
      height: 3px;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }
  }
}

.guide__channels-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 3px;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  &:after {
    left: 50%;
    margin: -15px;
    position: absolute;
    top: 50%;
  }

  &[layout=fixed] {
    .guide__channels-item--fixed & {
      padding-bottom: 0;
      position: relative;
      top: 5px;
    }
  }

  .guide__channels-item--fixed & {
    background-position: 50% 150%;
    background-size: 42px 37px;

    @media (min-width: $s-tablet) {
      background-size: 52px 47px;
    }
  }
}

.guide__channels-btn {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 21px;
  height: 65px;
  justify-content: center;
  position: absolute;
  top: 0;
  @include transition;
  width: 40px;
  z-index: 10;

  @media (min-width: $s-tablet) {
    height: 75px;
  }

  .no-touchevents &:hover {
    background-color: #000;
  }

  .no-touchevents .swiper-container:hover & {
    display: flex;
  }
}

.guide__channels-btn--prev {
  left: 0;

  &:after {
    @media (min-width: $s-tablet) {
      right: 100%;
    }
  }
}

.guide__channels-btn--next {
  right: 0;

  &:after {
    @media (min-width: $s-tablet) {
      left: 100%;
    }
  }

  .touchevents & {
    display: flex;
  }
}

.guide__header {
  background-color: #000;
  color: #fff;
  padding: 25px 0;
  text-align: center;

  @media (min-width: $s-tablet) {
    padding: 45px 0;
  }

  &.guide__header--chart {
    background-color: $tonightfilms;
    color: #000;
  }

  > div {
    position: relative;
  }

  strong {
    display: block;
    @include latobold;
    font-size: 13px;
    letter-spacing: 0.26px;
    margin-top: 15px;

    @media (min-width: $s-tablet) {
      font-size: 16px;
      letter-spacing: 0.32px;
      margin-top: 30px;
    }
  }

  small {
    align-items: center;
    color: #333;
    display: flex;
    @include latoregular;
    font-size: 12px;
    justify-content: center;
    letter-spacing: 0.24px;
    margin-top: 15px;
    text-align: center;

    @media (min-width: $s-tablet) {
      margin-top: 25px;
    }

    > * {
      margin-left: 5px;
    }
  }
}

.guide__header-back {
  align-items: center;
  color: #fff;
  display: flex;
  @include latoblack;
  font-size: 14px;
  left: 10px;
  position: absolute;
  top: -3px;
  z-index: 10;

  .no-touchevents &:hover {
    &:before {
      background-color: #fff;
      color: #000;
    }
  }

  @media (min-width: $s-tablet) {
    left: $container-padding;
    top: 3px;
  }

  @media (min-width: $l-tablet) {
    top: 10px;
  }

  @media (min-width: $medium) {
    top: 6px;
  }

  @media (min-width: $l-wide) {
    top: 12px;
  }

  &:before {
    align-items: center;
    border-radius: 50%;
    display: flex;
    font-size: 11px;
    flex-shrink: 0;
    height: 35px;
    justify-content: center;
    margin-right: 10px;
    @include transition;
    width: 35px;

    @media (min-width: $s-tablet) {
      border: solid 2px #fff;
      height: 40px;
      width: 40px;
    }

    @media (min-width: $l-tablet) {
      font-size: 12px;
      height: 45px;
      width: 45px;
    }
  }

  .guide__header--chart & {
    &:before {
      color: #000;

      @media (min-width: $s-tablet) {
        border-color: #000;
      }
    }

    .no-touchevents &:hover {
      &:before {
        background-color: #fff;
        border-color: #fff;
      }
    }
  }

  ~ h1, ~ strong {
    @media (max-width: ($s-tablet - 1)) {
      padding: 0 40px;
    }
  }
}

.guide__header-title {
  @include latoblack;
  font-size: 23px;
  position: relative;
  z-index: 5;

  @media (min-width: $s-tablet) {
    font-size: 40px;
    margin: 0 auto;
    max-width: 80%;
  }

  @media (min-width: $l-tablet) {
    font-size: 50px;
  }

  @media (min-width: $medium) {
    font-size: 60px;
  }
}

.guide__body {
  margin-top: 25px;

  @media (min-width: $s-tablet) {
    margin-top: 35px;
  }

  > div {
    display: flex;

    @media (max-width: ($l-tablet - 1)) {
      flex-direction: column;
    }
  }
}

.guide__content {
  @media (min-width: $l-tablet) {
    width: calc(100% - 340px);
  }
}

.guide__content-text {
  border-bottom: 1px solid $border;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 1.6;

  @media (max-width: ($s-tablet - 1)) {
    margin-left: -$container-padding;
    margin-right: -$container-padding;
    padding: 0 $container-padding 25px;
  }

  @media (min-width: $s-tablet) {
    padding: 0 25px 35px;
  }

  @media (min-width: $medium) {
    font-size: 18px;
    letter-spacing: 0.2px;
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    background-image: linear-gradient(#fff 50%, rgba(232, 0, 0, 0.6) 0);
    background-repeat: repeat-x;
    background-position: 0 22px;
    background-size: 1px 1px;
    color: $primary;
    text-decoration: none;
    position: relative;
    text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
    z-index: 2;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
    padding: 0;
  }
}

.guide__aside {
  flex-shrink: 0;

  @media (max-width: ($l-tablet - 1)) {
    display: none;
    margin-bottom: 35px;
    order: -1;
    text-align: center;
  }

  @media (max-width: ($s-tablet - 1)) {
    margin-bottom: 25px;
  }

  @media (min-width: $l-tablet) {
    margin-left: 40px;
    width: 300px;
  }
}

.guide__days {
  border-bottom: 1px solid $border;
  position: relative;

  @media (max-width: ($s-tablet - 1)) {
    margin-left: -$container-padding;
    margin-right: -$container-padding;
    padding: 20px calc(#{$container-padding} + 40px + 8px);
  }

  @media (min-width: $s-tablet) {
    padding: 20px 107.5px;
  }

  .guide__highlight + & {
    border-top: 1px solid $border;
  }
}

.guide__days-slider {
  &.swiper-container {
    opacity: 0;
    position: static;
    transition: opacity .3s ease, visibility .3s ease;
    visibility: hidden;
  }

  &.swiper-container-initialized {
    opacity: 1;
    visibility: visible;
  }

  .swiper-button-prev,
  .swiper-button-next {
    align-items: center;
    background: #fff;
    border: 1px solid $border;
    border-radius: 5px;
    color: $primary;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 40px;
    justify-content: center;
    margin-top: 0;
    position: absolute;
    top: 20px;
    transition: all .3s ease;
    width: 40px;
    z-index: 10;

    @media (min-width: $s-tablet) {
      font-size: 21px;
      height: 55px;
      width: 55px;
    }

    &:focus {
      outline: none;
    }

    .no-touchevents &:hover {
      background-color: $primary;
      border-color: $primary;
      color: #fff;
    }
  }

  .swiper-button-prev {
    left: $container-padding;

    @media (min-width: $s-tablet) {
      left: 25px;
    }
  }

  .swiper-button-next {
    right: $container-padding;

    @media (min-width: $s-tablet) {
      right: 25px;
    }
  }
}

.guide__days-item {
  margin-left: 0;
  width: calc(100%/5);

  > a {
    color: #666;
    display: block;
    @include latobold;
    margin: 0 auto;
    max-width: 100%;
    text-align: center;
    width: 40px;

    @media (min-width: $s-tablet) {
      width: 55px;
    }

    &:not(.is-active) {
      .no-touchevents &:hover {
        time {
          border-color: $primary;
          color: $primary;
        }
      }
    }

    &.is-active {
      time {
        background-color: $primary;
        border-color: $primary;
        color: #fff;
      }
    }
  }

  time {
    align-items: center;
    border: 1px solid $border;
    border-radius: 5px;
    display: flex;
    font-size: 16px;
    height: 40px;
    justify-content: center;
    letter-spacing: 0.32px;
    transition: border-color .3s ease, color .3s ease;
    width: 100%;

    @media (min-width: $s-tablet) {
      font-size: 22px;
      height: 55px;
      letter-spacing: 0.45px;
    }
  }

  small {
    display: block;
    font-size: 12px;
    margin-top: 3px;

    @media (min-width: $s-tablet) {
      font-size: 16px;
      margin-top: 7px;
    }
  }
}

.guide__slots {
  @media (max-width: ($s-tablet - 1)) {
    height: 91px;
    margin-left: -$container-padding;
    margin-right: -$container-padding;
  }

  @media (min-width: $s-tablet) {
    height: 116px;
  }

  > div {
    border-bottom: 1px solid $border;
  }

  ul {
    align-items: baseline;
    display: flex;
    justify-content: space-around;
    padding: 20px $container-padding;

    @media (min-width: $s-tablet) {
      padding: 20px 25px;
    }

    > li {
      @media (min-width: $s-tablet) {
        padding: 0 10px;
      }
    }
  }

  a {
    align-items: center;
    color: #666;
    display: flex;
    flex-direction: column;
    @include latoregular;
    font-size: 12px;
    letter-spacing: 0.24px;
    text-align: center;

    @media (min-width: $s-tablet) {
      font-size: 14px;
      letter-spacing: 0.28px;
    }

    @media (min-width: $medium) {
      font-size: 16px;
      letter-spacing: 0.32px;
    }

    &:before {
      display: block;
      transition: color .3s ease;
    }

    &.is-active,
    .no-touchevents &:hover {
      &:before {
        color: $primary;
      }
    }

    &.icon-clock {
      &:before {
        font-size: 21px;

        @media (min-width: $s-tablet) {
          font-size: 32px;
        }
      }
    }

    &.icon-morning {
      &:before {
        font-size: 25px;

        @media (min-width: $s-tablet) {
          font-size: 39px;
        }
      }
    }

    &.icon-coffee {
      &:before {
        font-size: 27px;

        @media (min-width: $s-tablet) {
          font-size: 42px;
        }
      }
    }

    &.icon-moon {
      &:before {
        font-size: 21px;

        @media (min-width: $s-tablet) {
          font-size: 32px;
        }
      }
    }

    &.icon-moon-full {
      &:before {
        font-size: 22px;

        @media (min-width: $s-tablet) {
          font-size: 35px;
        }
      }
    }

    &.icon-live {
      &:before {
        font-size: 18px;

        @media (min-width: $s-tablet) {
          font-size: 28px;
        }
      }
    }

    span {
      display: block;
      margin-top: 10px;

      @media (min-width: $s-tablet) {
        margin-top: 15px;
      }
    }
  }
}

.guide__sections {
  @media (min-width: $s-tablet) {
    padding: 25px $container-padding 0;
  }

  + .guide__sections {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;
  }
}

.guide__loading {
  @include loading;
  margin: 0;
}

.guide__section {
  margin-bottom: 40px;
  position: relative;

  @media (min-width: $s-tablet) {
    margin-bottom: 45px;
  }

  &:focus {
    outline: none;
  }
}

.guide__section-header {
  display: block;
  height: 70px;

  &[href] {
    @include transition(opacity);

    .no-touchevents &:hover {
      opacity: .9;
    }
  }

  .guide & {
    position: sticky;
    top: 62px; //65px - 3px border-radius
    z-index: 13;

    @media (min-width: $s-tablet) {
      top: 72px; //75px - 3px border-radius
    }

    .app__header--sticky.is-active ~ .app__body & {
      top: calc(62px + 55px);

      @media (min-width: $s-tablet) {
        top: calc(72px + 55px);
      }

      @media (min-width: $l-tablet) {
        top: calc(72px + 95px);
      }

      @media (min-width: 1366px) {
        top: calc(72px + 48px);
      }
    }
  }

  .guide__strip-slider & {
    > div {
      border-radius: 5px;
      margin: 0;
    }
  }

  .guide__sections + .guide__sections & {
    padding: 0 $container-padding;
  }

  > div {
    align-items: center;
    background-color: #000;
    color: #fff;
    display: flex;
    @include latoregular;
    height: 100%;
    margin-left: -$container-padding;
    margin-right: -$container-padding;
    padding: 0 $container-padding;

    @media (min-width: $s-tablet) {
      border-radius: 5px;
    }

    > div {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  h2 {
    font-size: 22px;
    letter-spacing: 0.4px;
    line-height: 1;

    small {
      display: block;
      font-size: 12px;
      letter-spacing: 0.24px;
      margin-bottom: 2px;
    }
  }

  strong {
    align-self: flex-end;
    flex-shrink: 0;
    @include latobold;
    font-size: 13px;
    letter-spacing: 0.26px;
    margin-bottom: 2px;
    margin-left: 5px;
    margin-top: 22px;
  }
}

.guide__section-shows {
  padding-top: 20px;

  .guide__sections + .guide__sections & {
    padding-left: $container-padding;
    padding-right: $container-padding;
  }
}

.guide__section-shows-slot {
  &:not(:first-child) {
    margin-top: 25px;

    @media (min-width: $s-tablet) {
      margin-top: 40px;
    }
  }

  h3 {
    align-items: center;
    background-color: #666;
    border-radius: 5px;
    color: #fff;
    display: flex;
    @include latoregular;
    font-size: 16px;
    height: 50px;
    letter-spacing: 0.32px;
    margin-bottom: 20px;
    padding: 0 20px;

    &:before {
      color: rgba(255,255,255,.5);
      margin-right: 20px;
    }

    &.icon-morning {
      &:before {
        font-size: 25px;
      }
    }

    &.icon-coffee {
      &:before {
        font-size: 27px;
      }
    }

    &.icon-moon {
      &:before {
        font-size: 21px;
      }
    }
  }
}

.guide__section-media {
  height: 0;
  margin-top: 25px;
  padding-bottom: 56.25%;
  position: relative;

  @media (min-width: $s-tablet) {
    margin-top: 40px;
  }

  .guide__slots + & {
    margin-top: 0;

    @media (max-width: $s-tablet - 1) {
      margin-bottom: 25px;
    }
  }

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.guide__show-list {
  &.guide__show-list--xl {
    margin-top: 30px;

    .movie__section & {
      @media (max-width: ($s-tablet - 1)) {
        margin-top: 15px;
      }
    }

    .guide__show-item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &.guide__show-list--chart {
    .guide__show-item {
      .guide__show-button {
        border-radius: 5px;
        padding: 10px;

        &.is-active, &[aria-expanded=true] {
          border-radius: 5px 5px 0 0;
        }
      }
    }
  }
}

.guide__show-item {
  @include latoregular;
  font-size: 14px;
  letter-spacing: 0.28px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .guide__strip-slider &,
  .post__content & {
    align-items: center;
    display: flex;
    padding: 0 15px;
  }

  .guide__strip-slider & {
    color: #fff;
  }
}

.guide__show-button {
  background-color: transparent;
  border: none;
  font-weight: 400;
  padding: 0 15px;
  text-align: left;
  width: 100%;

  &:not(.is-active):not([aria-expanded=true]) {
    position: relative;

    &:after {
      background-color: #fff;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;

      .guide__show-list--chart & {
        border-radius: 3px;
      }
    }
  }

  &.is-active, &[aria-expanded=true] {
    background-color: $grey;
    border-radius: 5px 5px 0 0;
    padding: 15px;

    > div, .guide__show-caption {
      &:before {
        transform: rotate(180deg);

        .guide__show-list--xl & {
          margin-top: -8px;
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  &:focus {
    outline: none;
  }

  @at-root {
    button#{&} {
      cursor: pointer;
    }
  }

  .movie__section &,
  .post__guide & {
    cursor: default;
    padding: 0;
  }

  > div {
    padding-right: 25px;
    position: relative;

    &:before {
      color: #ccc;
      font-size: 14px;
      position: absolute;
      right: 0;
      top: 2px;
      z-index: 5;

      .guide__show-list--xl & {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .guide__show-list--xl & {
      align-items: center;
      display: flex;
    }
  }
}

.guide__show-caption {
  align-items: flex-start;
  display: flex;
  flex-shrink: 0;
  position: relative;
  width: 100%;
  z-index: 5;

  &:before {
    color: #e9e9e9;
    flex-shrink: 0;
    font-size: 14px;
    margin-left: auto;
    margin-right: -10px;
    order: 3;
    padding: 0 10px;
    position: relative;
    top: 2px;
  }

  .guide__show-list--xl & {
    display: block;
    padding-right: 45px;
    position: relative;
    width: calc(100% - 80px);

    &:before {
      margin: -7px 0 0;
      padding: 0;
      position: absolute;
      right: 15px;
      top: 50%;
    }
  }
}

.guide__show-cover {
  align-self: flex-start;
  border: solid 1px $border;
  border-radius: 5px;
  display: block;
  flex-shrink: 0;
  height: 60px;
  margin-right: 20px;
  overflow: hidden;
  position: relative;
  @include transition(border-color);
  width: 60px;
  z-index: 5;

  @at-root {
    a#{&} {
      .no-touchevents &:hover {
        border-color: $primary;
      }
    }
  }

  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.guide__show-maininfo {
  flex-shrink: 0;
  margin-top: 2px;
  margin-right: 15px;

  .guide__show-list--xl & {
    color: #333;
    margin-bottom: 5px;

    @media (max-width: ($s-tablet - 1)) {
      font-size: 12px;
      letter-spacing: .24px;
    }
  }

  time {
    @include latoblack;

    &:not(:only-child) {
      &:after {
        content: "|";
        margin: 0 10px;
      }
    }

    .guide__show-list--xl & {
      @include latoregular;
    }
  }

  span {
    display: inline-block;
    white-space: nowrap;
  }
}

.guide__show-title {
  display: block;
  line-height: 1.3;

  @at-root {
    a#{&} {
      color: $primary;
      text-decoration: underline;
    }
  }

  .guide__show-item--live &,
  .guide__show-item--series &,
  .guide__show-item--movie &,
  .guide__show-item--sport & {
    &:before {
      border: 1px solid #ccc;
      border-radius: 3px;
      color: #666;
      display: inline-block;
      font-size: 10px;
      font-weight: 900;
      letter-spacing: 0.1px;
      margin-right: 7px;
      padding: 1px 7px;
      pointer-events: none;
      position: relative;
      text-transform: uppercase;
      top: -1px;

      @media (min-width: $s-tablet) {
        padding: 1px 10px;
      }
    }
  }

  .guide__show-item--live & {
    &:before {
      background-color: $primary;
      border: none;
      color: #fff;
      content: "LIVE";
    }
  }

  .guide__show-item--series & {
    &:before {
      content: "SERIE TV";
    }
  }

  .guide__show-item--movie & {
    &:before {
      content: "FILM";
    }
  }

  .guide__show-item--sport & {
    &:before {
      content: "SPORT";
    }
  }

  .guide__show-button.is-active &,
  .guide__show-button[aria-expanded=true] & {
    @include latobold;
  }

  .guide__show-list--xl & {
    @include latobold;
    font-size: 16px;
    letter-spacing: 0.32px;
    line-height: 1.15;
  }
}

.guide__show-review {
  margin-top: 10px;

  strong {
    color: #333;
    @include latobold;
    font-size: 14px;
    letter-spacing: 0.28px;

    &:before {
      color: $tonightfilms;
      margin-right: 10px;
    }
  }
}

.guide__show-details {
  display: none;
  padding-bottom: 3px;

  &.is-open,
  .guide__show-button[aria-expanded=true] + & {
    background-color: $grey;
    display: block;
  }
}

.guide__show-media {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  img, iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.guide__show-info {
  line-height: 1.47;
  padding: 20px 15px;

  &:first-child {
    padding-top: 0;
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.guide__show-meta {
  line-height: 1.47;
  padding: 0 15px 20px;

  > ul {
    border-top: 1px solid $border;
    padding-top: 20px;
  }
}

.guide__show-next {
  padding: 0 15px 20px;

  span {
    border-top: 1px solid $border;
    display: block;
    margin-bottom: 10px;
    padding-top: 20px;
  }

  ul {
    > li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  time {
    color: #666;
  }
}

.guide__highlight {
  margin-top: 20px;

  .guide__content > & {
    margin: 0 0 20px;

    @media (min-width: $s-tablet) {
      margin: 0 0 40px;
      padding: 0 25px;
    }

    &:not(:first-child) {
      margin-top: 20px;

      @media (min-width: $s-tablet) {
        margin-top: 40px;
      }
    }
  }

  .guide__sections + .guide__sections & {
    padding-left: $container-padding;
    padding-right: $container-padding;
  }

  > strong, > a > strong {
    display: block;
    @include latobold;
    font-size: 12px;
    letter-spacing: 0.24px;
    padding-bottom: 10px;
  }
}

.guide__highlight-show {
  border: solid 1px #e2e2e2;
  border-radius: 5px;
  @include latoregular;
}

.guide__highlight-show-inner {
  background-color: #f9f9f9;
  display: flex;
  padding: 20px;
}

.guide__highlight-show-image {
  flex-shrink: 0;
  margin-right: 10px;
  width: 65px;

  > div {
    height: 0;
    padding-bottom: 150%;
    position: relative;
  }

  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.guide__highlight-show-caption {
  font-size: 14px;
  letter-spacing: 0.28px;

  > * {
    display: block;
  }

  time {
    margin-bottom: 2px;
  }

  span:first-of-type {
    font-size: 12px;
    letter-spacing: 0.24px;
    margin-bottom: 10px;
  }

  span:last-of-type {
    margin-top: 10px;
  }

  strong {
    @include latobold;
    font-size: 16px;
    letter-spacing: 0.32px;
    margin-bottom: 10px;
  }
}

.guide__show-channels {
  display: flex;
  font-size: 12px;
  letter-spacing: 0.24px;
  overflow: hidden;
  padding: 10px 20px;

  .guide__show-details & {
    background-color: #fff;
    margin: 0 3px;
    width: calc(100% - 6px);
  }

  span {
    display: block;
    flex-shrink: 0;
    margin-right: 15px;
  }

  a {
    @include latobold;
    line-height: 1.3;
    transition: color .3s ease;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 10px;
    }

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

.guide__network {
  border-radius: 5px;
  color: #fff;
  display: flex;
  padding: 20px;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  &.guide__network--netflix {
    background-color: $netflix;
  }

  &.guide__network--apple-tv-plus {
    background-color: $apple-tv-plus;
  }

  &.guide__network--amazon-prime-video {
    background-color: $amazon-prime-video;
  }

  &.guide__network--disney-plus {
    background-color: $disney-plus;
  }

  &.guide__network--now-tv {
    background-color: $now-tv;
  }

  &.guide__network--timvision {
    background-color: $timvision;
  }

  &.guide__network--infinity {
    background-color: $infinity;
  }

  &.guide__network--sky-go {
    background-color: $sky-go;
  }

  &.guide__network--rai-play {
    background-color: $rai-play;
  }

  &.guide__network--apple-itunes {
    background-color: $apple-itunes;
  }

  &.guide__network--google-play-movies {
    background-color: $google-play-movies;
  }

  &.guide__network--microsoft-store {
    background-color: $microsoft-store;
  }

  &.guide__network--playstation {
    background-color: $playstation;
  }

  &.guide__network--rakuten-tv {
    background-color: $rakuten-tv;
  }

  &.guide__network--youtube-premium {
    background-color: $youtube-premium;
  }

  &.guide__network--mubi {
    background-color: $mubi;
  }

  &.guide__network--mediaset-play {
    background-color: $mediaset-play;
  }

  &.guide__network--chili {
    background-color: $chili;
  }

  &.guide__network--discovery-plus {
    background-color: $discovery-plus;
  }

  &.guide__network--vvvvid {
    background-color: $vvvvid;
  }

  &.guide__network--starz-play-amazon-channel {
    background-color: $starz-play-amazon;
  }

  p {
    @include latoregular;
    font-size: 14px;
    letter-spacing: 0.28px;
    margin: 5px 0 0;

    @media (min-width: $s-tablet) {
      font-size: 18px;
      letter-spacing: 0.36px;
      margin: 20px 0 0;
    }
  }
}

.guide__network-logo {
  flex-shrink: 0;
  margin-right: 15px;
  width: 65px;

  @media (min-width: $s-tablet) {
    margin-right: 40px;
  }

  > div {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
  }

  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.guide__network-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  @media (max-width: ($s-tablet - 1)) {
    margin-left: calc((65px + 15px) *-1);
    margin-top: 40px;
    width: calc(100% + 65px + 15px);
  }

  > div {
    flex: 1;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  a {
    align-items: center;
    background-color: #000;
    border-radius: 5px;
    display: flex;
    @include latobold;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.28px;
    justify-content: center;
    text-align: center;
    transition: all .3s ease;

    .no-touchevents &:hover {
      background-color: $primary;
      color: #fff;
    }
  }
}

.guide__sections-more {
  display: flex;
  justify-content: center;
  margin-top: 15px;

  @media (min-width: $s-tablet) {
    padding: 0 25px;
  }

  form {
    width: 100%;
  }

  button {
    align-items: center;
    animation: pulseButton 1.5s infinite;
    background-color: $primary;
    border-radius: 5px;
    box-shadow: 0 0 0 0 rgba(232,0,0,.5);
    color: #fff;
    display: flex;
    @include latobold;
    font-size: 22px;
    height: 80px;
    justify-content: center;
    letter-spacing: 0.5px;
    padding: 0 20px;
    position: relative;
    text-align: center;
    transition: background-color .3s ease;
    width: 100%;

    &:before {
      left: 25px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .no-touchevents &:hover {
      background-color: $primaryDarken;
    }
  }
}

.guide__filters {
  background-color: rgba(0,0,0,.4);
  @include latoregular;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  max-width: 100%;
  position: fixed;
  top: 0;
  width: 100%;

  &:not(#filters) {
    backface-visibility: hidden;
    opacity: 0;
    transition: opacity .3s ease .1s,visibility .3s ease .2s,z-index 0s ease .3s;
    visibility: hidden;
    z-index: -1000000000; //sennò il banner pubblicitario va sopra
    &.is-open {
      backface-visibility: visible;
      opacity: 1;
      transition: opacity .3s ease 0s, visibility .3s ease 0s, z-index ease 0s;
      visibility: visible;
      z-index: 1000000000; //sennò il banner pubblicitario va sopra
      .guide__filters-inner {
        transform: translateX(0);
        transition: transform .3s ease .2s;
      }
    }

    .guide__filters-inner {
      transform: translateX(-100%);
      transition: transform .3s ease 0s;
    }
  }
}

.guide__filters-close {
  align-items: center;
  background-color: #fff;
  border: solid 1px #000;
  border-radius: 50%;
  display: flex;
  font-size: 10px;
  height: 35px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 20px;
  transform: translateX(-50%);
  transition: color .3s ease;
  width: 35px;
  z-index: 10;

  .no-touchevents &:hover {
    color: $primary;
  }
}

.guide__filters-inner {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  padding: 55px 0 35px;
  position: relative;

  @media (min-width: $s-tablet) {
    max-width: 360px;
  }
}

.guide__filters-chart {
  margin-top: 35px;

  > a {
    align-items: center;
    background-color: $tonightfilms;
    border-radius: 5px;
    color: #000000;
    display: flex;
    @include latoregular;
    font-size: 16px;
    height: 50px;
    justify-content: center;
    letter-spacing: 0.32px;
    text-align: center;
    transition: background-color .3s ease, color .3s ease;
    width: 100%;

    &:before {
      font-size: 21px;
      margin-right: 25px;
    }

    &.is-active {
      color: $primary;
    }

    .no-touchevents &:hover {
      background-color: $tonightfilmsDarken;
    }
  }
}

.guide__filters-genres,
.guide__filters-channels {
  margin-top: 35px;

  > strong {
    display: block;
    @include latobold;
    font-size: 14px;
    letter-spacing: 0.28px;
    text-align: center;
  }
}

.guide__filters-genres {
  background-color: #e9e9e9;
  border-radius: 5px;
  padding: 20px 15px;
  text-align: center;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 15px -7.5px -5px;

    > li {
      padding: 5px 7.5px;
      width: 50%;

      &.is-active {
        a {
          border: 1px solid $primary;
          color: $primary;
        }
      }
    }
  }

  a {
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    color: #333333;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    letter-spacing: 0.28px;
    line-height: 1.1;
    padding: 0 7px;
    text-align: center;
    transition: color .3s ease;

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

.guide__filters-channels-tabs {
  display: flex;
  margin-top: 25px;

  > button {
    color: rgba(51, 51, 51, 0.55);
    @include latoregular;
    font-size: 16px;
    letter-spacing: 0.32px;
    text-align: center;
    transition: color .3s ease;
    width: calc(100% / 3);

    &[selected] {
      outline: none;
    }

    &:after {
      background-color: #666;
      border-radius: 2px;
      content: "";
      display: block;
      height: 3px;
      margin-top: 8px;
      opacity: .4;
      transition: opacity .3s ease;
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }

    &[selected],
    .no-touchevents &:hover {
      color: #333;

      &:after {
        opacity: 1;
      }
    }

    &.--free {
      &:after {
        background-color: #58ab21;
      }
    }

    &.--premium {
      &:after {
        background-color: #ee5b2f;
      }
    }

    &.--sky {
      &:after {
        background-color: #337ab7;
      }
    }
  }
}

.guide__filters-channels-list {
  > div {
    display: none;

    &[selected] {
      display: block;
      outline: none;
    }
  }

  ul {
    margin-top: 20px;

    > li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }

      &.is-active {
        a {
          border: 1px solid $primary;
          color: $primary;
        }
      }
    }
  }

  a {
    align-items: center;
    border: solid 1px $border;
    border-radius: 5px;
    color: #333;
    display: flex;
    height: 60px;
    overflow: hidden;
    padding-right: 15px;
    transition: color .3s ease;

    .no-touchevents &:hover {
      color: $primary;
    }

    > div {
      display: block;
      height: 60px;
      margin-right: 20px;
      position: relative;
      width: 60px;

      img {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    small {
      flex-shrink: 0;
      font-size: 12px;
      letter-spacing: 0.24px;
      margin-left: auto;
    }

    span {
      font-size: 16px;
      letter-spacing: 0.32px;
    }
  }
}

.guide__categories {
  margin: 0 0 30px;

  @media (min-width: $s-tablet) {
    margin: 0 0 50px;
  }
}

.guide__categories-list {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;

  @media (min-width: $s-tablet) {
    margin: -14px;
  }
}

.guide__categories-item {
  flex: 0 0 auto;
  padding: 10px;
  width: calc(100% / 2);

  @media (min-width: $s-tablet) {
    padding: 14px;
    width: calc(100% / 3);
  }

  .guide__categories--bgcolor &,
  body[amp-x-tv-guide-home-buttons="colored"] &,
  body[amp-x-tv-guide-evening-buttons="colored"] & {
    &.guide__categories-item--today {
      > a, > button {
        background-color: #3cb318;
      }
    }

    &.guide__categories-item--allchannels {
      > a, > button {
        background-color: #c4a426;
      }
    }

    &.guide__categories-item--tonight {
      > a, > button {
        background-color: #c11adf;
      }
    }

    &.guide__categories-item--tonightfilms {
      > a, > button {
        background-color: $tonightfilms;
      }
    }

    &.guide__categories-item--sport {
      > a, > button {
        background-color: $sport;
      }
    }

    &.guide__categories-item--live {
      > a, > button {
        background-color: #df4444;
      }
    }
  }

  > a, > button {
    display: block;
    width: 100%;

    .guide__categories:not(.guide__categories--bgcolor):not(.guide__categories--bggrey) & {
      .no-touchevents &:hover {
        .guide__categories-image {
          transform: translate3d(0, 0, 0) scale(1.07);
        }

        .guide__categories-title {
          color: $primary;
        }
      }
    }

    .guide__categories--bgcolor &,
    .guide__categories--bggrey &,
    body[amp-x-tv-guide-home-buttons="colored"] &,
    body[amp-x-tv-guide-evening-buttons="colored"] &,
    body[amp-x-tv-guide-home-buttons="neutral"] &,
    body[amp-x-tv-guide-evening-buttons="neutral"] & {
      align-items: center;
      backface-visibility: hidden;
      border-radius: 5px;
      display: flex;
      height: 80px;
      padding: 0 20px;
      justify-content: center;
      transform: translateZ(0);
      @include transition(transform, 1s);

      @media (min-width: $s-tablet) {
        height: 90px;
      }

      .no-touchevents &:hover {
        transform: translate3d(0, 0, 0) scale(1.07);
      }
    }

    .guide__categories--bggrey &,
    body[amp-x-tv-guide-home-buttons="neutral"] &,
    body[amp-x-tv-guide-evening-buttons="neutral"] & {
      background-color: #f7f7f7;
    }
  }
}

.guide__categories-image {
  backface-visibility: hidden;
  background-color: $grey;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px;
  height: 0;
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
  transform: translate3d(0, 0, 0);
  @include transition(transform, 1s);

  &:before {
    color: #fff;
    font-size: 25px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: $s-tablet) {
      font-size: 35px;
    }
  }

  &.loading {
    &:after {
      height: 30px;
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      width: 30px;
      z-index: 10;
    }
  }

  .guide__categories--bgcolor &,
  .guide__categories--bggrey &,
  body[amp-x-tv-guide-home-buttons="colored"] &,
  body[amp-x-tv-guide-evening-buttons="colored"] &,
  body[amp-x-tv-guide-home-buttons="neutral"] &,
  body[amp-x-tv-guide-evening-buttons="neutral"] & {
    display: none;
  }
}

.guide__categories-title {
  display: block;
  @include latobold;
  font-size: 14px;
  line-height: 1.31;
  margin-top: 10px;
  text-align: center;
  @include transition(color);

  @media (min-width: $s-tablet) {
    font-size: 16px;
  }

  .guide__categories--bgcolor &,
  .guide__categories--bggrey &,
  body[amp-x-tv-guide-home-buttons="colored"] &,
  body[amp-x-tv-guide-evening-buttons="colored"] &,
  body[amp-x-tv-guide-home-buttons="neutral"] &,
  body[amp-x-tv-guide-evening-buttons="neutral"] & {
    font-size: 15px;
    line-height: 1;
    margin-top: 0;

    @media (min-width: $s-tablet) {
      font-size: 17px;
    }
  }

  .guide__categories--bgcolor &,
  body[amp-x-tv-guide-home-buttons="colored"] &,
  body[amp-x-tv-guide-evening-buttons="colored"] & {
    color: #fff;
  }

  .guide__categories--bgcolor .guide__categories-item--tonightfilms &,
  body[amp-x-tv-guide-home-buttons="colored"] .guide__categories-item--tonightfilms &,
  body[amp-x-tv-guide-evening-buttons="colored"] .guide__categories-item--tonightfilms & {
    color: #000;
  }
}
