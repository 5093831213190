@import "font";
@import "fontello";
@import "utils";

@keyframes spin {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}

html {
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  min-width: 320px;
  margin: 0;
  font-family: "PT Serif", serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.body--error {
    background-color: #000;
  }
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

a[href],
button {
  touch-action: manipulation;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

// Firefox puts an inner border on inputs and buttons
input::-moz-focus-inner {
  border: 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  -ms-interpolation-mode: bicubic;
}

.image-fix {
  display: block;
  width: 100%;
  height: auto;
}

textarea {
  box-sizing: border-box;
  resize: vertical;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  font-family: "Lato", sans-serif;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

//Icone importate da fontello
[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="icon-"]:after,
[class*=" icon-"]:after {
  width: auto;
  margin: 0;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//Contenitore principale del sito
.container {
  margin: 0 auto;
  max-width: $container;
  padding: 0 $container-padding;
  width: 100%;
}

//Adv inside content
.adv__wrapper {
  margin: 30px 0;
  text-align: center;

  @media (min-width: $s-tablet) {
    margin: 50px 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

//Bottone generico
.button {
  display: inline-block;

  &.button--outlined {
    > a, > button {
      background-color: transparent;
      border: 1px solid #ccc;
      color: #fff;

      .no-touchevents &:hover {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }

  &.button--search {
    font-size: 14px;

    @media (min-width: $l-mobile) {
      flex: 0 0 35px;
    }

    @media (min-width: $s-tablet) {
      flex: 0 0 40px;
      font-size: 16px;
    }

    > a {
      @media (min-width: $l-mobile) {
        min-width: 0;
        padding: 0;
        width: 35px;
      }

      @media (min-width: $s-tablet) {
        width: 40px;
      }
    }
  }

  &.button--tonightfilms {
    > a, > button {
      background-color: $tonightfilms;
      color: #000;

      .no-touchevents &:hover {
        background-color: darken($tonightfilms, 10%);
      }
    }
  }

  &.is-loading {
    background-color: #fff;
    border-radius: 5px;

    > a,
    > button {
      cursor: default;
      pointer-events: none;

      &:after {
        animation: spin 2s infinite linear;
        color: #fff;
        content: "\e834";
        flex-shrink: 0;
        font-family: fontello;
        font-size: 20px;

        @media (min-width: $medium) {
          font-size: 1.05vw;
        }
      }

      span {
        display: none;
      }
    }
  }

  .section__text--static & {
    margin: 1em 0;
  }

  .movie__intro-poster & {
    margin: 35px 0;

    @media (max-width: ($l-tablet - 1)) {
      display: none;
    }
  }

  .guide__show-details & {
    padding: 0 15px 20px;
    width: 100%;

    @media (min-width: $s-tablet) {
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      width: 230px;
    }
  }

  .guide__strip-filters & {
    width: 100%;
  }

  .star__card & {
    margin-top: auto;
    padding-top: 10px;
    width: 100%;

    @media (min-width: $s-tablet) {
      padding-top: 15px;
    }
  }

  .stars__section-footer & {
    min-width: 190px;
    padding: 0 15px;
    width: calc(100%/2);

    @media (min-width: $l-mobile) {
      width: calc(100%/3);
    }

    @media (min-width: $s-tablet) {
      width: calc(100%/4);
    }

    @media (min-width: $l-tablet) {
      padding: 0 20px;
    }
  }

  .post__star & {
    margin-top: 30px;
  }

  .post__footer-newsletter & {
    flex-shrink: 0;

    > a,
    > button {
      text-transform: none;
    }
  }

  > a,
  > button {
    align-items: center;
    background-color: $primary;
    border-radius: 5px;
    color: #fff;
    display: flex;
    @include latoblack;
    font-size: 12px;
    height: 35px;
    justify-content: center;
    letter-spacing: 0.6px;
    padding: 0 15px;
    text-align: center;
    text-transform: uppercase;
    @include transition;

    @media (min-width: $s-tablet) {
      height: 40px;
      min-width: 145px;
    }

    .no-touchevents &:hover {
      background-color: $primaryDarken;
    }

    .movies__search-box & {
      min-width: 105px;

      @media (min-width: $s-tablet) {
        margin: 0 auto;
        min-width: 145px;
      }
    }

    .guide__strip-buttons & {
      white-space: nowrap;

      @media (max-width: ($s-tablet - 1)) {
        font-size: 11px;
        height: 30px;
        padding: 0 5px;
      }

      @media (min-width: $s-tablet) {
        min-width: 80px;
      }
    }

    .guide__strip-filters & {
      @include latoregular;
      font-size: 14px;
      letter-spacing: 0.28px;
      justify-content: flex-start;
      text-align: left;
      text-transform: none;
      width: 100%;

      @media (min-width: $medium) {
        min-width: 160px;
      }

      &:before {
        font-size: 10px;
        right: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include transition(color);
      }

      &.is-active,
      .no-touchevents &:hover {
        background-color: $primary;
        border-color: $primary;

        &:before {
          color: #fff;
        }

        span {
          &:before {
            color: #fff;
          }
        }
      }

      .body--withskin & {
        @media (min-width: $medium) {
          min-width: 0;
          padding-right: 30px;
        }
      }

      span {
        align-items: center;
        display: flex;

        &:before {
          color: $primary;
          margin-right: 7px;
          @include transition(color);

          @media (min-width: $s-tablet) {
            margin-right: 10px;
          }

          .body--withskin & {
            display: none;
          }
        }

        &.icon-morning {
          &:before {
            font-size: 16px;

            @media (min-width: $s-tablet) {
              font-size: 19px;
            }
          }
        }

        &.icon-coffee {
          &:before {
            font-size: 19px;

            @media (min-width: $s-tablet) {
              font-size: 22px;
            }
          }
        }

        &.icon-moon {
          &:before {
            font-size: 18px;

            @media (min-width: $s-tablet) {
              font-size: 21px;
            }
          }
        }

        &.icon-moon-full {
          &:before {
            font-size: 16px;

            @media (min-width: $s-tablet) {
              font-size: 19px;
            }
          }
        }
      }
    }

    .guide__strip-button & {
      min-width: 230px;
      text-transform: none;
    }

    .guide__show-details & {
      text-transform: none;
    }

    .stars__section .star__card &,
    .stars__strip-slider & {
      min-width: 0;
      width: 100%;
    }

    .stars__section .star__card & {
      border-color: #000;
      color: #000;

      .no-touchevents &:hover {
        color: #fff;
      }
    }

    .stars__section-footer & {
      width: 100%;
    }

    .stars__strip-footer & {
      min-width: 175px;

      @media (min-width: $s-tablet) {
        min-width: 210px;
      }
    }

    .post__star & {
      min-width: 175px;
    }
  }
}
