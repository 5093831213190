@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?49569903');
  src: url('../font/fontello.eot?49569903#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?49569903') format('woff2'),
       url('../font/fontello.woff?49569903') format('woff'),
       url('../font/fontello.ttf?49569903') format('truetype'),
       url('../font/fontello.svg?49569903#fontello') format('svg');
  font-display: block;
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?49569903#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-book:before { content: '\e800'; } /* '' */
.icon-angle-right:before { content: '\e801'; } /* '' */
.icon-outfit:before { content: '\e802'; } /* '' */
.icon-facebook:before { content: '\e803'; } /* '' */
.icon-twitter:before { content: '\e804'; } /* '' */
.icon-whatsapp:before { content: '\e805'; } /* '' */
.icon-website:before { content: '\e806'; } /* '' */
.icon-right:before { content: '\e807'; } /* '' */
.icon-anchor:before { content: '\e808'; } /* '' */
.icon-info:before { content: '\e809'; } /* '' */
.icon-live:before { content: '\e80a'; } /* '' */
.icon-fullscreen-exit:before { content: '\e80b'; } /* '' */
.icon-fullscreen:before { content: '\e80c'; } /* '' */
.icon-msn:before { content: '\e80d'; } /* '' */
.icon-star-drops:before { content: '\e80e'; } /* '' */
.icon-star:before { content: '\e80f'; } /* '' */
.icon-menu:before { content: '\e810'; } /* '' */
.icon-linkedin:before { content: '\e811'; } /* '' */
.icon-thumb:before { content: '\e812'; } /* '' */
.icon-thumb-full:before { content: '\e813'; } /* '' */
.icon-expand:before { content: '\e816'; } /* '' */
.icon-gift:before { content: '\e81c'; } /* '' */
.icon-arrow-down:before { content: '\e820'; } /* '' */
.icon-search:before { content: '\e821'; } /* '' */
.icon-x:before { content: '\e826'; } /* '' */
.icon-chart:before { content: '\e82d'; } /* '' */
.icon-tik-tok:before { content: '\e82e'; } /* '' */
.icon-moon-full:before { content: '\e82f'; } /* '' */
.icon-moon:before { content: '\e830'; } /* '' */
.icon-coffee:before { content: '\e831'; } /* '' */
.icon-morning:before { content: '\e832'; } /* '' */
.icon-clock:before { content: '\e833'; } /* '' */
.icon-refresh:before { content: '\e834'; } /* '' */
.icon-flipboard:before { content: '\e837'; } /* '' */
.icon-pinterest:before { content: '\e839'; } /* '' */
.icon-instagram:before { content: '\e83e'; } /* '' */
.icon-down:before { content: '\e840'; } /* '' */
.icon-shining:before { content: '\e851'; } /* '' */
.icon-comment:before { content: '\e85f'; } /* '' */
.icon-play:before { content: '\e860'; } /* '' */
.icon-angle-left:before { content: '\e866'; } /* '' */
.icon-camera:before { content: '\e869'; } /* '' */
.icon-share:before { content: '\e86a'; } /* '' */
.icon-gallery:before { content: '\e86c'; } /* '' */
.icon-list:before { content: '\e86d'; } /* '' */
.icon-arrow-left:before { content: '\e86f'; } /* '' */
.icon-check:before { content: '\e871'; } /* '' */
.icon-filters:before { content: '\e872'; } /* '' */
.icon-close:before { content: '\e874'; } /* '' */
.icon-link:before { content: '\f08e'; } /* '' */
.icon-single:before { content: '\f0c8'; } /* '' */
.icon-youtube:before { content: '\f16a'; } /* '' */
.icon-google:before { content: '\f1a0'; } /* '' */